.team-login-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    @media  screen and (max-width: 991px) {
        padding:0 24px;
        align-items: flex-start;
    }
    .team-login-ctn{
        .heading-section{
            margin-bottom: 32px;
            div.ant-typography{
                font-size:24;
                color:rgba(0, 0, 0, 0.85);
                @media  screen and (max-width: 991px) {
                    font-size: 16px;
                }
            }
        }
        .team-login-form-button{
            margin-top: 12px;
            height: 40px;
            font-size:16px;
            margin-bottom: 28px;
            width: 100%;
        }
        .team-login-form{
            width: 430px;
            @media  screen and (max-width: 600px) {
                width:100%
            }
            .ant-form-item-label > label{
                margin-bottom:4px;
                font-size:14px;
                color:#202223;
                font-weight: 500;
                &::after{
                    display:none;
                }
            }
            .team-login-form-input{
                height: 40px;
                width: 100%;
                
            }
           
        }
    }
}
