.ant-picker-preset .ant-tag {
    border-color: var(--secondary-color) !important;
    color: var(--secondary-color) !important;
    background-color: #fff !important;
}

.ant-required-icon-copy::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: '*';
    font-family: SimSun, sans-serif;
}