.add-edit-device-form{
    .add-edit-form-switch{
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-bottom:24px;
        div.ant-typography{
           font-weight:500;
           font-size: 14px;
        }
        &.required{
            div.ant-typography{
                &::before{
                    content: "*";
                    color: var(--secondary-color);
                    margin-right: 4px;
                    margin-top: 4px;
                    font-size: 18px;
                }
            }
        }
    }
    .add-edit-device-form-item{
        margin-bottom: 24px;
        .ant-form-item-label > label{
            margin-bottom: 8px;
            color:#202223;
            font-weight:500;
            &::after{
                display: none;
            }
        }

        &.required{
            .ant-form-item-label > label{
                &::before{
                    content: "*" !important;
                    color: var(--secondary-color) !important;
                    margin-right: 4px !important;
                }
            }
        }
        .ant-upload.ant-upload-drag{
            border: 1px solid #D9D9D9;
        }
       
    }
    .ant-card-body{
        padding:14px !important;
    }
}

 /*Select  Scrollbar styles */
 .update-device-select .rc-virtual-list-holder::-webkit-scrollbar{
    width: 8px;
    background: transparent;
   
}
 .update-device-select .rc-virtual-list-holder::-webkit-scrollbar-thumb{
    background: rgba(0, 0, 0, 0.5) !important;
    border-radius: 99px;
}

.update-device-select .rc-virtual-list-holder::-webkit-scrollbar-thumb:hover{
    background-color: #555;
}