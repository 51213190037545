// Header
.create-itc-header-section{
    background-color: #0057B8;
    border-radius: 20px;
    padding:24px 36px;
    background-image: url('../images/itc-header-bg.png');
    margin-bottom: 37px;
    .create-itc-header-ctn{
        display: flex;
        justify-content: space-between;
        .create-itc-header-content{
            .create-itc-header-title{
                font-size: 1.625rem;
                color: #FFFFFF;
                margin-bottom: 11px;
            }
            .create-itc-header-tagline{
                font-size: 1rem;
                color: #FFFFFF;
                margin-bottom: 0;
                font-weight: 600;
            }
            .create-itc-header-subtagline{
                font-size: 0.875rem;
                color: #FFFFFF;
                margin-bottom: 0;
                font-weight: 400;
            }
        }
        .create-itc-header-img{
            @media screen and (max-width: 991px) {
                display: none; 
            }
        }
    }   
}

.itc-back-btn{
    cursor: pointer;
    margin-bottom: 14px;
    span{
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85)
    }
}

.mobile-progress-bar-section{
    display: none;
    @media screen and (max-width:991px) {
        display:block; 
        margin-bottom: 10px;                  
    }
    .mobile-progress-bar{
        margin: 15px 0 10px 0;
    }
}

// Company details
// .itc-company-details-section{
//     .itc-company-details-ctn{
//         .itc-details-form-ctn{
//         }
//     }
// }


// Company details form
.itc-company-details-form{
    width:687px;
    @media screen and (max-width: 1200px) {
        width: 100%;   
    }
    .itc-company-details-form-item{
        margin-bottom: 24px;
        .ant-form-item-label > label{
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 8px;
            &::after{
                display:none;
            }
        }
        .itc-company-details-form-textarea{
            height: 100px;
        }
        .itc-company-website-input{
            width:347px;
            @media screen and (max-width: 1200px) {
                width: 100%;   
            }
        }
    }
}

// Itc Options
.itc-options-modal{
    .ant-modal-body {
        padding: 24px 0 !important;
    }
}

.itc-options-header{
    // width: 600px;
    padding: 0 10px;
    margin-top: 20px;
    img{
        margin-bottom: 23px;
    }
    @media screen and (max-width: 991px) {
        width: 100%;   
    }
}

.itc-options-footer-ctn{
    padding:23px 37px;
    .itc-options-footer{
        padding-top:23px;
        border-top: 1px solid #B8B8B8;
    }  
}
 

.itc-option-card{
    width: 382px;
    padding:0 7px;
    cursor: pointer;
    .itc-option-card-img-ctn{
        width: 100%;
        height: 190px;
        margin-bottom: 14px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
        }
    }
    .itc-option-card-details-ctn{
        min-height:160px;
        @media screen and (max-width: 510px){
            min-height:180px;
        }
        @media screen and (max-width: 480px){
            min-height: 115px;
        }
        .itc-option-card-title{
            color: #000;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
        }
        .itc-option-card-details{
            color: #4B4B4B;
            font-size: 12px;
            font-weight: 400;
        }
    } 
    .itc-option-card-started{
        color:var(--secondary-color);
        font-size:12px; 
        font-weight:600; 
        margin-bottom:0
    }
}

// Extra company details

    .business-location{
        width: 690px;
        padding-bottom: 36px;
        @media screen and (max-width: 1200px) {
            width: 100%;   
        }
    }
    .social-media-links-ctn{
        padding-top: 36px;
        margin-bottom: 36px;
        border-top: 1px solid #DFDFDF;
        .social-media-links{
            width: 690px;
            @media screen and (max-width: 1200px) {
                width: 100%;   
            }
        }
    }
    .pjt-mngt-links-ctn{
        padding-top: 36px;
        margin-bottom: 36px;
        border-top: 1px solid #DFDFDF;
        .pjt-mngt-links{
            width: 690px;
            @media screen and (max-width: 1200px) {
                width: 100%;   
            }
            .member-form-row{
               
                .ant-select-selection-placeholder{
                    color: rgba(0, 0, 0, 0.85);
                }
            }
            .input-close-icon{
                cursor:pointer; 
                color:#00000073; 
                font-size:16px; 
                @media screen and (max-width: 768px) {
                    color:rgba(0, 0, 0, 0.65);
                    text-align: right;
                    font-size: 14px;
                    margin-bottom:12px;
                    margin-top: 0px;
                }        
            }
        }
    }



// Customization
.customization-form-ctn{
    .brand-logo{
        padding-bottom: 24px;
    }
    .brand-color{
        padding-top: 24px;
        padding-bottom: 30px;
        border-top:1px solid #DFDFDF;
        .color-pallete{
            display: flex;
            gap: 19px;
            @media screen and (max-width: 420px) {
               flex-direction: column;
            }
            .color-pallete-item{
                width: 244px;
                height: 148px;
                border-radius: 3px;
                flex-shrink: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                p{
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 24px;
                    margin-bottom: 0;
                    color:#FFFFFF;
                    &:nth-of-type(2){
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

// Specialization 

.itc-specialization-form{
    .itc-specialization-category{
        width:368px;
        @media screen and (max-width: 991px) {
            width: 100%;     
        }
    }
    .itc-specialization-skill{
        width:722px;
        @media screen and (max-width: 991px) {
            width: 100%;     
        }
    }
}


.added-specialization-list{
    p{
        margin-bottom: 0;
    }
}

// Testimonials

.create-itc-testimonial-form{
    .testimonial-avatar{
        margin-top: 20px;
        margin-bottom: 38px;
    }
    .testimonial-primary-details{
        width: 746px;
        @media screen and (max-width: 1200px) {
            width: 100%;     
        }
        .itc-testimonial-form-label-item{
            margin-bottom: 6px;
            .ant-form-item-label > label{
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 8px;
                &::after{
                    display:none;
                }
                &::before{
                    display: none !important;
                }
            }
        }
    }
    p{
        color: #626262;
        font-size: 14px;
        margin-bottom: 30px;
    }
    .create-itc-testimonial-form-textarea{
        height: 200px;
    }
}



// Added Testimonials
.added-faq-list{
    .added-faq-content{
        .number-faq{
            display: flex;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0;
            color: #FFF;
            text-align: center;
            font-size:  12px;
            background-color: var(--secondary-color);
        }
    }
}

// Membership
.itc-membership-form-ctn{
    .info-details-text{
        font-size: 14px;
        color:rgba(0, 0, 0, 0.65);
        font-style: italic;
        margin-bottom: 15px;
        .anticon-info-circle svg{
            fill: #000000 !important
        }
    }
    .select-membership-btn-ctn{
        margin-bottom: 15px;
        .select-membership-btn{
            width:fit-content !important;
            height: 40px;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            // font-weight: 500;
            border-radius: 5px;
            padding: 0 20px;
            box-shadow: none !important;
            border-width: 2px;
            &:hover{
                color: var(--secondary-color);
                border:2px solid var(--secondary-color);
            }
            &.active{
                color: var(--secondary-color);
                border:2px solid var(--secondary-color);
            }
        }    
    }
    .itc-membership-checkbox{
        margin-bottom: 15px;
    }
    .itc-membership-form-label-item{
        margin-bottom: 34px;
        .ant-input{
            width: 450px;
            @media screen and (max-width: 991px) {
                width: 100%;     
            }
        }
        .ant-form-item-label > label{
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 12.5px;
            &::after{
                display:none;
            }
            &::before{
                display: none !important;
            }
        }
        .ant-form-item-extra{
            color: #626262 !important;
            font-size: 14px;
            font-weight: 400;
            margin-top: 16.5px !important;
        }
    }
  
}

// Tech Team

.tech-team-list-ctn{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(302px, 1fr));
    gap: 16px; 
    // max-width: 1200px;
    margin: 0 auto; 
    margin-top: 23px;
    margin-bottom:51px;
}

.itc-tech-team{
    margin-top: 23px;
    margin-bottom: 30px;
}

// External Integrations
.integrations-section{
    width: 900px;
    padding-bottom: 36px;
    @media screen and (max-width: 1200px) {
        width: 100%;   
    }
    .integration-card{
        display: flex;
        flex-direction: column;
        height: 100%;
        &.ant-card-bordered {
            border: 1px solid #D9D9D9;
        }
        .ant-card-cover{
            border: 1px solid #D9D9D9;
            border-bottom:0;
        }
        .ant-card-body{
            padding:18px;
            border-top: 1px solid #D9D9D9;
            min-height: 220px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

.integrations-form-item{
    margin-bottom: 12px;
    .ant-form-item-label > label{
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 4px;
        &::after{
            display:none;
        }
    }
}

.integration-subtitle{
    color: rgba(0, 0, 0, 0.64);
    font-size: 14px;
    margin-bottom: 24px;
}

//Success
.itc-success-section{
    .itc-success-ctn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 90vh;
        img{
            margin-bottom:24px;
        }
        .itc-success-title{
            width: 457px;
            margin-bottom: 20px;
            text-align: center;
            @media screen and (max-width: 991px) {
                width: 100%;    
            }
        }
        .itc-success-subtitle{
            color: rgba(0, 0, 0, 0.64);
            font-size: 18px;
            width: 717px;
            margin-bottom:24px;
            text-align: center;
            @media screen and (max-width: 1200px) {
                width: 100%;     
            }
        }
    }
}


// General Stylings

// Form title stylings
.itc-form-title{
    margin-bottom: 24px;
    font-weight: 500 !important;
}

.itc-form-subtitle{
    color: rgba(0, 0, 0, 0.64);
    font-size: 16px;
    margin-bottom: 24px;
}

// Form label stylings
.itc-form-label-item{
    margin-bottom: 34px;
    .ant-form-item-label > label{
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 8px;
        &::after{
            display:none;
        }
        &::before{
            display: none !important;
        }
    }
}

// Next Button Stylings
.itc-next-btn{
    height:40px;
    width:132px;
    font-size: 16px;
}

.add-new-btn{
    width:614px;
    height:40px;
    margin-bottom:18px;
    @media screen and (max-width: 991px) {
        width: 100%;     
    }
}

// external link
.ext-link, .ext-link:hover{
    color: var(--secondary-color) !important;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
}
