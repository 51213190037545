.cyber-heading{
    display: flex;
    justify-content: space-between;
    gap:10px;
    flex-wrap: wrap;
    .main-heading {
        font-size: 30px;
        font-weight: 500;
    }
}

.cybersecurity-form{
    .cybersecurity-form-item{
        margin-bottom: 12px;
        .ant-form-item-label > label{
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 4px;
            &::after{
                display:none;
            }
        }
    }
}

.cybersecurity-subtitle{
    color: rgba(0, 0, 0, 0.64);
    font-size: 14px;
    margin-bottom: 24px;
}

.cyber-info-icon svg{
    fill: rgba(0, 0, 0, 0.75) !important
}
