.my-details-section{
    .my-details-ctn{
        .my-details-heading{
            margin-bottom:48px;
            @media screen and (max-width: 991px) {
               margin-bottom: 24px;
            }
        }
        .my-details-form-section{
            width:650px;
            padding-left: 20px;
            @media screen and (max-width: 991px) {
                width: 100%;
                padding-left: 0px;
            }
            .my-details-form-row{
                margin-bottom: 12px;
                @media screen and (max-width: 767px){
                    margin-bottom: 0px;
                    
                }
                &.profile-image-row{
                    @media screen and (max-width: 767px){
                        margin-top: 20px; 
                    }
                }
                .ant-upload.ant-upload-drag{
                    background: #FFFFFF;
                    border: 1px solid #D9D9D9;
                    border-radius: 2px;
                }
                .ant-form-item-label > label{
                    margin-bottom: 4px;
                    color:#202223;
                    &::after{
                        display: none;
                    }
                }
                .my-details-form-input{
                    height: 40px;
                }

            }
            .my-details-save-btn{
                margin-top:40px;
            }
        }
    }
}