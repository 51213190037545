.container{
    @media screen and (min-width:1440px) {
        max-width:1380px;
        margin: 0 auto; 
    }
    @media (min-width: 992px) and (max-width: 1439px){
        max-width: 90%;
        margin: 0 auto;
    }
    @media screen and (max-width:991px) {
        max-width: 90%;
        margin: 0 auto;
    }
}
 