

.Link{
    text-decoration: none;
    color: #F04A22;
}
.Button{
    display: flex;
    justify-content:space-between;
    align-items:center;
 
    margin-bottom:2rem;
}

.login-btn{
    width: 100%;
    height:42px !important;
    background: #F04A22 !important;
    border: 1px solid #F04A22 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
    border-radius: 2px !important;
    font-size: 16px !important;
}

.login-section{
    padding:0 24px;
}

.login-container{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 85vh;
    width:100%;
}

.sign-up-cta{
    margin-top: 12vh;
    margin-bottom: 20px;
}

.login-input{
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    height: 40px;
    margin-bottom: 12px;
}

.login-form{
    width:430px;
}

.guest-subtitle{
    width: 430px;
    margin-bottom: 32px !important; 
    margin-top:0;
    font-weight:500 !important;
    color: rgba(0, 0, 0, 0.65) !important;
}


@media (max-width:500px){
    .login-form{
        width:100%;
    }
    .login-container{
        display:block;
        margin-top: 10vh;
        height: auto;
    }

    .sign-up-cta{
        margin-top: 10vh;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    }
    
}
