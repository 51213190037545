.password-section{
    .password-ctn{
        .password-heading{
            margin-bottom:32px;
        }
        .password-form-section{
            width:550px;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            .ant-form-item-label > label{
                margin-bottom: 4px;
                color:#202223;
                font-weight:500;
                &::after{
                    display: none;
                }
            }
            .password-form-input{
                height: 40px;
            }
            .password-save-btn{
                margin-top:40px;
            }
        }
    }
}