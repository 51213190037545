.reset-password-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    @media  screen and (max-width: 991px) {
        padding:0 24px;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        margin-top: 10vh;
    }
    .reset-password-ctn{
        @media screen and (max-width: 991px) {
            width: 100%;
        }
        .heading-section{
            margin-bottom: 32px;
        }
        .reset-password-form{
            width: 430px;
            @media  screen and (max-width: 600px) {
                width: 100%;
            }
            .reset-password-form-input{
                height: 40px;
                width: 100%;
                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                &[type=number] {
                    -moz-appearance: textfield;
                }
            }
            .reset-password-form-button{
                margin-top: 32px;
                height: 40px;
                font-size:16px;
                width: 100%;
                margin-bottom: 28px;

            }
        }
    }
    .reset-password-success{
        width: 813px;
        @media screen and (max-width: 991px) {
            width: 100%;
        }
        .ant-result{
            .ant-result-title{
                font-weight: 500;
            }
            .ant-result-icon{
                .anticon{
                    color: #F04A22
                }
            }
        }
    }
}
