.job-posting-ctn{
    // padding-bottom: 80px;
    padding:0 50px;
    margin-top: 25px;
    @media  screen and (max-width: 991px) {
        padding:0 24px;
    }
    .job-posting-header{
        margin-bottom: 40px;
    }
    .job-posting-content{
        padding-bottom: 40px;
        @media screen and (max-width: 991px) {
            padding-bottom: 150px;
        }
    }
}

.job-post-next-btn-ctn{
    margin-top: 40px;
    @media screen and (max-width: 991px) {
        border-top: 1px solid #EAECF0;
        padding:24px 16px;
        position: fixed;
        left:0;
        bottom:0;
        width: 100%;
        justify-content: center;
        background-color: #fff;
    }
    .job-post-next-btn{
        font-size: 16px;
        width: 132px;
        height:40px;
        @media screen and (max-width: 991px) {
            width:100%;
            height:32px;
            font-size: 14px;
        }
    }
}

// Getting Started Section
.getting-started-ctn{
    .getting-started-content{
        // height: 55vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .getting-started-inner{
            width: 492px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 991px) {
                align-items: flex-start;
            }
            .post-job-title-mobile{
                display: none;
                @media screen and (max-width: 991px) {
                   display: block;
                   margin-bottom: 32px;
                }
            }
           .getting-started-img{
                margin-bottom: 30px;
                @media screen and (max-width: 991px) {
                    display: none;
                }
            }
            .getting-started-title{
                font-size:32px;
                font-weight:500; 
                color:#000;
                @media screen and (max-width: 991px) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }
            .ant-radio{
                span{
                    font-size: 17px;
                }
            }
            .select-job{
                width:492px;   
                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
                    height:40px !important;
                }
            }
        }
    }
    .getting-started-btn-ctn{
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        @media screen and (max-width: 991px) {
            border-top: 1px solid #EAECF0;
            padding:24px 16px;
            position: fixed;
            left:0;
            bottom:0;
            width: 100%;
            justify-content: center;
        }
        .getting-started-btn{
            font-size: 16px;
            height:40px;
            @media screen and (max-width: 991px) {
                flex:1;
                width: 43vw;
                height:32px;
                font-size: 14px;
            }
        }
    }
}

// Job Title Section
.job-title-ctn{
    .job-title-content{
        width: 678px;
        @media (max-width:768px) {
            width:100%;
        }
        .job-title-heading{
            margin-bottom: 40px;
        }
        .job-title-examples{
            ul{
                li{
                    font-size: 14px;
                }
            }
        }
    }
}

.job-title-form{
    margin-bottom: 40px;
    .ant-input{
        height: 40px;
    }
}


// Job Description Section
.job-description-ctn{
    .job-description-content{
        width: 1006px;
        @media (max-width:1028px) {
            width:100%;
        }
        .job-description-heading{
            margin-bottom: 40px;
        }
        .job-description-examples{
            ol{
                li{
                    font-size: 14px;
                }
            }
        }
    }
}

.job-description-form{
    margin-bottom: 40px;

    .job-description-textarea{
        height: 100%;
        margin-bottom: 20px;
    }
    .upload-form-item{
        width: 405px;
        @media (max-width:576px) {
            width:100%;   
        }
        .document-upload{
            a{
                text-decoration: none;
                color: var(--secondary-color);
            }
        }
    }
}

// Job Category Section

.job-category-ctn{
    .job-category-content{
        width: 678px;
        @media (max-width:768px) {
            width:100%;
        }
        .job-category-heading{
            margin-bottom: 40px;
        }
    }
}

.job-category-form{
    margin-bottom: 40px;
    .ant-select{
        height: 40px !important;
        width: 532px !important;
        @media (max-width:576px) {
            width:100% !important; 
        }
    }
}

// select job category styles
.select-job-category .rc-virtual-list-holder::-webkit-scrollbar, .add-job-skills-select .rc-virtual-list-holder::-webkit-scrollbar{
    width: 8px;
    background: transparent;
   
}

.select-job-category .rc-virtual-list-holder::-webkit-scrollbar-thumb, .add-job-skills-select .rc-virtual-list-holder::-webkit-scrollbar-thumb{
    background: rgba(0, 0, 0, 0.5);
    border-radius: 99px;
}

.select-job-category .rc-virtual-list-holder::-webkit-scrollbar-thumb:hover, .add-job-skills-select .rc-virtual-list-holder::-webkit-scrollbar-thumb:hover{
    background-color: #555;
}


.select-job-category .ant-select-item-group, .select-job-category .title-option{
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}

.select-job-category .ant-select-item-option-grouped{
    padding-left: 12px;
}

// Job Skills Section

.job-skills-ctn{
    .job-skills-content{
        width: 678px;
        @media (max-width:768px) {
            width:100%;
        }
        .job-skills-heading{
            margin-bottom: 40px;
        }
    }
}

.job-skills-form{
    margin-bottom: 40px;
    .ant-select{
        // height: 40px !important;
        width: 532px !important;
        @media (max-width:568px) {
            width:100% !important; 
        }
    }
}

// Job Type Styles
.job-type-ctn{
    .job-type-content{
        .job-type-heading{
            margin-bottom: 40px;
        }
        .job-type-form-ctn{
            width: 861px;
            @media (max-width:991px) {
                width:100%;
            }
        }
    }
}

.job-type-form{
    margin-bottom: 40px;
    width:100%;
    .ant-form label{
        font-weight: 500;
    }
    .job-type-subtext{
       font-weight:400; 
       color:rgba(0, 0, 0, 0.65);
       font-size:14;
    }
}

// Job Budget Styles
.job-budget-ctn{
    .job-budget-content{
        .job-budget-heading{
            margin-bottom: 40px;
            width: 840px;
            @media (max-width:991px) {
                width:100%;
            }
        } 
        .job-budget-form-ctn{
            width: 861px;
            @media (max-width:991px) {
                width:100%;
            }
        }  
    }
}

.job-budget-form{
    margin-bottom: 40px;
    width: 100%;
    .hourly-budget-ctn{
        display: flex !important;
        column-gap: 56px;
        @media (max-width:991px) {
            flex-direction: column;
            row-gap:16px;
        }
    }
    .ant-radio-wrapper span{
        font-weight: 500;
    }
    .job-budget-subtext{
        margin-bottom: 24px;
        font-size: 14px;
        color:rgba(0, 0, 0, 0.65);
    }
    .job-budget-error{
        font-size: 14px;
        color:var(--secondary-color);
    }
    .input-budget-label{
        color: #000 !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        margin-bottom: 8px !important;
    }
    .hourly-budget-input{
        width: 109px;
        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
    .fixed-budget-input{
        width: 252px;
        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
}

// Job Duration Styles
.job-duration-ctn{
    .job-duration-content{
        .job-duration-heading{
            margin-bottom: 40px;
        }
    }
}

.job-duration-form{
    margin-bottom: 40px;
    .ant-form label{
        font-weight: 500;
    }
    .job-duration-subtext{
        margin-bottom: 24px;
        font-size: 14px;
        color:rgba(0, 0, 0, 0.65);
    }
}

// Job Preview Styles
.job-preview-ctn{
    .job-preview-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }
    .job-preview-content{
        .job-preview-section{
            margin-bottom:42px;
            .job-preview-title{
                font-size: 20px;
                font-weight: 500; 
                margin-bottom: 8px;
                margin-bottom: 0;
            }
            .job-preview-icon{
                color:var(--secondary-color);
                font-size: 18px;
            }
            .job-preview-subtitle{
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
    .job-preview-btn-ctn{
        display: flex;
        column-gap: 16px;
        @media screen and (max-width: 991px) {
            border-top: 1px solid #EAECF0;
            padding:24px 16px;
            position: fixed;
            left:0;
            bottom:0;
            width: 100%;
            justify-content: center;
            background-color: #fff;
        }
        .job-preview-btn{
            height: 40px;
            font-size: 16px;
            @media screen and (max-width: 991px) {
                flex:1;
                width: 43vw;
                height:32px;
                font-size: 14px;
            }
        }
    }
}


// Success job post styles
.success-job-section{
    display: flex;
    justify-content: center;
    .ant-result-success .ant-result-icon > .anticon{
        color: #F04A22 !important;
    }
    .success-job-text{
        color: rgba(0, 0, 0, 0.45);
        text-align: center;
        font-size: 16px;
        width: 717px;
        margin-bottom: 40px;
        @media screen and (max-width: 1200px) {
            width: 100%; 
        }
    }
}
