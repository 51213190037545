.tech-team-item{
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    padding:10px;
    .tech-team-tech-info{
        border-radius: 5px;
        background: #F7F7F7;
        padding:10px;
        .tech-team-tech-details{
            .rating-label{
                display: flex;
                align-items: center;
                border-radius: 100px;
                background: #FFF;
                column-gap: 5px;
                padding:4px 7px;
                width: fit-content;
                .rating-text{
                    margin-bottom: 0;
                    color: #555;
                    font-size: 14px;
                }
                .reviews-text{
                    color:#A8A8A8;
                    font-size: 14px;
                    font-weight: 300;
                    margin-bottom: 0;
                }
            }
            .no-rating-label{
                height:34px;
                width: 30px;
            } 
        }
    }
    .tech-team-item-summary{
        padding:10px 3px;
        .summary-btn-ctn{
            display: flex;
            column-gap: 7px;
            margin-top: 13.22px;
            .summary-btn{
                flex: 1;
                height: 40px;
                font-size: 16px;
                // &:nth-of-type(2){
                //     font-weight: 500;
                // }
            }
        }
    }
}