.company-details-section{
    .company-details-ctn{
        width: 824px;
        @media screen and (max-width: 991px) {
            width: 100%;
        }
        .company-details-heading{
            margin-bottom: 0px;
        }
        .company-details-form-section{
            .company-details-form-item{
                border-bottom: 1px solid #EAECF0;
                padding-bottom: 24px;
                padding-top: 40px;
                .form-item-title{
                    font-size: 14px;
                    color: #344054;
                    margin-bottom: 0;
                }
                .form-item-sub-text{
                    color: rgba(0, 0, 0, 0.65);
                    font-size: 14px;
                }
                .ant-upload.ant-upload-drag{
                    background: #FFFFFF;
                    border: 1px solid #D9D9D9;
                    border-radius: 2px;
                }
                .ant-form-item-label > label{
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                    margin-bottom: 4px;
                    font-weight: 500;
                    &::after{
                        display:none;
                    }
                }
                .company-details-form-input{
                    height: 40px;
                }
                .ant-input-group-addon, .ant-input{
                    height: 40px;
                }
                .company-details-form-select{
                    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
                        height: 40px;
                    }
                    
                    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
                        line-height: 40px;
                    }
                    
                    &.ant-select-single .ant-select-selector .ant-select-selection-item{
                        line-height: 40px;
                    }
                }
                .company-details-form-textarea{
                    textarea.ant-input{
                        height:142px !important;
                    }
                }
            }
        }
    }
}