.team-management-ctn{
    padding-top:20px;
    margin-bottom: 50px;
    .team-heading-section{
        margin-bottom:32px;
    }

    .invite-team-section{
        border-top:1px solid #EAECF0;
        border-bottom: 1px solid #EAECF0;
        padding:32px 0;
        .member-form-row{
            width: 625px;
            margin-bottom: 4px;
            margin-right: 0 !important;
            @media screen and (max-width: 991px) {
                width: 100%;
            }
            @media screen and (max-width: 768px) {
                column-gap: 0 !important;   
            }
            .ant-select-selection-placeholder{
                color: rgba(0, 0, 0, 0.85);
            }
            .member-form-input{
                height: 40px;
            }
            .member-form-select{
                &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
                    height: 40px;
                }
                
                &.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
                    line-height: 40px;
                }
                
                &.ant-select-single .ant-select-selector .ant-select-selection-item{
                    line-height: 40px;
                }
            }
        }
        .input-close-icon{
            cursor:pointer; 
            color:#00000073; 
            font-size:16px; 
            margin-top:12px;
            @media screen and (max-width: 768px) {
                color:rgba(0, 0, 0, 0.65);
                text-align: right;
                font-size: 14px;
                margin-bottom:12px;
                margin-top: 0px;
            }        
        }
        .cta-form-row{
            margin-top:4px;
        }
    }

    .team-members-table-section{
        padding-top: 32px;
        position: relative;
        .table-tab-section{
            border: 1px solid #D9D9D9;
            border-radius: 8px;
            @media screen and (max-width: 991px) {
                border: none;
            }
            &.no-invited-member-section{
                border: none !important;
                .no-invites-ctn{
                    border: 1px solid #D9D9D9;
                    border-radius: 2px;
                    height: 342.34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-top: 32px;
                    .empty-invitation-title{
                        font-weight: 500;
                        font-size: 20px;
                        color: rgba(0, 0, 0, 0.85);
                        margin: 11px 0 18px 0;
                    }
                    .empty-invitation-info{
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.6);
                    }

                }
            }
            .members-list{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            .members-load-more-btn{
                width: 100%;
            }

            .ant-table{
                background: transparent;
            }
            .ant-table-container{
                min-height: auto !important;
            }
            .ant-tabs-ink-bar{
                background: rgba(0, 0, 0, 0.85) !important;
            }
            .ant-tabs-tab{
                font-size: 16px;
            }
            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: rgba(0, 0, 0, 0.85) !important;
                text-shadow: none !important;
                font-weight: 500;
                font-size: 16px;
            }
            .ant-tabs > .ant-tabs-nav{
                margin-bottom: 0;
                padding-left:16px;
                padding-top:4px;
                @media  screen and (max-width: 991px) {
                    padding-left:0;
                }
            }

            .ant-table-pagination.ant-pagination{
                position: absolute;
                bottom:-70px;
                right:0px;
            }
        }
    }
}



.edit-member-modal{
    ul{
        padding-left:0;
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.65);
    }
}

.remove-member-modal{
    .ant-modal-footer{
        border-top: none;
    }
}

.invite-overlay{
    left:40px !important;
    top:355px !important;
    @media screen and (max-width: 991px) {
        left:24px !important;
        top:385px !important;
    }
    .ant-tooltip-content{
        width: 458px;
        @media screen and (max-width: 991px) {
            width: auto;
        }
        .ant-tooltip-inner{
            background: #393939;
        }   
        .ant-tooltip-arrow{
            display: none;
        }
    }
}

.role-overlay{
    left:60vw !important;
    @media screen and (max-width: 1440px) {
        left:50vw !important;
    }
    @media screen and (max-width: 1040px) {
        left:45vw !important;
    }
    .ant-tooltip-content{
        width: 500px;
        .ant-tooltip-inner{
            background: #393939;
        }   
        .ant-tooltip-arrow{
            display: none;
        }
    }
}

.mobile-role-overlay{
    // top:685px !important;
    .ant-tooltip-content{
        width: 274px;
        @media screen and (max-width:320px) {
            width: auto;
        }
        .ant-tooltip-inner{
            background: #393939;
        }   
        .ant-tooltip-arrow{
            display: none;
        }
    }
}

.select-role-overlay{
    .ant-tooltip-content{
        width: 248px;
        .ant-tooltip-inner{
            background: #393939;
        }   
        .ant-tooltip-arrow{
            display: none;
        }
    }
}

.member-popover-content{
    width:95px;
    padding:0px 12px;
    text-align: center;
}

.member-popover-content .member-popover-cta{
    color: #F04A22;
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid #D9D9D9;
    &:last-of-type{
        border-bottom: none;
    }
}


.member-popover .ant-popover-arrow{
    display: none;
}

.member-popover{
    padding-top:0;
}

.member-popover .ant-popover-content{
    width: 95px !important;
}

.member-popover .ant-popover-inner-content{
    padding: 5px 0px !important;
    padding-bottom: 2px !important;
}

@media screen and (max-width:767px) {
    .edit-member-modal, .remove-member-modal{
        .ant-modal-footer{
            display: flex;
            justify-content: center;
            column-gap: 18px;
            padding: 10px 24px;
            button{
                flex: 1;
            }
        }
    }
    .remove-member-modal{
        .ant-modal-footer{
            column-gap: 8px;
        }
    }
}
