.saved-profiles-container{
    background-color: #FFFFFF;
    min-height: 82vh;
    width: 100%;
    @media (max-width:991px) {
        padding: 0;
    }
    .header-section{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .your-list{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 0;
        }
        .create-list-icon{
           color:#F04A22; 
           font-size:17px;
           position: relative;
           top:0px;
        }
        .create-list-text{
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #F04A22;
            margin-bottom:0;
        }
    }
    .saved-card-lists{
        margin-top:25px;
        .empty-list-desc{
            width: 360px;
            margin:0 auto;
            color: #7A7A7D;
            @media (max-width:420px) {
                width:100%
            }
        }
        .card-ellipsis{
            color:#767575;
            font-size: 22px;
        }
        .card-list-description, .card-list-no-description{
            margin-bottom: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            // max-height: 50px;
        }
        .card-list-no-description{
            color: #878787;
        }
        .ant-card-body{
            height: 145px;
        }
        .ant-card-bordered{
            border: 1px solid #C4C4C4;
            border-radius: 2px;
            cursor: pointer;
            &:hover{
                border: 1px solid #F04A22;
            }
        }
        .ant-card-head{
            border-bottom: 1px solid #C4C4C4;
        }
    }
}

.card-popover-content{
    .popover-action-item{
        width:100%;
        padding:8px 12px;
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;
        &:hover{
            background-color:#F5F5F5; 
            
        }
    }
}

.list-card-popover.ant-popover-placement-bottomRight .ant-popover-arrow{
    display: none;
}

.list-card-popover.ant-popover-placement-bottomRight{
    padding-top:0;
}

.list-card-popover.ant-popover-placement-bottomRight .ant-popover-content{
    width: 139px !important;
}

.list-card-popover.ant-popover-placement-bottomRight .ant-popover-inner-content{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
