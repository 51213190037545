.client-onboarding-row{
    .content-section{
        padding-bottom: 40px;
        .content-ctn{
            padding:0 50px;
            margin-top: 15px;
            @media  screen and (max-width: 991px) {
                padding:0 24px;
            }
        }
    }
    .steps-section{
        background: #393939;
        width: 100%;
        min-height: 100vh;
        position: relative;
        .steps-ctn{
            // position: fixed;
            padding-top: 80px;
            padding-left:27px;
            padding-right: 27px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // min-height: 100vh;

            .steps-title{
                margin-bottom: 40px;
                h4.ant-typography{
                    font-size: 24px;
                    font-weight: 700;
                    color: #FFFFFF;
                }
            }
            .steps{
                .onboarding-steps{
                    &.ant-steps-vertical{
                        .ant-steps-item-title {
                            color: #FFFFFF;
                        }
                        .ant-steps-item-description{
                            color: #FFFFFF;
                        }
                        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
                            background: #FFFFFF;
                            border: 1px solid #FFF;
                        }
                        .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
                            color: #FFFFFF;
                        }

                        .ant-steps-item-finish .ant-steps-item-icon {
                            background-color: #F04A22;
                            border-color: #fff;
                            line-height: 30px;
                        }
                        .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
                            color: #ffffff;
                        }
                        
                        .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
                            background-color: #ffffff;
                        }

                        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
                            color: rgba(0, 0, 0, 0.85);
                        }
                        
                        .ant-steps-item-wait .ant-steps-item-icon{
                            background: transparent;
                            border: 1px solid #FFF;    
                        }
                        .ant-progress-circle-trail {
                            stroke: #F04A22;
                        }
                        .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
                            stroke: #FFF;
                        }
                        .ant-steps-item-icon .ant-steps-icon {
                            top:-1px;
                        }
                        .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon  {
                            top:-2px;
                        }
                    }
                }
            }
            .steps-inner{
                margin-bottom: 100px;
            }
            .step-footer{
                padding-bottom: 30px;
                position: absolute;
                bottom: 0;
            }
        }
    }
}
