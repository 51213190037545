.add-chat-member-form{
    .ant-form-item-label > label{
        margin-bottom: 4px;
        color:#202223;
        font-weight:500;
        &::after{
            display: none;
        }
    }
}

.add-chat-member-modal{
    .ant-modal-body{
        padding-top: 10px;
    }
}