.ticket-listing-container{
    background-color: #FFFFFF;
    /* margin-top:20px; */
    min-height: 50vh;
    padding: 24px;
}

.ticket-listing-container .ant-table-content{
    font-size: 14px;
}

.ticket-listing-row{
    margin-bottom: 0px;
}


.ticket-listing-row .white-btn{
    background: #FFFFFF !important;
    border: 1px solid #D9D9D9 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
    border-radius: 2px !important;
    color: #000000 !important;
}

.ticket-filter-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:20px;
}

.ticket-type-list{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.ticket-type-item{
    background: #F5F5F5;
    color: #8F8F8F;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 18px;
    cursor: pointer;
}

.ticket-type-item-active{
    color: #F04A22;
    background: #FFD5CB;
}

.ticket-tech-details{
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.ticket-tech-img{
    width: 22px;
    height: 22px;
}

.ticket-tech-img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.ticket-avatar{
    color: #FFFFFF;
    background-color: #F04A22;
    font-size: 13px;
    text-transform: uppercase;
    flex-shrink: 0;
}

.borderless-btn{
    border: none;
    padding: 0;
    font-weight: 500;
    background: transparent;
    box-shadow: none;
}

.borderless-btn a{
    color: #F04A22;
}

.ticket-card-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:20px;
}

.ticket-status-tag{
    background: #FFE3DC;
    border-radius: 3px;
    border:none;
    padding: 5px 12px;
    color: #F04A22;
    font-weight: 400;

}

@media (max-width:991px) {
    .ticket-listing-container{
        padding:0px;
    }
    .ticket-tech-img{
        width: 41px;
        height: 41px;
    }
}

@media (max-width:767px) {
    .ticket-filter-section{
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        align-items:flex-start;
        row-gap:20px;
    }
}

@media (max-width:450px) {
    .ticket-type-list{
        gap: 8px;
    }
    .ticket-type-item{
        font-size: 13px;
        font-weight: 500;
        padding: 5px 16px;
    }
}