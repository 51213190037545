.profile-details-ctn{
    background-color: #FFFFFF;
    // padding: 20px 30px;
    min-height: 82vh;
    width: 100%;
    @media (max-width:991px) {
        padding: 0;
    }
    .header-section{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list-name{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 0;
        }
    }
    .list-details-desc{
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #7A7A7D;
        margin-top: 16px;
    }
    .profile-card-list{
        .empty-list-desc{
            width: 460px;
            margin:0 auto;
            color: #7A7A7D;
            @media (max-width:600px) {
                width:100%
            }
        }
        .ant-card-bordered{
            border: 1px solid #D9D9D9;
            border-radius: 5px;
            cursor: pointer;
            &:hover{
                border: 1px solid #F04A22;
            }
        }
        .profile-img{
            height: 71px;
            width: 71px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 1.46436px;
            }
        }
        .other-profile-info{
            h5.ant-typography{
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: #34343C;
                margin-bottom: 5px;
            }
            div.ant-typography{
                font-size: 15px;
                line-height: 19px;
                color: #7A7A7D;
                margin-bottom: 0;
            }
        }

        .saved-profile-card-bio-ctn{
            min-height:90px;
        }
        .saved-profile-card-bio, .unavailable-bio{
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #34343C;
            margin: 21px 0 26px 0;
        }

        .unavailable-bio{
            color: #878787;
        }

        .work-option{
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            text-align: right;
            color: #34343C;
        }
    }
}

.message-modal-heading{
    margin-bottom: 30px;
    h3.ant-typography{
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 8px;
    }
    div.ant-typography{
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #878787;
        span{
            font-weight: 500;
        }
    }
}

.fav-list-content .list-item-ctn{
    padding:14px 14px 0 14px;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 10px;
      }
      
    &::-webkit-scrollbar-track {
        background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.783);
    }
}


.fav-list-content .ant-checkbox-wrapper{
    margin-left:0 !important;
    margin-bottom:10px !important;
}

.saved-tech-popover.ant-popover-placement-bottomRight .ant-popover-content{
    width: 290px !important;
    // height: 400px !important;
    // overflow-y: auto;
    
}

.saved-tech-popover.ant-popover-placement-bottomRight .ant-popover-inner-content{
    padding:0;
    padding-bottom: 8px !important;
}

.fav-list-content .save-new-list{
    background: #FFE2DB;
    padding:10px 14px 10px 14px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    cursor: pointer;
    width: 290px !important;
}
