.guest-signup-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    .guest-signup-ctn{
        width:430px;
        @media screen and (max-width: 991px) {
            padding:0 24px;
        }   
        @media  screen and (max-width: 600px) {
            width:100%
        }
        .guest-signup-form-section{
            .ant-form-item-label > label{
                margin-bottom:4px;
                font-size:14px;
                color:#202223;
                &::after{
                    display:none;
                }
            }
            .guest-signup-input{
                height: 40px;
            }
            .guest-signup-button{
                width:100%;
                height:40px;
                margin-top:28px;
            }
        }
    }
}