.sign-up-section{
    .signup-form-section{
        margin-top: 5vh;
        padding: 0 120px;
        display: flex;
        flex-direction: column;
        row-gap: 4vh;
        @media screen and (max-width: 1200px) {
            padding:0 80px;
        }  
        @media screen and (max-width: 991px) {
            padding:0 24px;
        }    
        .heading-section{
            margin-bottom: 30px;
        }
        .sign-up-input{
            height: 40px;
            width: 100%;
        }
        .sign-up-button{
            height: 40px;
            width: 100%;
            margin-top: 10px;
        }
        .other-links-section{
           display: flex;
           justify-content: center;
           margin-bottom:20px;
        }
    }
    .signup-right-section{
        background: #F2F2F2;
        width: 100%;
        min-height: 110vh;
        padding-top:80px;
        padding-left:50px;
        .signup-testimony{
            padding-right: 50px;
            margin-bottom: 70px;
        }
        .signup-image{
            img{
                width: 117%;
                height: 117%;
                object-fit: cover;
            }
        }
    }
}

