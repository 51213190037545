.job-post-client-details{
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding: 50px 0;
    margin: 56px 0;
    @media  screen  and (max-width:768px){
        border-bottom: 0;
    }
    &.job-browse-client-details{
        border: none;
        padding: 0;
        margin: 0;
    }
    .job-post-client-details-col{
        .client-info-icon{
            position: relative;
            top:2px;
        }
        @media  screen  and (max-width:768px){
            border-bottom: 1px solid #D9D9D9;
            padding-bottom: 20px;
        }
        .details-rate{
            div.ant-typography{
                margin:0;
                font-size: 14px;
            }
        }
        .payment-info{
            border-left: 1px solid #D9D9D9;
            border-right: 1px solid #D9D9D9;
            padding-left:80px;
            @media  screen  and (max-width:768px){
                border: 0;
                padding: 0;
            }
        }
        .projects-info{
            padding-left: 80px;
            @media  screen  and (max-width:768px){
                border: 0;
                padding: 0;
            }
        }
    }
}