.manage-applications-ctn{
    margin-top:40px;
    margin-bottom: 20px;
    .manage-applications-header{
        margin-bottom: 40px;
    }
    .manage-applications-table{
        .manage-applications-table-header{
            display: flex;
            justify-content: space-between;
            padding-top: 30px;
            padding-bottom: 20px;
            @media screen and (max-width:768px) {
                flex-direction: column;
                row-gap: 10px;
            }
            .search-manage-applications{
                width: 351px;
                @media screen and (max-width:576px) {
                    width: 100%;
                }
            }
        }
        .search-for-jobs{
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            a{
                color: #F04A22;
                font-weight: 500;
                text-decoration: underline;
            }
        }
    }
}

.popover-action-icon{
    position: relative;
    top:2px;
}

// List item for mobile
.job-application-list-item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    column-gap: 16px;
}