@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    overflow-x: initial !important;
    font-family: 'Roboto', sans-serif !important;
}

.auth-container{
    padding:0 40px;
    width: 100%;
}

.auth-page-ctn{
    background:#FFFFFF;
    /* padding:20px;  */
    min-height:70vh
}

.ant-page-header{
    padding:16px 40px;
}

.neutral-btn{
    background: #FFFFFF !important;
    border: 1px solid #D9D9D9 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
    border-radius: 2px !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 16px !important;
  }

.custom-success-message-alert, .custom-error-message-alert{
    margin-top: 10vh;
}

.custom-success-message-alert .ant-message-notice-content{
    background-color: #F6FFED;
    border:1px solid #B7EB8F;
}

.share-social-icon{
    width:100% !important;
    padding:8px 12px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    width: 210px !important;
}


.share-social-icon:hover{
    background-color:#F5F5F5;    
}

.phone {
    color: black !important;
    font-weight: 500 !important;
    text-decoration: none;
    font-size: 16px;
}

.phone:hover {
    color: #F04A22 !important;
}
.booking-heading-size{
    font-size: 16px !important;
}
.booking-text-size{
    font-size: 14px !important;
    text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #F04A22;
    border-color: #F04A22;
}

.dropdown-toggle::after {
    display: none !important;
}

.ant-radio-button-wrapper:hover {
    color: #F04A22;
}
.ant-radio-button-wrapper-disabled{
    color: rgba(0, 0, 0, 0.25) !important;
}

.ant-form-item-label {
    text-align: left !important;
}

.ant-textfield .ant-form-item-control {
    width: 100%;
    height: 65px !important;
}

.location-modal .ant-modal-body {
    padding: 0px !important;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;

}

.ant-message .anticon {
    position: relative;
    top: -2px !important;
    margin-right: 8px;
    font-size: 16px;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #F04A22;
    border-color: #F04A22;
}

.ant-btn.btn-blue {

    color: #fff;
    background-image: linear-gradient(to right, #F04A22, #F08522);
    /*background-color: #F04A22;*/
    /*border-color: #F04A22;*/
}

.ant-btn.btn-blue-only {

    /*color: #fff;*/
    /*background-color: #F04A22;*/
    /*border-color: #F04A22;*/
    color: #fff;
    background-image: linear-gradient(to right, #F04A22, #F08522);
}

.ant-btn.btn-edit {
    /*color: #fff;*/
    /*background-color: #F04A22;*/
    /*border-color: #F04A22;*/
    color: #fff;
    background-image: linear-gradient(to right, #F04A22, #F08522);
}

.ant-btn.btn-white {
    /*color: #F04A22;*/
    /*background-color: #fff;*/
    /*border-color: #F04A22;*/
    color: #fff;
    background-image: linear-gradient(to right, #F04A22, #F08522);
}

.ant-btn.btn-blue2 {
    color: #fff;
    background-image: linear-gradient(to right, #F04A22, #F08522);
}

.ant-btn.btn-sign-in {
    color: #fff;
    background-image: linear-gradient(to right, #F04A22, #F08522);
}

.ant-btn.btn-sign-in:disabled {
    color: #fff;
    background-image: linear-gradient(to right, #F04A22, #F08522);
}

.ant-btn.btn-sign-up {
    color: #F04A22;
    border-color: #F04A22;
    background: #fff;
}

.no-data-found-paragraph {
    text-align: center;
}

/* .ant-btn.btn-blue:hover {
	color: #F04A22;
	background-color: #fff;
	border-color: #F04A22;
} */
.ant-btn.btn-blue2:hover {
    color: #fff;
    background-color: #F04A22;
    border-color: #F04A22;
}

.profile-image {
    margin-left: 1rem;
}

.ant-btn.btn-orange {
    color: #fff;
    background-color: #F04A22;
    border-color: #F04A22;
    min-width: 120px
}

.ant-btn.btn-orange-minus {
    color: #fff;
    background-color: #F04A22;
    border-color: #F04A22;
    width:30px !important;
}

.ant-btn.btn-red {
    color: #fff;
    background-color: rgb(211, 40, 40);
    border-color: rgb(211, 40, 40);
}


.owl-navcheck .owl-nav {
    display: none !important;
}

.ant-switch-checked.ant-check {
    background-color: #F04A22 !important
}


/*.ant-btn.btn-orange:hover {*/
/*	color: #F04A22;*/
/*	background-color: #fff;*/
/*	border-color: #F04A22;*/
/*}*/
.text-orange {
    color: #F04A22 !important;
}

.bg-orange {
    background-color: #F04A22 !important;
}

.ant-typography-expand{
    color: #F04A22 !important;
}

.bg-gray {
    background-color: #F3F3F3 !important;
}

.font-wn {
    font-weight: normal;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.ant-layout {
    background: #fff;
}

.ant-layout-header.header {
    height: auto;
    padding: 10px 0;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    border-top: 6px solid #F04A22;
    border-bottom: 1px solid #DEDEDE;
    background-color: transparent;
}

.ant-menu-vertical.ant-menu-sub {
    min-width: none !important;
    padding: auto;
}

.menu-option {
    margin: 0.3rem !important;

}

.menu-option:hover {
    text-decoration: none;
    background-color: transparent;
    /* color: #F04A22 !important */
}

.menu-link {
    text-decoration: none;
    height: 5rem !important;

}

.menu-link:hover {
    text-decoration: none;
    color: black !important;
    font-weight: 500;
    height: 10rem !important;


}

.logo img {
    width: 130px;
    margin-right: 1.5rem;
}

.logo-onboarding img {
    width: 140px;
}

.header .ant-menu {
    justify-content: flex-end;
    border-bottom: none;
    background-color: transparent;
}

.header .ant-menu-horizontal > .ant-menu-item::after {
    bottom: -11px;
}


/* .ant-menu-submenu-popup {
    top: 89px !important;
} */

/* .ant-dropdown {
    top: 92px !important;
} */

.ant-menu-submenu-title {
    text-decoration: none;
    width: 100% !important;

}

.ant-menu-horizontal > .ant-menu-item a {
    color: gray !important;
    font-weight: 300 !important;
}

.ant-menu-light .ant-menu-submenu-title:hover {
    color: #F04A22 !important;
    border-bottom: none !important;
}

.ant-menu-light .ant-menu-submenu-title:active {
    color: #F04A22 !important;
}

/* .horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open{
  color: #F04A22;
} */
.ant-menu-horizontal > .ant-menu-submenu::after {
    transition: none !important;
}

.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected a,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover a {
    color: black !important;
    font-weight: 500 !important;
    text-decoration: none;
}

/* .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
    color: transparent !important;
} */

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: none !important;
}

.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-seleted::after {
    border-bottom: 2px solid #F04A22 !important;
    bottom: -33px;

}

.ant-menu-vertical > .ant-menu-item {
    height: 30px;
    width: 100%;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after {
    border-bottom: 2px solid transparent !important;

}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #F04A22;
}

.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item a {
    text-decoration: none;
}

.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
    border-bottom-color: transparent !important;
}

.banner {
    padding: 150px 0 70px 0;
    background: url("../images/bg-blue.png") no-repeat;
    background-size: cover;
}

.search-tech.ant-form-inline {
    position: relative;
}

.search-tech.ant-form-inline .ant-form-item {
    margin-right: 0;
    width: 100%;

}

.search-tech.ant-form-inline .ant-input-lg {
    height: 54px;
    border-radius: 5rem 0rem 0rem 5rem !important;
    padding-left: 1rem;
    margin-left: -1rem;
    background-color: white !important;
}

.search-tech.ant-form-inline .ant-form-item.btn-search {
    width: 20%;

}

.search-tech.ant-form-inline .ant-form-item.btn-search .ant-btn {
    border-radius: 0 0.25rem 0.25rem 0;

    height: 54px;
}

.schedule-availability-form .ant-card-bordered{
    border: none;
}

.schedule-services-table .ant-table-thead > tr > th{
    padding:16px 10px;
}

.schedule-services-table .ant-table-tbody > tr > td{
    padding:16px 10px;
}

.support-ticket-section .ant-card-head{
    padding: 0 16px;
}

.support-ticket-section .ant-card-body{
    padding: 16px 16px;
}

.btn-xl {
    width: 210px;
    height: 50px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
}

.ant-btn-block.btn-xl {
    width: 100%;
}

.ant-btn::after {
    display: none !important;
}

.ant-input:focus, .ant-input-focused {
    box-shadow: none !important;
}

.repearing-step {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
}

.repearing-step .ant-image {
    float: left;
    width: 60px;
    height: 60px;
    line-height: 40px;
    border-radius: 100%;
    text-align: center;
    position: relative;
    flex-shrink: 0;
    background-color: #F04A22;
}

.ant-image-center, .ant-image {
    text-align: center !important;
}

.repearing-step .ant-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    transform: translate(-50%, -50%);
    display: inline-block;
}

.repearing-step .text {
    margin: 5px 0 0 10px;
}

.ant-card.product-card {
    background-color: transparent;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: white !important;;
}

.tabs-wrp{
    background: #FFFFFF;
    padding: 20px 20px;
    margin-top: 20px;
}

.tabs-wrp .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.tabs-wrp .ant-tabs-tab:hover {
    color: #F04A22;
    text-shadow: none;
}

.tabs-wrp .ant-tabs-ink-bar {
    background: #F04A22;
}

.ant-layout-header .ant-menu {
    line-height: none !important;
}

.ant-card.product-card .ant-card-body {
    padding: 15px 0 0 0;
}

.ant-service-chat .ant-card-body {
    padding: 0;
}

.ant-card.product-card .ant-card-cover {
    overflow: hidden;
    border-radius: 0.25rem;
}

.ant-layout-footer.footer {
    padding: 0;
    margin: auto 0;
    margin-bottom: 0;
    bottom: 0;
}

.list-items {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0 0 20px 0;
    list-style: none;
}

.list-items li {
    float: left;
    width: 100%;
    margin: 10px 0;
    padding-left: 30px;
    line-height: normal;
    position: relative;
}

.list-items li::before {
    content: "";
    position: absolute;
    top: -3px;
    left: 0;
    width: 25px;
    height: 25px;
    background: url("../images/check-circle.png") no-repeat;
    background-size: contain;
}

.selectt.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #F04A22;
    color: white;
    border-radius: 0.5rem;


}

.ant-anchor-ink {

    visibility: hidden;
}

.appstore-section {
    padding: 90px 0;
    background: url("../images/banner.png") no-repeat;
    background-size: cover;
}

.appstore-links .ant-space-item {
    width: 155px;
    border-radius: 8px;
    overflow: hidden;
}

.ant-anchor-link-title {
    position: relative;
    display: block;
    margin-bottom: 6px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
    text-decoration: none;
    color: #F04A22;


}

.remove-margin {
    margin: 0 !important;

}

.ant-anchor-link-title:hover {
    color: #F04A22;

}

.selectt.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #F04A22;
    color: white;
    border-radius: 0.5rem;
}



.drop-icon {
    color: black !important;
    margin-top: -0.3rem !important;
    margin-left: -0.5rem !important;
}

.ant-menu-item-selected {
    color: transparent !important;
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    width: 255px !important;

}

.appstore-links .ant-space-item img {
    width: 100%;
}

.footer-logo img {
    width: 120px;
}

.tech-info-wrp {
    padding: 20px 0 0 0;
}

.as-seen-in {
    padding: 60px 0 70px 0;
}

.as-seen-in .prbox {
    height: 100%;
    background-color: #F1F1F1;
}

.prbox .ant-card-body {
    position: relative;
    height: 100%;
    padding-bottom: 90px;
}

.prbox .ant-card-body .view-detail {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 0 24px;
}

.onboarding-wrp {
    padding: 40px 0 0 0;
    position: relative;
    /*background: #fafcfe;*/
}

/*.onboarding-wrp::before{*/
/*  content: "";*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 350px;*/
/*  background: url("../images/techprofile-top-banner.png") no-repeat;*/
/*  background-size: cover;*/
/*}*/
/* Tech Prole Page */
.techprofile-wrp {
    padding: 40px 0 10px 0;
    position: relative;
}

.navbar-transparent {
    background-color: transparent;
}

.navbar-white {
    background-color: white;
}

.techprofile-wrp::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
    /*background: url("../images/techprofile-top-banner.png") no-repeat;*/
    background-size: cover;
}

.messaging-wrp {
    padding: 30px 0 120px 0;
    position: relative;
}

.home-wrp {
    padding: 10px 0 0px 0;
    position: relative;
}

.home-bg1 {
    padding: 10px 0 0px 0;
    position: relative;
}

.home-bg2 {
    position: relative;
}

.home-bg3 {
    position: relative;
}

.home-bg4 {
    position: relative;
}

.ant-skeleton-avatar-lg {
    border-radius: 3rem !important;
}

.messaging-wrp::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
    background: url("../images/techprofile-top-banner.png") no-repeat;
    background-size: cover;
}

.home-bg3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../images/bg-gray.png") no-repeat;
    background-size: cover;
}

.home-bg4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 320px;
    background: url("../images/guarantee.png") no-repeat;
    background-size: cover;
    background-position: top;
}

.heading-gradient {
    background-image: linear-gradient(to right, #F04A22, #F08522) !important;
    -webkit-background-clip: text !important;
    color: transparent !important;
}

.icon-gradient {

    color: #F04A22 !important;

}

.home-wrp::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
    background: url("../images/techprofile-top-banner.png") no-repeat;
    background-size: cover;
}

.home-bg1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 60vh;
    background: url("../images/home-bg.png") no-repeat;
    background-size: cover;
    background-position: top;
}

.home-bg2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -1rem;
    width: 100vw;
    height: 100%;
    background: url("../images/gray-bg.png") no-repeat;
    background-size: cover;
    background-position: top;

}

.certifications .ant-space {
    width: 100%;
}

.calendar-widget .ant-picker-calendar .ant-picker-panel {
    border-top: none;
}

.ant-picker-calendar-header {
    justify-content: center;
}

.ant-modal-mask {
    /* background-color: rgba(0, 0, 0, 0.8) !important; */
    z-index: 1050 !important;
}

.ant-modal-wrap {
    z-index: 1050 !important;
}

.ant-picker-cell-in-view .ant-picker-cell-inner {
    border: 1px solid transparent;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: white;
    background: transparent;
    /* border-radius: 100%; */
    border-color: #F04A22;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #F04A22;
    /* border-radius: 100%; */
}

.ant-picker-cell .ant-picker-cell-inner {
    /* border-radius: 100%; */
}

.ant-card-bordered.service-card {
    border-radius: 7px;
    overflow: hidden;
    height: 100%;
    position: relative;
    border: 1px solid #DBDBDB;
}

.service-card .list-items {
    margin: 0;
}

.react-calendar__month-view__days__day--weekend,.react-calendar__month-view__days__day--neighboringMonth{
  color: black;

 
}

.service-card .ant-card-meta-title {
    white-space: normal;
}

.service-card .ant-card-body {
    padding: 15px 15px 90px 15px;
}

.service-card .ant-card-body .view-detail {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    border-top: 1px solid #DBDBDB;
}

.service-card .ant-card-body .list-items li {
    padding-left: 22px;
    margin: 3px 0;
}

.service-card .ant-card-body .ant-card-meta-description {
    color: #000;
}

.service-card .ant-card-body .list-items li::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    width: 14px;
    height: 13px;
    background: url("../images/icon-tick.png") no-repeat;
    background-size: contain;
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
    display: block;
}

.skill-tags .ant-tag-orange {
    color: #F04A22;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 10px;
    margin-bottom: 8px;
    border-color: #F04A22;
    border-radius: 50px;
    background-color: transparent;
}

.action-aligin {
    text-align: right;
    margin-bottom: 1rem;
}

.ant-image-mask {
    border: 1px solid rgb(219, 217, 217) !important;
}

.tech-profile-box {

    /*height: 410px;*/
}

.tech-profile-box.ant-card-bordered {
    border: none;
    background-color: white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.19);
    margin: 0.2rem;
    padding: 0.3rem 0;
}

.tech-profile-box .ant-card-cover {
    text-align: center;
    margin: 20px 0 0px 0;

}

.tech-profile-box .ant-card-cover img {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 10rem;
    border: 5px solid transparent;
    background: -webkit-linear-gradient(left top, #F04A22, #F08522);

}

.carousel-slider .owl-carousel .owl-nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin: 0;
    transform: translateY(-50%);
}

.carousel-slider .owl-carousel .owl-nav button {
    width: 30px;
    height: 30px;
    line-height: normal;
    text-align: center;
    font-size: 30px;
    background-color: #fff;
    border: 1px solid #ddd;


    position: relative;
}

.carousel-slider .owl-carousel .owl-nav button span {
    transform: scale(1.3);
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.carousel-slider .owl-carousel .owl-nav .owl-prev {
    float: left;
    margin-left: -8px;
    border-radius: 100%;
}

.carousel-slider .owl-carousel .owl-nav .owl-next {
    float: right;
    margin-right: -8px;
    border-radius: 100%;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
    background: #F04A22;
    border-color: #F04A22;
}

.carousel-slider .owl-carousel .owl-nav .owl-next {
    float: right;
    margin-right: -2rem;
}

.carousel-slider .owl-carousel .owl-nav .owl-prev {
    float: left;
    margin-left: -2rem;
}

.carousel-slider .owl-carousel .owl-nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin: 0;
    transform: translateY(-50%);
    z-index: -10;
}

.sign-up-button {

    padding: 1rem !important;
    height: 40px !important;
    width: 100px;
    margin: auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-tabs-tab:hover {

    color: #F04A22 !important;
    border-color: #F04A22 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #F04A22 !important;
    text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-ink-bar {
    position: absolute;
    background: #F04A22 !important;
    pointer-events: none;
}

.tech-profile-box .ant-card-meta-title {
    font-size: 25px;
    margin-bottom: 0 !important;
    line-height: normal;;
}

.rating-stars {
    display: block;
    margin: 0 0 3px 0;
}

.rating-stars-label {
    top: 3px;
    position: relative;
    left: 4px;
    color: #000;
    font-size: 15px;
}

.logo-box {
    position: relative;
    height: 116px;
    overflow: hidden;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    background-color: white;

}

.logo-box img {
    display: block;
    max-width: 170px;
    max-height: 80px;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btn-share img {
    float: left;
}

.btn-share.ant-btn-lg {
    height: 50px;
    margin-top: 40px;
}

.ant-btn.btn-fb {
    background-color: #4267B2;
    border-color: #4267B2;
}

.ant-btn.btn-twitter {
    background-color: #1DA1F2;
    border-color: #1DA1F2;
}

.ant-btn.btn-booking {
    background-color: transparent;
    color: white;
    border-radius: 0.3rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
}

.action-card {
    height: 200px;
}

.ant-btn.btn-booking:hover {
    border: 1px solid white;
}

.step-wizard-wrp {
    padding: 30px 0;
}

.step-wizard-wrp .ant-steps-item-icon {
    position: relative;
}

.top-steps-action {
    padding: 0 0 30px 0;
}

.step-wizard-wrp .ant-steps-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top-steps-action .logo {
    text-align: center;
}

.wiard-close {
    text-align: right;
}


.bottom-steps-action {
    margin-top: 20px;
}

.step-wizard-wrp .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.step-wizard-wrp .ant-steps-item-finish .ant-steps-item-icon {
    background: #F04A22;
    border-color: #F04A22;
}

.step-wizard-wrp .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
}
.ant-picker-time-panel-cell-inner{
    
    background-color: white !important;
}
.ant-picker-time-panel-cell-inner:hover{
    
    background-color: #F4F4F4!important;
}
.ant-picker-time-panel-cell-inner:active{
    
    background-color: #F4F4F4 !important;
}

.step-wizard-wrp .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #F04A22;
}

.step-wizard-wrp .react-calendar.calendar-techme {
    /*background-color: #F4F4F4;*/

}
.ant-skeleton-element{
    width: 100% !important;
}
.step-wizard-wrp .react-calendar.calendar-techme {
    /*border-color: #F4F4F4;*/
    border-color: white;

}
.timepickerbutton{
        background-color: #F04A22 !important;
        color: white !important;
        border: none;
}
.ant-picker-now {
    display: none !important;
}

.react-calendar.calendar-techme {
    width: 100%;
   
    border-color: #DADADA;
    border-radius: 5px;
    font-size: 16px;


}

.react-calendar__month-view__days__day--weekend, .react-calendar__month-view__days__day--neighboringMonth {
    color: black;

}

.react-calendar__disable {
    color: #b7b7b7;
}
.ant-picker-time-panel-cell .ant-picker-time-panel-cell-selected{
    background-color: #b7b7b7 !important;
}
.react-calendar.calendar-techme .react-calendar__navigation button:enabled:hover,
.react-calendar.calendar-techme .react-calendar__navigation button:enabled:focus {
    background-color: #F4F4F4;
}

.react-calendar.calendar-techme .react-calendar__tile {
    background-color: transparent !important;
}

.react-calendar.calendar-techme .react-calendar__tile abbr {
    width: 40px;
    /*margin-left: -0.2rem;*/
    height: 40px;
    line-height: 30px;
    border-radius: 100%;
    display: inline-block;
    border: 1px solid transparent;
    padding: 5px;
}
.react-calendar__year-view__months__month abbr{
    background: white !important;
}

.react-calendar_enable abbr{
    color: #F04A22;
    background-color: #ffdad1;
    font-weight: bold;
}

.react-calendar.calendar-techme .react-calendar__tile--active {
    color: #F04A22;
    font-weight: bold;
}

.react-calendar.calendar-techme .react-calendar__tile--active abbr {
    /*padding-top: -0.2rem;*/
    background-color: #F04A22;
    color: white;
}
.react-calendar-current-date abbr {
    border: 1px solid #F04A22 !important;
}
.radio-gray,
.radio-gray .ant-radio-wrapper {
    width: 100%;
}

.radio-gray .ant-radio-wrapper {
    margin: 0 0 10px 0;
    padding: 13px 10px;
    border-radius: 3px;
    border: 2px solid transparent;
    background-color: #F4F4F4;
}

.radio-gray .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner,
.radio-gray .ant-radio-wrapper.ant-radio-wrapper-checked {
    border-color: #F04A22;
    color: #F04A22;
}

.radio-gray .ant-radio-wrapper .ant-radio-inner::after {
    /*top: 50%;*/
    /*left: 50%;*/
    background-color: #F04A22;
    /*transform: translate(-50%, -50%) !important;*/
}

.radio-gray .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #F04A22;
    box-shadow: none;
}

.ant-checkbox-group.checkbox-gray .ant-checkbox-wrapper {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 13px 10px;
    border-radius: 3px;
    border: 2px solid transparent;
    background-color: #F4F4F4;
}

.checkbox-gray .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-checked .ant-checkbox-inner,
.checkbox-gray .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    border-color: #F04A22;
}

.checkbox-gray .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-checked::after {
    border-color: #F04A22;
}

.checkbox-gray .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #F04A22;
    border-color: #F04A22;
}

.checkbox-gray .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.checkbox-gray .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner,
.checkbox-gray .ant-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ddd;
}

.step-wizard-wrp .ant-input.ant-input-lg {
    border-color: #CBCBCB;
    /*padding: 12px;*/
}

.step-wizard-wrp .ant-select-selector,
.step-wizard-wrp .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ddd !important;
    box-shadow: none !important;
}

.step-wizard-wrp .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
.step-wizard-wrp .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 48px;
}

.step-wizard-wrp .select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
}

.steps-content .ant-select {
    width: 100%;
}

.step-wizard-wrp .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
}

.step-wizard-wrp .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 20px;
}

.ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    text-align: center; /* For consistent horizontal alignment */
    height: 100%; /* Ensure it fills the container */
  }

.location-box {
    position: relative;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #F4F4F4;
    background-color: #F4F4F4;
    border-radius: 5px;
}

.location-boxx {
    position: relative;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #F4F4F4;
    background-color: #F4F4F4;
    border-radius: 5px;
}

.location-box.selected {
    border: 3px solid #F04A22;
}

.location-box .box-inner {
    display: block;
    max-width: 200px;
    max-height: 90px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.location-box .box-inner figure {
    float: left;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.location-box .box-inner .text {
    margin: 0 0 0 30px;
}

.location-box.selected:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    width: 20px;
    height: 15px;
    transform: translateY(-50%);
    background: url("../images/icon-check-orange.png") no-repeat;
}

.ant-btn a {
    text-decoration: none;
}

.profile-img .ant-image {
    border-radius: 100%;
    overflow: hidden;
}

.profile-img .ant-image-img {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    object-fit: cover;
}

.page-layout {
    margin: 3rem 25%;

}


.ant-chat .ant-card-body {
    padding: 0 !important;
    margin: 0 !important;


}

.ant-card-bordered .ant-card-buttom {
    border: none !important;
    border-bottom: 1px solid #f0f0f0 !important;
}

.ant-card-bordered .ant-chat-buttom {
    border: none !important;
    padding-bottom: 0 !important;
    margin: 0 !important;

}

.ant-chat .ant-input {
    /*background-color: #f8f8f8 !important;*/
    /*border-radius: 1rem;*/

}

.navbar-light .navbar-toggler-icon {
    background-image: url("../images/menu-icon.jpg") !important;
    background-size: 50px 20px !important;

}
.ant-form-item-explain-error {
    text-align: left !important;
}

.message-input-item .ant-form-item-explain-error {
    text-align: left !important;
    padding-left: 12px !important;
}

.active-navbar {
    color: #F04A22 !important;
    font-weight: 700;
}
.ant-list-item-meta-avatar{
    /*margin-top: -.3rem !important;*/
}
.active-navbar-admin {
    color: white !important;
    font-weight: 700;
}

.admin-content-container{
    background-color: #FFFFFF;
    padding:0 20px;
    min-height: 50vh;
}

.ant-alert-message {
    text-align: left !important;
}
.ant-input-affix-wrapper.ant-chat-input {
    /*background-color: #f8f8f8 !important;*/
    /*border-radius: 0.5rem;*/

}
.ant-input-affix-wrapper.ant-chat-input:active {
    background-color: #f8f8f8 !important;

}
.ant-input-affix-wrapper.ant-chat-input:hover {
    /*background-color: #f8f8f8 !important;*/
    border: 1px solid #F04A22;

}
.ant-input-affix-wrapper.ant-chat-inpuut {
    /* background-color: #f8f8f8 !important; */
    border: 1px solid #c4c6c7;

}
.ant-input-affix-wrapper.ant-chat-inpuut:hover {
    /* background-color: #f8f8f8 !important; */
    border: 1px solid #c4c6c7;

}

.ant-input-affix-wrapper.ant-chat-inputt {
    background-color: white !important;

}

.ant-input-affix-wrapper:focus.ant-chat-input {
    border: 1px solid pink !important;


}

.asdf:hover {
    cursor: pointer !important;
}


.main-image {
    max-width: 450px;
}

@media (min-width: 1500px) {
    .main-image {
        max-width: 100% !important;
    }
}

.card-safe {
    height: 250px !important;
}

.home-h1 {
    font-size: 34px !important;
}

.home-h2 {
    font-size: 30px !important;
}

.home-margin {
    margin-bottom: 5vw !important
}

.font-button {
    font-size: 1.2rem !important;
}

.font-button2 {
    font-size: 1.2rem !important;
}

@media (max-width: 500px) {
    .home-h1 {
        font-size: 32px !important;
    }

    .home-h2 {
        font-size: 28px !important;
    }

    .main-page {
        margin: 5vw auto !important;
    }

    .card-safe {
        height: 300px !important;
    }

    .home-margin {
        margin-bottom: 40px !important
    }

    .home-main {
        margin-bottom: 30px !important
    }

    .font-button {
        margin: 0.5rem 0 !important;
        font-size: 1rem !important;
    }

    .font-button2 {

        font-size: 1rem !important;
    }
}

@media (max-width: 991px) {
    .page-layout {
        margin: 3rem 10%;
    }

    .messaging-wrp {
        padding: 20px 0 20px 0;
    }
}

.scroll::-webkit-scrollbar {
    width: 10px;
}

@media (max-width: 768px) {
    .carousel-slider .owl-carousel .owl-nav .owl-prev {
        margin-left: -25px;
        display: block;
        border-radius: 20px 2px 2px 20px !important;
    }

    .carousel-slider .owl-carousel .owl-nav .owl-next {
        margin-right: -25px;
        display: block;
        border-radius: 2px 20px 20px 2px !important;
    }
}

/* Track */
.scroll::-webkit-scrollbar-track {
    background: #fafafa;
    border-radius: 0 !important;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: #e6e8ec;
    border-radius: 1rem !important;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: #e1e2e6;
}


.ant-border-check .ant-upload {
    border-radius: 50% !important;

    width: 150px;
    height: 150px;
    margin-top: -1rem;
}

.page-layout {
    margin: 3rem 25%;

}


.ant-chat .ant-card-body {
    padding: 0 !important;
    margin: 0 !important;


}

.ant-card-bordered .ant-chat-buttom {
    border: none !important;
    padding-bottom: 0 !important;
    margin: 0 !important;

}

.booking-chat-input-wrap {
    padding: 0.5rem 0rem;
    border: none;
    display: flex;
    margin-bottom: 0.5rem;

}

.modal-booking .ant-modal-body {
    border-radius: 0.5rem !important;
    padding: 0px !important;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
}

.ant-chat .ant-input {
    /*background-color: #f8f8f8 !important;*/

}

.delete-icon.ant-upload-list-item-card-actions-btn.ant-btn-sm.del {
    display: none !important;
}

.invoice-btn.ant-btn {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    color: #F04A22;
    border-radius: 0.5rem;
    border: 1px solid #F04A22;

}

.invoice-card .ant-card-body {
    padding: 0 !important;
}

.invoice-cardd .ant-card-body {
    padding: 15px !important;
}

.ant-input-affix-wrapper.ant-chat-input {
    /* background-color: #f8f8f8 !important; */
    border: 1px solid #c4c6c7;

}

.new-header {
    display: flex;
    align-items: inherit;

    width: 100%;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.sub-menuu {
    background-color: #f8f9fa !important;
}

.new-header-link {
    height: 72px !important;
    color: black;

    font-weight: 500;
}

.ant-drawer-body {
    padding: 0;
}

/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
} */

.new-header-link:hover {
    color: #F04A22 !important;
    font-weight: 500;
}

.new-header-link-account {
    color: black !important;
    font-weight: 500;
}

.new-header-link-account:hover {
    color: black !important;
    font-weight: 500;
}

.new-header-link:active {
    color: #996c61 !important;
    font-weight: 500;
}

.ant-menu-inline .ant-menu-item::after {
    border-right: none;
}

.new-header-link:hover {
    color: #F04A22 !important;
    font-weight: 500;

}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: none;
}

.navbar-light .navbar-nav .nav-link {
    display: flex;
    align-items: center;
    height: 80px;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    word-break: break-word !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    line-height: 16px !important; /* fallback */
    max-height: 32px !important; /* fallback */
    -webkit-line-clamp: 1 !important; /* number of lines to show */
    -webkit-box-orient: vertical !important;;
}

.card-heading-size {
    font-size: 1.4rem;
}

.list-size {
    font-size: 1.2rem;
}



.heightBooking {
    min-height: 80vh;
}
.ant-skeleton-element .ant-skeleton-input {
    width: 100% !important;
}

.booking-textarea textarea.ant-input {
    font-size: 14px !important;
}
.booking-height{
    min-height: 280px;
}
@media (max-width: 767px) {
    .booking-height{
        min-height: 100%;
    }
}
@media (max-width: 1400px) {
    .booking-height{
        min-height: 320px;
    }
    .heightBooking {
        height: 100%
    }

    .list-size {
        font-size: 1rem;
    }
   

    .service-option-card {
        height: 100% !important;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between !important;
        border: 2px solid rgb(243, 242, 242);
    }

    .card-heading {
        font-size: 28px !important;
    }

    .text-two {
        word-break: break-word !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: -webkit-box !important;
        line-height: 10px !important; /* fallback */
        height: 45px !important; /* fallback */
        -webkit-line-clamp: 2 !important; /* number of lines to show */
        -webkit-box-orient: vertical !important;;
    }

    .text-three {
        word-break: break-word !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: -webkit-box !important;
        line-height: 10px !important; /* fallback */
        height: 40px !important; /* fallback */
        -webkit-line-clamp: 3 !important; /* number of lines to show */
        -webkit-box-orient: vertical !important;;
    }
}

.textt {
    word-break: break-word !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    line-height: 16px !important; /* fallback */
    max-height: 32px !important; /* fallback */
    -webkit-line-clamp: 2 !important; /* number of lines to show */
    -webkit-box-orient: vertical !important;;
}

.text-two {
    word-break: break-word !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    line-height: 20px !important; /* fallback */
    height: 50px; /* fallback */
    -webkit-line-clamp: 2 !important; /* number of lines to show */
    -webkit-box-orient: vertical !important;;
}

.text-three {
    word-break: break-word !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    line-height: 20px !important; /* fallback */
    height: 60px; /* fallback */
    -webkit-line-clamp: 3 !important; /* number of lines to show */
    -webkit-box-orient: vertical !important;;
}

.tech-text {
    font-size: 1rem !important;
    margin-top: 0px;
    word-break: break-word !important;
    overflow: hidden !important;
    padding: 0 !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    line-height: 25px !important; /* fallback */
    max-height: 50px !important; /* fallback */
    -webkit-line-clamp: 4 !important; /* number of lines to show */
    -webkit-box-orient: vertical !important;;
}

.tech-text1 {
    font-size: 1rem !important;
    margin-top: 0px;
    word-break: break-word !important;
    overflow: hidden !important;
    padding: 0 !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    line-height: 25px !important; /* fallback */
    height: 125px !important; /* fallback */
    -webkit-line-clamp: 6 !important; /* number of lines to show */
    -webkit-box-orient: vertical !important;;
}

.service-text {
    word-break: break-word !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    line-height: 24px !important; /* fallback */
}

.navbar-light .navbar-toggler {
    background-color: #F04A22;
    color: white;
}

.navbar-light .navbar-toggler:hover {
    background-color: #F04A22;
    color: white;
    border: none;
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #F04A22;


}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    border-radius: 8px;
    margin-top: -1rem;
    margin-left: -1.5rem;

    padding-bottom: 1rem !important;
    padding-top: 0 !important;
    width: 220px !important;
    /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5); */
    background-color: white;


}

.ant-menu-item:active, .ant-menu-submenu-title:active {
    background-color: transparent !important;
}

.dropdown-item {
    padding: 0.25rem 0.5rem !important;
    border-bottom: 1px solid black;
    background-color: none;


}

.white-nav {
    background-color: white !important;
    transition: 0.3s ease;
    box-shadow: 1px 0px 15px rgba(0, 0, 0, 0.2);
}

.checkhover:hover {
    box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.2);
}

.button-background {
    background-image: linear-gradient(to right, #F04A22, #F08522);
    color: white;
    height: 40px;
    padding: 0 15px;
    border-radius: 4px;
    font-weight: 500;
    font-size: medium;
}

.button-background:hover {
    background-image: linear-gradient(to right, #F04A22, #F08522);
    color: white;
}

.button-background:active {
    background-image: linear-gradient(to right, #F04A22, #F08522);
    color: white;
    border: none !important;
}

.button-background:focus {
    background-image: linear-gradient(to right, #F04A22, #F08522);
    color: white;
    border: none !important;
}

.button-background.active {
    background-image: linear-gradient(to right, #F04A22, #F08522);
    color: white;
    border: none !important;
}

.custom-drawer-style .button-background  {
    color: white !important;
}

.gradiant-button-orange {
    background-image: linear-gradient(to right, #F04A22, #F08522);
    color: white;
    height: 40px;
    /*padding: 0 5px;*/
    border-color: transparent !important;
    border-radius: 4px;
    font-weight: 500;
    font-size: medium;
}

.gradiant-button-orange:hover {
    background-image: linear-gradient(to right, #F04A22, #F08522);
    color: white;
    border: none !important;
}

.gradiant-button-orange {
    border: none !important;
}

.gradiant-button-orange:active {
    background-image: linear-gradient(to right, #F04A22, #F08522);
    color: white;
    border: none !important;
}

.gradiant-button-orange:focus {
    background-image: linear-gradient(to right, #F04A22, #F08522);
    color: white;
    border: none !important;
}

.gradiant-button-orange.active {
    background-image: linear-gradient(to right, #F04A22, #F08522);
    color: white;
    border: none !important;
}




.dropdown-item:focus, .dropdown-item:hover {
    color: #F04A22 !important;
    background-color: transparent !important;
    text-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.3) !important;


}

.vl {
    border-left: 3px solid white;
    height: 44px;
    margin: 0;
    padding: 1rem 1.5rem;
    margin-right: 0.3rem;
    color: #393939 !important;

}

@media (max-width: 991px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        width: 90vw;
        text-align: center;
    }

    

    .navbar-light .navbar-nav .nav-link {
        height: 50px;


        text-align: center;

    }

    .new-header-direction {
        flex-direction: column;
        margin: 0
    }

    .page-layout {
        margin: 3rem 10%;
    }

    .messaging-wrp {
        padding: 20px 0 20px 0;
    }
}

.scroll::-webkit-scrollbar {
    width: 10px;

}

@media (max-width: 766px) {
    .action-aligin {
        text-align: inherit;
    }

    .action-card {
        height: auto;
    }
}

/* Track */
.scroll::-webkit-scrollbar-track {
    background: #fafafa;
    border-radius: 0 !important;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: #e6e8ec;
    border-radius: 1rem !important;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: #e1e2e6;
}

.nav-links {
    text-decoration: none;


}

.nav-links:hover {

    font-weight: bold;
}

.nav-links.active {

    font-weight: bold;
}

.footer-widgets {
    color: #fff;
    padding: 0;
    background-color: #F04A22;

}

.new-footer-widgets {
    color: #030303;
    padding: 30px 0;
    background-color: #F9FAFC;

}

.quick-links ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.quick-links ul li {
    width: 100%;
    display: inline-block;
    margin: 5px 0;
}

.quick-links .ant-typography {
    color: #fff;
    text-decoration: none;
    font-size: medium;
}

.quick-links .ant-typography:hover {
    color: #F04A22;
}

.quick-links .ant-typography-footer {
    color: black;
    text-decoration: none;
    font-size: medium;
}

.quick-links .ant-typography-footer:hover {
    color: black;
    font-weight: 500;
}

.ant-comment-content-author {
    display: block !important;
    margin: 0 !important;
}

.ant-comment-inner {
    padding: 0 !important;
}

.social-links {
    float: left;
    width: 100%;
    margin: 10px 0;
}

.social-links ul {
    padding: 0;
    list-style: none;
}

.social-links ul li {
    float: left;
    margin: 0 20px 0 0;
    text-align: center;
}

.social-links ul li a {
    width: 40px;
    height: 40px;
    display: block;
    line-height: 35px;
    border: 1px solid #fff;
    border-radius: 100%;
    color: #fff;
    transition: all 0.3s ease-in-out 0s;
}

.social-links ul li a img {
    width: 16px;
}

.social-links ul li a:hover {
    background-color: #F04A22;
    border-color: #F04A22;
}

.our-support {
    float: left;
    width: 251px;
    padding: 20px;
    margin: 20px 0 0 0;
    background-color: #001B55;
    border: 1px solid rgba(255, 255, 255, 0.28);
}

.our-support figure {
    float: left;
    width: 36px;
}

.our-support figure img {
    width: 100%;
}

.our-support .text {
    margin: 0 0 0 50px;
    font-size: 18px;
    line-height: normal;
}

.our-support .text strong {
    display: block;
    margin: 6px 0 0 0;
}

.copyright {
    padding: 15px 0;
    color: #fff;
    background-color: #F04A22;
}

.inputStyle {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.service-box .ant-card-meta-description {
    color: #464646;
    font-size: 16px;
}

.service-box .ant-card-meta-title {
    font-size: 18px;
}

.service-tab .ant-tabs-tab-btn {
    font-size: 18px;
}

.tech-bio {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.bookings {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.status {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.tech-name {
    height: 21px;
    text-transform: capitalize;
    margin: 0;
}

.tech-city {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.review-box .ant-card-body {
    padding: 10px 25px 5px 25px
}

.ant-rate-star:not(:last-child) {
    margin-right: 2px !important;
}

.ant-form-item {
    margin-bottom: 10px;
}

.usageType .radio-button {
    /*height: 5em;*/
}


.onboarding-step-wizard {
    padding: 30px 0;
}

.onboarding-step-wizard .ant-steps-item-icon {
    position: relative;
}

.onboarding-step-wizard .ant-steps-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.onboarding-step-wizard .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.onboarding-step-wizard .ant-steps-item-finish .ant-steps-item-icon {
    background: #F04A22;
    border-color: #F04A22;
}

.onboarding-step-wizard .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
}

.onboarding-step-wizard .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #F04A22;
}

.onboarding-step-wizard .react-calendar.calendar-techme {
    background-color: #F4F4F4;
}

.onboarding-step-wizard .react-calendar.calendar-techme {
    border-color: #F4F4F4;
}

.onboarding-step-wizard .ant-input.ant-input-lg {
    border-color: #CBCBCB;
    /*padding: 12px;*/
}

.onboarding-step-wizard .ant-select-selector,
.onboarding-step-wizard .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ddd !important;
    box-shadow: none !important;
}

.onboarding-step-wizard .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
.onboarding-step-wizard .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 48px;
}

.onboarding-step-wizard .select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    line-height: 48px;
}

.steps-content .ant-select {
    width: 100%;
}

.onboarding-step-wizard .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
}

.onboarding-step-wizard .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 38px;
}

.suggestions .ant-card-meta-title {
    white-space: normal;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #F04A22 !important;
    border-color: #F04A22 !important;
}

.img-selected {
    border: 2px solid #F04A22;
}

.page {
    padding: 0px 0 15px 0;
    position: relative;
    margin: 0 auto;
    overflow-x: hidden;
}

/* For pages without side navigation */
.page-ctn{
    margin-top: 20px;
}

.mainbody {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.navForMobile {
    display: none;
}

.navForWeb {
    display: block;
}

#outline-default-btn:disabled {
    color: #00000040 !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none !important;
    box-shadow: none !important;
}


@media (max-width: 991px) {
    .page .techprofile-wrp {

        padding: 10px 0 0 0;

    }
}

@media (max-width: 991px) {
    .navForMobile {
        display: block;
    }

    .navForWeb {
        display: none;
    }
}

.mobile-no-data-image {
    width: 300px;
}


.ant-switch-checked {
    background-color: #F04A22;
    /*background-color: #F04A22;*/

}

.ant-radio-inner::after {
    background-color: #F04A22;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #F04A22;
}

.tech-slider {
    padding: 120px 0 30px 0;
    background: url("../images/bg-blue.png") no-repeat;
    background-size: cover;
}

.find-tech-container {
    background: url("../images/find-tech-bg.png") no-repeat;
    padding: 20px;
    background-size: cover;
    margin-top: 25px;
    border-radius: 10px;
}

.bookings-container {
    padding: 20px;
    background-size: cover;
    margin-top: 25px;
    border-radius: 10px;
}

.gradiant-bookings {
    background: url("../images/manage-bookings.png") no-repeat;
    background-size: cover;
    border-radius: 1rem;
    margin-bottom: 0.5rem;
    height: 180px;
}

.gradiant-bg {
    background: url("../images/gradiant-bg.png") no-repeat;
    background-size: cover;
}

.highlight-box {
    padding: 60px 10px 40px 10px;
}

.highlight-gradiant {
    background: url("../images/small-linear-gradiant.png") no-repeat;
    background-size: cover;
}

.location-modal-content {
    padding: 5rem 2rem 0rem 1rem;
}

.rccs {
    margin: 0 !important;
}

.tech-profile-badge .ant-ribbon-placement-start {
    display: none;
}

.service-type-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.service-type-img {
    margin-bottom: 0.5rem;
    width: 300px
}

.service-type-paragraph {
    color: #7D7D7D;
    width: 300px;
}

.service-location-modal-paragraph {
    margin: 0 !important;
    text-transform: uppercase;
    font-weight: 500;
}

.service-location-modal {
    display: flex;
    border-radius: 0.5rem;
    align-items: center;
    justify-content: space-between;
}

.service-location-address {
    margin: 0;
    margin-bottom: 0.3rem;
    background-color: #F4F4F4;
    color: black;
    padding: 0.2rem 0.5rem;
    align-items: center;
    width: fit-content;
    border-radius: 5rem;
}

.service-type-active {
    margin: 0;
    margin-left: 0.5rem;
    margin-top: 0.1rem;
    background-color: #F04A22;
    padding: 0.1rem 0.5rem;
    border-radius: 2rem;
    color: white
}

.service-type-title {
    display: flex;
    align-items: center;
}

.device-card {
    position: relative;
    display: inline-block;

    cursor: pointer;
}

.device-card .img-top {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    /*height: 100%;*/
}

.device-card:hover .img-top {
    display: inline;
}

.service-option-card {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between !important;
    height: 100%;
    border: 2px solid rgb(243, 242, 242);
}

.bIBabz {
    width: 100%
}

.lh-40 .ant-select-selection-item {
    line-height: 40px !important;
}
.time-slot .ant-radio{
    display: none;
}
.time-slot-radio{
    background-color: white !important;
    border: 1px solid !important;;
    border-radius: 5px !important;;
    justify-content: center !important;;
    font-weight: bold !important;;
}
.site-page-header {
    background: #FFFFFF;
}

 .ant-page-header-heading-left{
    flex-direction: column;
    align-items: flex-start;
}

.site-page-header .ant-page-header-heading-sub-title{
    color: #000000;
    margin-top: 15px;
}

.site-page-header.ant-page-header.has-breadcrumb{
    padding-top: 20px !important;
}

.service-title-column{
    width:200px
}
.subscriptions-action-column{
    width:100px
}

.service-request-row {
    background-color: #fff1ed;
}
.service-payment-required-row {
    background-color: #e4ffeb;
}

.ant-pagination-item-link .anticon{
    vertical-align: 0
}
.next-action-section{
    background-image: linear-gradient(to right, #F04A22, #F08522);
    padding: 1rem;
    border-radius: 2px;
    min-height: 170px
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector, .ant-picker-focused, .ant-picker:hover, .ant-picker-focused, .ant-input:hover,
.ant-input:focus, .ant-input-focused, .ant-pagination-item-active, .ant-pagination-item:hover,
.ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover button, .ant-pagination-next:hover button,
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link,
.ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn:hover, .ant-btn:focus,.ant-btn:active,
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover
{
    border-color: #F04A22 !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-btn-primary:hover
{
    background-color: #fff1ed !important;
    background-image: none !important;
    color: #F04A22 !important;
    border-color: #F04A22 !important;
}
.ant-btn-primary,.ant-btn-default{
    border-color: #F04A22 !important;
}
.ant-btn-default:hover, .ant-btn-default, .ant-btn-default:focus{
    color: #F04A22 !important;
}
.ant-picker-range .ant-picker-active-bar,.ant-btn-primary,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, #cm.cloud .c-bn:first-child,
.ant-btn-primary:active,.ant-btn-primary:focus, .ant-progress-bg
{
    background-color: #F04A22 !important;;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link,
.ant-btn-primary:hover, .ant-btn-primary:focus,
.ant-btn:hover,.ant-btn:active,.ant-btn:focus,
.ant-upload-drag-icon svg,
/* .anticon-exclamation-circle svg, */
.anticon-info-circle svg, .chat-bubble a,
.ant-result-info .ant-result-icon > .anticon
{
    color: #F04A22 !important;
}

.ant-btn.ant-btn-secondary:disabled, .ant-btn.ant-btn-secondary:disabled:hover{
    color: rgba(0, 0, 0, 0.45) !important;
    border-color: #d9d9d9 !important;
 }

.anticon-info-circle svg {
    fill: #F04A22 !important;
}
/*.ant-btn*/
/*{*/
/*    border-radius: 10px;*/
/*}*/
.ant-btn-primary:focus{
    color: white !important;
}
.ant-btn.ant-btn-block{
    width: auto !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused
{
    box-shadow: 0 0 0 2px #dc35451f;
    border-color: #F04A22;
}
.cc_div .b-tg .c-tgl:checked~.c-tg, #c-bns button:first-child, #s-bns button:first-child{
    background-color: #F04A22 !important;
}
.attachment-button .ant-image{
    margin-right: 10px;
}
aside.emoji-picker-react{
    position: absolute !important;
    bottom: 20px;
    left: 0%;
}
.chat-wrapper{
    /*margin: 0 auto;*/
    /*overflow: hidden;*/
}
.chat-container{
    overflow-y: auto;
    overflow-x: hidden;
    height: 55vh;
    /* transform: rotate(180deg); */
    /* direction: rtl; */
    padding-top: 20px;
    display: flex;
    flex-direction: column-reverse;


    /*overflow-y: scroll;*/
    /*overscroll-behavior-y: contain;*/
    /*scroll-snap-type: y proximity;*/
}
.chat-container .chat-container-content {
    /* transform: rotate(180deg); */
}

.message-container:last-child {
    /* scroll-snap-align: end; */
}
.message-container{
    /* transform: rotate(180deg); */
    /* direction: ltr; */
}
.ant-radio-button-wrapper:focus-within,
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child
{
    box-shadow: none !important;
}
.anticon {
    vertical-align: 0 !important;
}
.sticky-banner{
    padding: 6px 10px;
    text-align: center;
    background-color: #fff7f6;
    font-size: 1rem;
    z-index: 999;
    width:100%;
    margin-bottom:5px;
}

.sticky-banner-ctn{
    margin-left: 220px;
    margin-right:55px; 
}

@media screen and (max-width: 991px) {
    .sticky-banner-ctn{
        margin-left: 16px;
        margin-right:16px; 
    }
}


.DraftEditor-root{
    padding: 0 10px;
}
.ant-notification{
    z-index: 2000 !important;
}
.service-hipaa-badge{
    position: absolute;
    z-index: 100;
    height: 45px;
    width: 45px;
    bottom: -1rem;
    left: 2rem;
    scale: 3;
}
.container{
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

a.rdw-dropdown-selectedtext {
    color: black;
}

.ant-btn-primary:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #D9D9D9 !important;
    background: #F5F5F5 !important;
    text-shadow: none !important;
    box-shadow: none !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-input-number-affix-wrapper:focus, .ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover,
.ant-checkbox-checked::after
{
    border-color: #F04A22 !important;
}
.ant-input-number-affix-wrapper:focus, .ant-input-number-affix-wrapper-focused{
    box-shadow: none !important;
}

.ant-table-container{
    min-height: 40vh;
}

.ant-list span.ant-spin-dot.ant-spin-dot-spin .ant-spin-dot-item{
    background-color:#F04A22 !important
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent !important;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #F04A22 !important;
}

/*AI Button*/

.ai-chat-btn{
    font-weight:500; 
    border:0; 
    box-shadow:none; 
    padding:0;
}

.ai-chat-btn span{
    color:#000; 
}

/* popver stylings */

.global-popover-content .popover-action-item{
    width:100%;
    padding:8px 12px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.global-popover-content .popover-action-item:hover{
    background-color:#F5F5F5; 
    width: 181px !important;
}


.global-popover .ant-popover-arrow{
    display: none;
}

.global-popover{
    padding-top:0;
}

.global-popover .ant-popover-content{
    width: 181px !important;
}

.global-popover .ant-popover-inner-content{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 4px !important;	
    padding-bottom: 4px !important;	
}


@media (max-width:991px) {
    .auth-page-ctn{
        padding:0px; 
    }
    .auth-container{
        padding:0 25px;
    }

    .ant-page-header{
        padding:16px 25px;
    }
}

@media (max-width:481px) {
    .page-title-row{
        display: block;
    }
    .page-title-row h3.ant-typography {
        font-size: 21px;
    }

    .page-search-col{
        margin-top:15px;
    }
}
/*
  apply css on second div inside discovery-search-user-rating class
*/
.discovery-search-user-rating > div:nth-child(2) {
    color: black;
}

.icon-ctn{
    border: 1px solid #D9D9D9;
    width: 29.57px;
    height: 29.57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}
.tech-card-search-page{
    text-decoration: none;
}

.custom-ant-tag{
    color: #3A3A44;
    font-size: 14.1px;
    font-style: normal;
    font-weight: 400;
    padding: 4px 9.4px;
    border-radius: 2.35px;
    border: 1.175px solid #D9D9D9;
    background: #F0F0F0;
    margin-bottom: 8px;
}

.loading-dots {
    width: 20px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side,#F04A22 90%,#0000) 0/calc(100%/3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: d1 1s steps(4) infinite;
  }
  @keyframes d1 {to{clip-path: inset(0 -34% 0 0)}}