.utility-header{
    width: 100%;
    padding: 40px 50px 20px 50px;
    @media screen and (max-width: 991px) {
        padding: 24px;
    }
    &.login-utility-header{
        padding: 24px 40px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(23, 24, 24, 0.05);
        @media screen and (max-width: 991px) {
            padding: 24px 16px !important;
        }
        .utility-tecme-logo{
            img{
                width:101px;
            }
        }
    }
    .utility-tecme-logo{
        img{
            width:131px;
            cursor: pointer;
        }
    }
    .login-link a{
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        text-decoration: none;
    }

    .team-name{
        font-size: 14px;
        color: #000000;
        margin: 0;
    }
}

.nav-menu-popover-content .sub-menu-link:hover{
    background-color:#F5F5F5; 
    width:380px;
}

.nav-menu-popover-content .team-menu-link{
    width:380px;
    padding:5px 15px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    margin-bottom: 4px;
    &:hover{
        background-color:#F5F5F5; 
        width:380px;
    }
    &.team-menu-link-active{
        background-color: #FFE2DB;
    }
}

.nav-menu-popover .ant-popover-arrow{
    display: none;
}

.nav-menu-popover{
    padding-top:0;
}

.nav-menu-popover .ant-popover-content{
    width: 380px !important;
    margin-top: 12px;
}

.nav-menu-popover .ant-popover-inner-content{
    padding: 5px 0px !important;
    padding-bottom: 2px !important;
}
