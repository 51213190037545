.manage-job-ctn{
    margin-top:60px;
    .manage-job-header{
        margin-bottom:50px
    }
    .manage-job-card{
        padding:24px;
        border: 1px solid #D9D9D9;
        @media screen and (max-width: 991px) {
            border: 0;
            padding: 0;
        }
        .manage-job-details{
            h4.ant-typography{
                margin-bottom:30px;
            }
            .manage-job-description{
                margin-bottom:40px;
            }
            .download-cv{
                margin-bottom: 8px;
                a{
                    color: var(--secondary-color);
                    text-decoration: none;
                }
            }
            .job-skills{
                @media  screen and (max-width: 991px) {
                    margin-bottom: 40px;
                }
            }
            .other-job-info{
                margin-top:40px;
                .job-post-details-content-item{
                    display: flex;
                    column-gap: 8px;
                    margin-bottom: 20px;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    .job-post-details-content-icon{
                        position: relative;
                        top:2.5px;
                    }
                    .job-post-details-content-subitem{
                        div.ant-typography{
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            &:last-of-type{
                               color: rgba(0, 0, 0, 0.65);
                            }
                        }
                    }
                }
            }   
        }
    }
    .manage-job-cta-ctn{
        border-left: 1px solid #D9D9D9;
        padding-left: 24px;
        @media screen and (max-width: 991px) {
            border: 0;
            padding: 0;
        }
        .manage-job-cta{
            margin-top: 10px;
            padding-left: 16px;
            margin-bottom:20px;
            .manage-job-cta-item{
                display:flex;
                column-gap: 8px;
                align-items: center;
                margin-bottom:20px;
                cursor: pointer;
                div.ant-typography{
                    margin:0;
                    color: var(--secondary-color);
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
        .manage-job-cta-info{
            margin-top: 74px;
            .my-details{
                margin-bottom: 30px;
                h4.ant-typography{
                    margin-bottom: 16px;
                }
                .details-rate{
                    div.ant-typography{
                        margin:0;
                        font-size: 14px;
                    }
                }
            }
            .payment-info{
                border-top:1px solid #D9D9D9;
                border-bottom:1px solid #D9D9D9;
                padding:30px 0;
            }
            .projects-info{
                padding: 30px 0;
            }
        }
    }
}
.job-applications-section{
    margin-top: 48px;
}

.mobile-manage-job-posting{
    display: none;
    @media screen and (max-width: 991px) {
        border-top: 1px solid #EAECF0;
        padding:24px 16px;
        position: fixed;
        left:0;
        bottom:0;
        width: 100%;
        justify-content:flex-start;
        background-color: #fff;
        display: flex;
        z-index: 9;
        column-gap: 16px;
        .action-job-icon{
            flex: 0 0 auto; 
        }
        .edit-job-btn{
            flex: 1;
        }
    }
}


.job-details-posting-popover .ant-popover-content{
    width: 250px !important;
}

.job-details-posting-popover-content .popover-action-item:hover{
    background-color:#F5F5F5; 
    width: 250px !important;
}