.view-edit-contract-proposal {
    min-height: calc(100vh - 64px);
    padding-bottom: 70px !important;
    .seleted-tab-container-assigned-ticket {
        .ant-card-body {
            padding: 0;
        }
    }
    .ant-divider {
        border-color: #C2CBDC !important;
    }
    .mid-section {
        padding-bottom: 12px;
        .main-heading {
            font-size: 25px;
            font-weight: 300;
        }
    }
    .right-side-card {
        .secondary-color-sub-heading {
            font-size: 13px;
            margin: 0;
        }
        .rating-wrapper {
            margin-top: -3px;
        }
    }
    .main-content {
        display: grid;
        grid-template-columns: calc(100% - 310px) 300px;
        column-gap: 10px;
    }
    .active-contract-main-content {
        display: grid;
        grid-template-columns: calc(100% - 360px) 350px;
        column-gap: 10px;
    }
    .payment-image {
        object-fit: cover;
    }
    .selected-payment-method {
        background-color: var(--light-bg-color);
        border: 1px solid var(--medium-orange-bg-color) !important;
        height: 70px;
        .fs-6 {
            color: var(--secondary-color);
        }
    }
    .payment-method {
        height: 70px;
        border-radius: 2px;
        padding: 12px;
        border: 1px solid var(--border-color);
    }
    .contract-status-tag-default {
        font-size: 14px;
        border-radius: 3px;
        padding: 4px 18px;
    }
    .mobile-sub-heading {
        font-size: 13px;
        margin-top: -3px;
        font-weight: 400;
    }
    .time-white-btn, .time-white-btn:hover, .time-white-btn:focus, .time-white-btn:active{
        background: #FFFFFF !important;
        border: 1px solid #D9D9D9 !important;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
        border-radius: 2px !important;
        color: #000000 !important;
    }
    .time-white-btn:disabled {
        background-color: #f6f6f6 !important;
    }
    .ant-table table{
        border:1px solid #F0F0F0;
        border-bottom: 0;
        font-size: 14px;
    }
    .total-time-card{
        .ant-card-head{
            padding: 0 12px;
        }
        .ant-card-body{
            padding-left:12px;
            padding-right: 12px;
        }
    }
    .active-contract-proposal {
        .main-heading {
            font-size: 30px;
            font-weight: 300;
        }
        .customer-details {
            justify-content: center;
            .view-profile-button {
                margin: 0px;
                margin-top: -3px;
                font-size: 14px;
                color: var(--secondary-color);
                cursor: pointer;
            }
        }
        .assigned-ticket-mobile-wrapper {
            .empty-assigned-tickets-mobile {
                min-height: 48vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .add-ticket-button-mobile {
                display: grid;
                place-items: center;
            }
            .empty-timesheet-mobile {
                min-height: 42vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
        .empty-invoices-mobile {
            min-height: 40vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    .ant-checkbox {
        span.ant-checkbox-inner {
            border-radius: 50%;
        }
    }
    .mobile-chat-form {
        position: relative !important;
        z-index: 1;
        padding: 0 !important;
        padding-top: 1rem !important;
    }
    .min-height-55vh {
        min-height: 55vh;
        table {
            min-height: calc(55vh);
            height: 100%;
        }
    }
    .payment-methods-wrapper {
        max-height: 45vh;
        overflow-y: auto;
    }
    .activity-card-body{
        .ant-card-body{
            padding:0 !important;
        }
        .activites-wrapper-desktop{ 
            max-height: 40vh;
            overflow-y: auto;
            width: 100%;
            &::-webkit-scrollbar {
                width: 10px;
              }
              
            &::-webkit-scrollbar-track {
                background: #ffffff;
              }
              
            &::-webkit-scrollbar-thumb {
                background: #dad5d5;
            }
            .activites-inner{
                padding:20px 24px 0 24px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .mid-section {
            .main-heading {
                font-size: 20px;
            }
        }
        .selected-payment-method {
            height: auto;
        }
        .payment-method {
            height: auto;
        }
    }
}

.edit-contract-modal {
    .ant-radio-group {
        display: flex;
        gap: 10px;
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            border-color: var(--secondary-color) !important;
            color: var(--secondary-color) !important;
            &::before {
                background-color: var(--secondary-color) !important;
            }
        }
    }
    .ant-form-item-label {
        font-weight: bold;
    }
}

.new-support-ticket-modal {
    .ant-radio-group {
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            border-color: var(--secondary-color) !important;
            color: var(--secondary-color) !important;
            &::before {
                background-color: var(--secondary-color) !important;
            }
        }
    }
    .ant-form-item-label {
        font-weight: bold;
    }
    .ant-upload-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .ant-upload-list-picture .ant-upload-list-item{
        width:205px;
        margin-right:10px;
        margin-bottom:2px;
    }
    @media screen and (min-width: 768px) {
        & {
            min-width: 700px;
        }
        .priority-item {
            width: 100%;
            justify-content: flex-end;
            display: flex;
        }
    }
}

.disclaimer {
    background-color: var(--light-bg-color);
    border-radius: 2px;
    border: 1px solid var(--secondary-color);
}

.contract-status-tag {
    color: var(--secondary-color);
    font-size: 14px;
    background-color: var(--light-secondary-color);
    border-radius: 3px;
    padding: 4px 18px;
    border-color: var(--light-secondary-color);
}

.ticket-updated-successfully-wrapper {
    .ant-modal-confirm-title {
        margin-top: -1.2rem;
    }
}

@media screen and (min-width: 768px) {
    .edit-contract-modal-component {
        width: 700px !important;
    }
}

// .edit-contract-proposal-it-skills-select {
//     overflow: hidden;
// }

 /*Select  Scrollbar styles */
 .edit-contract-proposal-it-skills-select .rc-virtual-list-holder::-webkit-scrollbar{
    width: 8px;
    background: transparent;
   
}
 .edit-contract-proposal-it-skills-select .rc-virtual-list-holder::-webkit-scrollbar-thumb{
    background: rgba(0, 0, 0, 0.5) !important;
    border-radius: 99px;
}

.edit-contract-proposal-it-skills-select .rc-virtual-list-holder::-webkit-scrollbar-thumb:hover{
    background-color: #555;
}


.ant-select-selection-search-input {
    min-width: 120px !important;
}

.add-payment-method-button {
    height: 40px;
}

.payment-change-modal {
    .payment-image {
        object-fit: cover;
    }
    .selected-payment-method {
        background-color: var(--light-secondary-color);
        border: 1px solid var(--medium-orange-bg-color) !important;
        height: 70px;
        .fs-6 {
            color: var(--secondary-color);
        }
    }
    .payment-method {
        height: 70px;
        padding: 8px;
        border: 1px solid var(--border-color);
    }
    .ant-checkbox {
        span.ant-checkbox-inner {
            border-radius: 50%;
        }
    }
}

.assigned-ticket-container{
    @media (max-width:991px) {
        padding:20px;
    }
}

#file-dispute-button {
    &:disabled {
        color: #00000040 !important;
        border-color: #d9d9d9 !important;
        background: #f5f5f5 !important;
        text-shadow: none !important;
        box-shadow: none !important;
    }
}