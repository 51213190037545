.step-switch-cta{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .skip-cta{
        margin: 0;
        color: rgba(0, 0, 0, 0.85);
        text-decoration: underline;
        cursor: pointer;
    }
}
.mobile-progress-bar-section{
    display: none;
    @media screen and (max-width:991px) {
        display:block;                   
    }
    .mobile-progress-bar{
        margin: 40px 0 30px 0;
    }
}
.main-form-content{
    margin-top:45px;
    // width: 755px;
    @media screen and (max-width: 991px) {
        margin-top: 16px; 
    }
}


// Next Button Stylings
.onboarding-next-btn{
    height:40px;
    width:132px;
    // @media  screen and (max-width: 576px) {
    //     width:100%;
    // }
}

.company-details-heading{
    width: 755px;
    @media screen and (max-width: 1200px) {
        width: 100%;      
    }
    .welcome-heading{
        font-weight: 400;
        font-size: 26px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 20px;
    }
    .welcome-subheading{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom:4px;
    }
    .welcome-text{
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.65);
    }
}


// company details stylings

.company-details-section{
    width: 755px;
    @media screen and (max-width: 1200px) {
        width: 100%;   
    }
    .company-details-form-ctn{
        margin-bottom: 28px;
        .form-heading{
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 24px;
        }
        .company-details-form{
            width:687px;
            @media screen and (max-width: 1200px) {
                width: 100%;   
            }
            .company-details-form-item{
                margin-bottom: 24px;
                .ant-form-item-label > label{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.85);
                    margin-bottom: 8px;
                    &::after{
                        display:none;
                    }
                    // &::before{
                    //     content: "*";
                    //     position: absolute;
                    //     left: 0;
                    // }
                }
                .company-name-input{
                    height: 40px;
                }
                .company-details-form-select{
                    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
                        height: 40px;
                    }
                    
                    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
                        line-height: 40px;
                    }
                    
                    &.ant-select-single .ant-select-selector .ant-select-selection-item{
                        line-height: 40px;
                    }
                }
                .company-details-form-textarea{
                    height: 100px;
                }
            }
        }
    }
}

// Payment Details stylings

.payment-details-section{
    width: 755px;
    @media screen and (max-width: 1200px) {
        width: 100%;   
    }
    .payment-details-heading{
        margin-bottom: 45px;
        .add-payment-heading{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom:4px;
        }
        .add-payment-text{
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.65);
        }
    }
    .payment-details-form-ctn{
        .payment-details-form{
            .payment-billing-details{
                border-bottom: 1px solid #EAECF0;
                padding-bottom:30px;
                margin-bottom: 24px;
                &:last-of-type{
                    border-bottom: none;
                    margin-bottom: 0;
                }
               .payment-method-heading{
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.85);
                    margin-bottom: 24px;
                } 
               .payment-details-form-item{
                    margin-bottom: 24px;
                    .ant-form-item-label > label{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        color: rgba(0, 0, 0, 0.85);
                        margin-bottom: 8px;
                        &::after{
                            display:none;
                        }
                    }
                    .payment-billing-input{
                        height: 40px;
                    }
                    .payment-details-checkbox{
                        margin-top:40px;
                        margin-bottom: 40px;
                    }
                    .company-details-form-select{
                        &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
                            height: 40px;
                        }
                        
                        &.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
                            line-height: 40px;
                        }
                        
                        &.ant-select-single .ant-select-selector .ant-select-selection-item{
                            line-height: 40px;
                        }
                    }
                }
            }
        }
    }
}


// Team Details stylings

.team-details-section{
    margin-bottom: 50px;
    width: 755px;
    @media screen and (max-width: 1200px) {
        width: 100%;   
    }
    .team-details-heading{
        margin-bottom: 40px;
        .team-heading{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom:4px;
        }
        .team-text{
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.65);
        }
    }
    .team-details-form-ctn{
        .member-form-row{
            width: 625px;
            margin-bottom: 4px;
            margin-right: 0 !important;
            @media screen and (max-width: 1200px) {
                width: 100%;   
            }
            @media screen and (max-width: 768px) {
                column-gap: 0 !important;   
            }
            .ant-select-selection-placeholder{
                color: rgba(0, 0, 0, 0.85);
            }
            .member-form-input{
                height: 40px;
            }
            .member-form-select{
                &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
                    height: 40px;
                }
                
                &.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
                    line-height: 40px;
                }
                
                &.ant-select-single .ant-select-selector .ant-select-selection-item{
                    line-height: 40px;
                }
            }
        }
        .input-close-icon{
            cursor:pointer; 
            color:#00000073; 
            font-size:16px; 
            margin-bottom:12px;
            @media screen and (max-width: 768px) {
                color:rgba(0, 0, 0, 0.65);
                text-align: right;
                font-size: 14px;
            }        
        }
        .cta-form-row{
            margin-top:4px;
        }
    }
}

// Success onboarding stylings

.success-onboarding-section{
    display: flex;
    justify-content: center;
    .ant-result-success .ant-result-icon > .anticon{
        color: #F04A22 !important;
    }
    .success-onboarding-text{
        color: rgba(0, 0, 0, 0.45);
        text-align: center;
        font-size: 16px;
        width: 717px;
        margin-bottom: 40px;
        @media screen and (max-width: 1200px) {
            width: 100%; 
        }
    }
}
