.ant-upload-inbox-icon-wrapper svg {
  font-size: 48px;
  color: var(--secondary-color);
}

.device-details-image-ctn{
    width:100%;
    height:100%;
}
.device-image{
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.ant-table-row{
  cursor: pointer;
}

.text1{
  color:"#00000073";
  font-size: 12; 
  font-weight: 400;
}

.text2{
  color:"#000000";
  font-size: 14;
  font-weight: 400;
}

@media (min-width: 991px) {
  .ant-list-item {
    flex: 0 0 33.3333%; /* 3 cards per row */
  }
}


.devices-popover-content .popover-action-item{
    width:100%;
    padding:8px 0;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.devices-popover-content .popover-action-item:hover{
    background-color:#F5F5F5; 
    width: 181px !important;
}


.devices-popover .ant-popover-arrow{
    display: none;
}

.devices-popover{
    padding-top:0;
}

.devices-popover .ant-popover-content{
    width: 181px !important;
}

.devices-popover .ant-popover-inner-content{
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 8px !important;	
    padding-bottom: 8px !important;	
}

/* .device-details-image-ctn img{
  width: 100%;
  height: 300px;
  object-fit: cover;
} */