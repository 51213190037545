.job-post-details-card{
    border: 1px solid #D9D9D9;
    border-radius: 0px 0px 8px 8px;
    .job-post-details-heading{
        border-bottom: 1px solid #D9D9D9;
        padding: 24px;
        div.ant-typography{
            font-weight: 500;
            color: var(--secondary-color);
        }
    }
    .job-post-details-content{
        padding:24px;
        .job-post-details-content-item{
            display: flex;
            column-gap: 8px;
            margin-bottom: 20px;
            align-items: flex-start;
            &:last-of-type{
                margin-bottom: 0;
            }
            // .job-post-details-content-icon{
            //     // position: relative;
            //     // top:2.5px;
            // }
            .job-post-details-content-subitem{
                div.ant-typography{
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    &:last-of-type{
                       color: rgba(0, 0, 0, 0.65);
                    }
                }
            }
        }
    }
}