@media only screen and (min-width: 1201px) and (max-width: 1400px) {

}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .container {
        width: 100%;
        max-width: inherit;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 100%;
        max-width: inherit;
    }

    .ant-layout-header.header {
        position: static;
        padding: 18px 0;
    }

    .banner,
    .appstore-section,
    .press-releases,
    .tech-info-wrp,
    .techprofile-wrp {
        padding: 30px 0;
    }

    .onboarding-wrp {
        padding: 30px 0;
    }

    .tech-guy {
        display: none;
    }

    .tech-info-wrp {
        margin: 0;
    }

    .header .ant-menu-horizontal .ant-menu-item::after {
        bottom: -26px;
    }

    .search-tech.ant-form-inline {
        margin: 0 0 20px 0;
    }

    .mb-5 {
        margin-bottom: 2rem !important;
    }

    .mt-5 {
        margin-top: 2rem !important;
    }

    .hide-on-sm {
        display: none;
    }

    .home-slider {
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {
    .service-option-card{
        min-height: 200px;
    }
    .container {
        width: 100%;
        max-width: inherit;
    }

    .ant-layout-header.header {
        position: relative;
        padding: 12px 0;
    }
    .tech-slider{
        padding: 20px 0 30px 0;
    }
    .location-modal-content{
        padding: 0;
    }
    .find-tech-container{
        border-radius: 0px;
    }
    .highlight-gradiant{
        background:none;
    }

    .logo {
        text-align: center;
        margin: 0;
    }

    .logo-onboarding {
        margin: 0;
    }

    .header .ant-menu {
        margin: 0 0 15px 0;
        justify-content: center;
    }

    .banner,
    .appstore-section,
    .press-releases,
    .tech-info-wrp,
    .techprofile-wrp {
        padding: 30px 0;
    }

    .tech-guy {
        display: none;
    }

    .tech-info-wrp {
        margin: 0;
    }

    .banner .ant-carousel {
        text-align: center;
    }

    .header .ant-menu-horizontal .ant-menu-item::after {
        bottom: -27px;
    }

    .search-tech.ant-form-inline {
        margin: 0 0 20px 0;
    }

    .mb-5 {
        margin-bottom: 2rem !important;
    }

    .mt-5 {
        margin-top: 2rem !important;
    }

    .hide-on-sm {
        display: none;
    }

    .home-slider {
        text-align: center;
    }

    .search-tech.ant-form-inline .ant-form-item,
    .search-tech.ant-form-inline .ant-form-item.btn-search {
        width: 100%;
    }

    .search-tech.ant-form-inline .ant-form-item.btn-search {
        margin-top: 5px;
    }

    .search-tech.ant-form-inline .ant-input-lg,
    .search-tech.ant-form-inline .ant-form-item.btn-search .ant-btn {
        border-radius: 0.25rem;
    }

    .search-tech.ant-form-inline .ant-form-item.btn-search .ant-btn {
        height: auto;
    }

    .search-tech.ant-form-inline .ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0;
    }

    .tech-profile-box .ant-card-meta-title {
        font-size: 16px;
    }

    .tech-profile-box .ant-card-cover {
        margin: 20px 0 10px 0;
    }

    .service-card .ant-card-body .view-detail {
        padding: 5px 15px;
    }

    .service-card .ant-card-body .ant-btn-lg {
        padding: inherit;
        /* height: 32px; */
        font-size: 14px;
    }

    .ant-layout-header.header .ant-menu-overflow {
        display: inherit;
    }

    .show-large {
        display: none;
    }

    .btn-menu {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        color: #fff !important;
        background-color: #F04A22;
        border-radius: 0.25rem;
        text-align: center;
        line-height: 24px;
        cursor: pointer;
    }

    .side-menu .logo {
        text-align: left;
    }

    .side-menu .ant-drawer-body {
        padding: 0;
    }

    .side-menu .ant-menu-vertical .ant-menu-item {
        margin-top: 0;
    }

    .side-menu .ant-menu-vertical {
        border: none;
    }

    .side-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #F04A22;
    }

    .side-menu .ant-menu-item a {
        text-decoration: none;
    }

    .side-menu .ant-menu-item a:hover {
        color: #F04A22;
    }

    .side-menu .ant-menu-item-selected a,
    .side-menu .ant-menu-item-selected a:hover {
        color: #fff;
        text-decoration: none;
    }
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
    .tech-profile-box.ant-card {
        text-align: center;
    }

    .onboarding-wrp {
        padding: 0px 20px 20px 20px;
    }

    .support-request-button{
        width:100%;
        margin: 10px 10px 0px 10px;
    }
    .support-request-container{
        padding-right: 15px;
    }
}

@media only screen and (max-width: 470px) {
    .margin-top--small .inputStyle {
        width: 2rem !important;
        height: 2rem;
        margin: 0 5px;
        font-size: 1rem;
    }

    .card > form > p {
        font-size: 1.2em !important;
    }
}

@media screen and (max-width: 765px) {
    .onboarding-wrp {
        padding: 0px 20px 20px 20px;
    }
}

@media screen and (max-width: 700px) {
    .review-text {
        max-width: 170px
    }
    .next-action-buttons{
        width: 100%
    }

}

@media screen and (max-width: 450px) {
    .review-text {
        max-width: 130px
    }
}

@media screen and (max-width: 320px) {
    .review-text {
        max-width: 110px
    }
}