.reports-ctn{
    @media screen and (max-width: 991px) {
        padding-bottom:70px   
    }
    .reports-title{
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding-bottom:21px;
        margin-bottom: 21px;
    }
    .reports-action-ctn{
        display: flex;
        justify-content: space-between;
        margin-bottom: 31px;
        position: relative;
        @media screen and (max-width: 991px){
            flex-direction: column;
            align-items: flex-start;  
            width: 100%;          
        }
        .filter-options{
            display: flex;
            gap: 10px;
            width: 100%;
            @media screen and (max-width: 991px){
               flex-direction: column;
            }
        }
        .range-picker-value-card{
            border-radius: 2px;
            border: 1px solid #D9D9D9;
            background: #FFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:4px 12px;
            width: 256px;
            cursor: pointer;
            @media screen and (max-width: 991px){
                width: 432px;
            }
            @media screen and (max-width: 475px){
               width:100% !important;
            }
            .start-date, .end-date{
                p{
                    font-size: 14px;
                    margin-bottom: 0;
                    color:rgba(0, 0, 0, 0.85);
                }
            }
        }
        .go-btn{
            @media screen and (max-width: 991px){
                width: 432px;
            }
            @media screen and (max-width: 475px){
               width:100% !important;
            }
        }
        .download-csv-cta{
            @media screen and (max-width: 991px) {
                border-top: 1px solid #EAECF0;
                padding:24px 16px;
                position: fixed;
                left:0;
                bottom:0;
                width: 100%;
                background-color: #fff;
                z-index: 9;
                .download-csv-btn{
                    width: 100%;
                }
            }
        }
    }

    .date-range-picker-ctn{
        position: absolute;
        z-index: 99;
        left:0px;
        top:40px;
        .date-range-picker{
            border: 1px solid #D1D1D1;
        }
    }

    .total-invoice-amount{
        position: relative;
        top:-50px;
        width: fit-content;
        @media screen and (max-width: 991px){
            top:-30px;
            font-size: 1.25rem;
        }

        @media screen and (max-width: 360px){
            top:10px;
        }
    }
}

.empty-state-ctn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background: #FAFAFA;
    .empty-state-img{
        width: 100%;
        max-width: 132px;
        margin-bottom: 21px;
    }
    .empty-state-content{
        text-align: center;
        .empty-state-title{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 17px;
        }
        .empty-state-subtitle{
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 18px;
        }
    }
}


// List item styles
.report-list-item{
    display: flex;
    column-gap: 16px;
    justify-content: space-between;
    width: 100%;
}