.account-notifications-heading{
    display:flex; 
    justify-content:space-between; 
    align-items:center;
    margin-bottom: 30px;
}

.account-notification-item{
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    cursor: pointer;
    width: 100%;
}

.account-notification-details p:first-of-type{
    margin-bottom:0;
    font-weight: 500;
    font-size: 16px;
    color:#000000
}

.account-notification-details p:last-of-type{
    font-weight: 400;
    font-size: 14px;
    color:#000000;
    margin-bottom:0;
    /* word-break: break-all; */
}

.account-notification-details small{
    color:#686868;
    font-size:14px;
    font-weight: 400;
}

.account-mark-all{
    color: #F04A22;
    cursor: pointer;
    position: relative;
    top:-30px;
    font-weight: 500;
}

.disabled-mark{
    opacity: 0.4;
    cursor: not-allowed;
    width: fit-content;
}

.empty-notification{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 350px;
    align-items: center;
}

.empty-notification p{
    margin-top:10px;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 20px;
}

@media (max-width:767px) {
    .account-notifications-heading{
        display:block; 
    }

    .account-notifications-p{
        display: none;
    }
    .account-mark-all{
        top:0px;
        margin-top: 10px;
    }
}

