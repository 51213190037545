@import './variables.scss';

// Nav Styles
.itc-nav-section{
    background-color: #fff;
    padding:14px 0;
    box-shadow: 0px 4px 6px 4px rgba(0, 0, 0, 0.04);
    position: fixed;
    top:0;
    left:0;
    width:100%;
    z-index: 999;
    // min-height: 70px;
    .itc-nav-ctn{
        @extend .container;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .itc-nav-logo-ctn{
            display: flex;
            // align-items: center;
            gap: 10px;
            flex-wrap: wrap;
            .itc-logo{
                width: auto;
                height: 60px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .itc-menu-list-ctn{
            display: flex;
            align-items: center;
            column-gap: 8vw;
            @media screen and (max-width:991px) {
                display: none;
            }
            ul.menu-list{
                list-style: none;
                display: flex;
                column-gap: 35px;
                margin-bottom: 0;
                li{
                    padding-bottom: 3px;
                    a{
                        text-decoration: none;
                        color: #000000D9;
                        font-size: 14px;
                    }
                }
            }
            .itc-nav-cta-ctn{
                display: flex;
                align-items: center;
                column-gap: 15px;
                .itc-nav-mobile-num{
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    a{
                        text-decoration: none;
                        font-size: 14px;
                    }
                }
            }
        }
        .itc-hamburger-menu{
            display: none;
            height: 22px;
            width: 26px;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            @media screen and (max-width:991px) {
                display: flex;
            }
            span{
                display: block;
                width: 100%;
                height:3px;
                background-color: #000000;
                position: absolute;
                &:nth-child(1){
                    top:2px;
                    transition: all .5s ease-in-out;
                }

                &:nth-child(2){
                    top:10px;
                    transition: all .5s ease-in-out;
                }

                &:nth-child(3){
                    top:18px;
                    transition: all .5s ease-in-out;
                }
            }
            &.itc-hamburger-menu-toggle{
                span{
                    &:nth-child(1){
                        top:10px;
                        transform: rotate(45deg);
                    }
                    &:nth-child(2){
                        opacity: 0;
                    }
                    &:nth-child(3){
                        top:10px;
                        width: 100%;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    .itc-mobile-navigation-menu-closed{
        height:0px;
        overflow: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        .mobile-navigation-menu-ctn{
            display: none;
        }
        .itc-mobile-navigation-menu-ctn{
            display: none;
        }
    }
    .itc-mobile-navigation-menu{
        transition: all 0.3s ease-in-out;
        opacity: 1;
        background: #FFFFFF;
        min-height: 100vh;
       
        .itc-mobile-navigation-menu-ctn{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
            padding-bottom: 40px;
            ul{
                list-style-type: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: 70px;
                li{
                    a{
                        font-size: 25px;
                        font-weight: 500;
                        color: #000;
                        text-decoration: none;
                    }
                }
            }
            .mobile-itc-nav-cta-ctn{
                display: flex;
                column-gap: 20px;
                margin-top:40px;
                flex-wrap: wrap;
            }
        }
    }
}


// Hero Section
.itc-hero-section{
    margin-top: 40px;
    margin-bottom:40px;
    padding-top: 80px;
    .itc-hero-ctn{
        @extend .container;
        display: flex;
        align-items: center;
        column-gap: 8vw;
        @media  screen and (max-width: 991px){
            flex-direction: column;
            row-gap:50px;
            margin-top: 50px;
        }       
        .left-section{
            flex: 50%;
            .text-content{
                .hero-sponsor{
                    font-weight: 500;
                    margin-bottom: 0;
                    a{
                        color: var(--secondary-color);
                    }
                }
                .hero-title{
                    margin:0;
                    font-size: 2.75rem;
                }
                .hero-subtitle{
                    color: #000;
                }
            }
        }
        .right-section{
            flex: 50%;
            position: relative;
            // .hero-gradient{
            //     width: 580px;
            //     height: 580px;
            //     border-radius: 50%;
            //     // margin-right:80px;   
            //     margin-bottom: 20px; 
            //     @media  screen and (max-width: 1200px){
            //         width: 500px;
            //         height: 500px;   
            //     }    
            //     @media  screen and (max-width: 600px){
            //         width: 380px;
            //         height: 380px;   
            //     }      
            //     @media  screen and (max-width: 400px){
            //         width: 350px;
            //         height: 350px;   
            //     }   
            //     // @media  screen and (max-width: 380px){
            //     //     width: 330px;
            //     //     height: 330px;   
            //     // }                                                            
            // }
            // .itc-hero-img{
            //     position: absolute;
            //     top: 70px;
            //     right: 0;
            //     @media  screen and (max-width: 1200px){  
            //         top: 50px;
            //     }  
            //     @media  screen and (max-width: 991px){  
            //         position: absolute;
            //         top: 40px;
            //     }  
            //     img{
            //         @media  screen and (max-width: 1200px){  
            //             width: 500px;    
            //         }   
            //         @media  screen and (max-width: 600px){
            //             width: 380px;
            //         }  
            //         @media  screen and (max-width: 400px){
            //             width: 350px;
            //         }    
            //     }
               
            // }

            .itc-hero-img{
                height: 500px;
                @media  screen and (max-width: 991px){
                    height: auto;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    .hero-points-section{
        @extend .container;
        position: relative;
        z-index: 2;
        .hero-points-ctn{
            display: flex;
            border-radius: 4px;
            background: #FFF !important;
            box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.10);
            padding:17px 35px 17px 55px;
            @media screen and (max-width: 991px){
                flex-direction: column;
                row-gap: 20px;
                padding: 17px 35px 17px 35px;
                
            }
            .hero-point-item{
                flex: 33%;
                display: flex;
                column-gap: 20px;
                align-items: center;
                border-right: 1px solid #D9D9D9;
                margin-right: 80px;
                @media screen and (max-width: 991px){
                    border-right: none;
                    margin-right: 0;
                }
                &:last-child{
                    border-right: none;
                    margin-right: 0;
                }
                .hero-point-icon{
                    display: flex;
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    color: #FFF;
                    text-align: center;
                    img{
                        width:38px;
                    }
                }
                .hero-point-text{
                    margin: 0;
                }
            }
        }
    }
}

// About Section
.itc-about-section{
    background: #F7F7F7;
    padding-top: 180px;
    padding-bottom: 100px;
    margin-top: -50px;
    position: relative;
    z-index: 1;
    .itc-about-ctn{
        @extend .container;
        display: flex;
        column-gap: 3vw;
        @media screen and (max-width: 991px){
            flex-direction: column;
            row-gap:50px;
        }
        .itc-about-media{
            position: relative;
            flex: 45%;
            .itc-about-img{
                width: 100%;
                // height:50vh;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .itc-about-content{
            flex:55%;
            .itc-about-title{
                font-size: 2.75rem;
                font-weight: 500;
                line-height: 59px;
                margin-bottom: 0;
                img{
                    position: relative;
                    top:2px;   
                }
            }
            .company-ratings-ctn{
                display: flex;
                column-gap: 5px;
                .company-ratings{
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 400;
                    color: var(--secondary-color);
                }
                .company-reviews{
                    margin-bottom: 0;
                    color: #757575;
                    font-size: 16px;
                    font-weight: 300;
                }
            }
            .company-details-ctn{
                margin-bottom: 30px;
                margin-top: 8px;
                .company-details-item{
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    margin-bottom: 3px;
                    .company-details{
                        margin-bottom: 0;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.85);
                    }                
                }
            }
            .company-description{
                font-size: 1.125rem;
            }
        }
    }
}

// Services Section
.itc-services-section{
    margin-top:75px;
    .itc-services-ctn{
        @extend .container;
        .services-title{
            margin-bottom: 22px;
            .itc-services-heading{
                font-size: 2.75rem;
                font-weight: 600;
                margin-bottom: 0;
            }
        }
        .services-content{
            .service-tab-ctn{
                border-radius: 3px;
                border: 1px solid #D9D9D9;
                display: flex;
                @media screen and (max-width: 991px) {
                    flex-direction: column;
                    border: none;
                }
                .service-tab-left-pane{
                    border-right: 1px solid #D9D9D9;
                    flex:25%;
                    @media screen and (max-width: 991px) {
                        flex: 100%;
                        border-right: none;
                    }
                    .service-tab-menu{
                        padding:36px 24px;
                        @media screen and (max-width: 991px) {
                            padding:15px 0px;
                            display: flex;
                            column-gap:13px;
                            // justify-content: space-between;
                            border-bottom: 1px solid #D9D9D9;
                            align-items: center;
                            overflow: auto;
                            white-space: nowrap;
                            scroll-behavior: smooth;
                            -ms-overflow-style: none; /* IE and Edge */
                            scrollbar-width: none; /* Firefox */
                            margin-bottom:10px;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                            p{
                                margin-bottom: 0;
                            }
                        }
                        p{
                            font-size: 16px;
                            font-weight: 500;
                            cursor: pointer;
                        }
                    }
                }
                .service-tab-right-pane{
                    flex:75%;
                    .work-category-info{
                       padding: 30px 30px 20px 30px;
                        @media screen and (max-width: 991px) {
                           padding: 30px 0px 20px 0px;
                        }
                    }
                    
                    .work-category-info .specialized, .mobile-introduction .specialized{
                        font-weight: 400;
                        font-size: 20px;
                        color:#393939;
                        margin-bottom: 5px;
                    }
                    
                    .work-category-info .specialized span, .mobile-introduction .specialized span{
                        font-weight: 400;
                        font-size: 20px;
                    }
                    
                    .work-category-info .specialization-work-type{
                        border: 0.883134px solid rgba(0, 0, 0, 0.85);
                        border-radius: 4.41567px;
                        padding: 6px 18px;
                        width: fit-content;
                    }
                    
                    .work-category-info .anticon-info-circle svg, .mobile-introduction .anticon-info-circle svg{
                        fill:rgba(0, 0, 0, 0.85) !important;
                        font-size: 20px;
                        margin-top: -2px;
                    }
                    .work-category-info .introduction-text{
                        font-size: 1.125rem;
                        color: #393939;
                    }
                    
                    .work-category-info .introduction-title{
                        margin-top: 40px !important;
                        margin-bottom: 24px !important;
                    }
                    .category-skills-ctn{
                        border-top:1px solid #D9D9D9;
                    }
                    
                    .category-skills-ctn .category-skills-content{
                        padding: 19px 27px 0 27px;
                        @media screen and (max-width: 991px) {
                            padding: 19px 0px 0px 0px;
                         }
                    }
                    
                    .category-skills-content .skill-tag-list{
                        padding-bottom: 30px;
                    }
                    
                    .category-skills-content .skill-tag-list .ant-tag{
                        padding:7px 21px;
                        background: #F0F0F0;
                        border-radius: 5px;
                        font-size: 16px;
                        color: #3A3A44;
                        margin-right: 24px;
                        margin-bottom: 12px;
                    }
                }
            }
        }
        
        .work-category-info{
            padding-bottom:20px;
            .specialization-work-type{
                border: 0.883134px solid rgba(0, 0, 0, 0.85);
                border-radius: 4.41567px;
                padding: 6px 18px;
                width: fit-content;
            }
            .introduction-text{
                font-size: 1.125rem;
                color: #393939;
            }
            .introduction-title{
                margin-top: 40px !important;
                margin-bottom: 24px !important;
            }
        }
        
        .work-category-info .specialized, .mobile-introduction .specialized{
            font-weight: 400;
            font-size: 20px;
            color:#393939;
            margin-bottom: 5px;
            span{
                font-weight: 400;
                font-size: 20px;
            }
        }
        
        
        .work-category-info .anticon-info-circle svg, .mobile-introduction .anticon-info-circle svg{
            fill:rgba(0, 0, 0, 0.85) !important;
            font-size: 20px;
            margin-top: -2px;
        }
        
        .mobile-introduction .specialized, .mobile-introduction .specialized span{
            font-size: 16px;
        }
        
        .mobile-introduction .specialization-work-type{
            border: 0.883134px solid rgba(0, 0, 0, 0.85);
            border-radius: 4.41567px;
            padding: 4px 18px;
            width: fit-content;
            font-size: 14px;
            margin: 15px 0 20px 0;
        }
        
        
        .category-skills-ctn{
            border-top:1px solid #C2CBDC;
            .category-skills-content{
                padding-left:30px;
                padding-top: 19px;
                padding-right:30px;
                .skill-tag-list{
                    padding-bottom: 30px;
                    .ant-tag{
                        padding:7px 21px;
                        background: #F0F0F0;
                        border-radius: 5px;
                        font-size: 16px;
                        color: #3A3A44;
                        margin-right: 24px;
                        margin-bottom: 12px;
                    }
                }
            }
        }
        
        
        .work-categories-ctn-mobile{
            display: none;
            // @media screen and (max-width: 991px) {
            //     display: block;
            // }
            .mobile-introduction{
                border-bottom: 1px solid #C2CBDC;
                margin-top: 30px;
            }
        }
        
        .skill-tag-list-mobile{
            padding-top: 25px;
            margin-bottom:20px;
            .ant-tag{
                padding:7px 21px;
                background: #F0F0F0;
                border-radius: 5px;
                font-size: 14px;
                color: #3A3A44;
                margin-right: 8px;
                margin-bottom: 8px;
            }
        }
        
    }
}


// Work Process Section
.itc-processes-section{
    margin-top:75px;
    .itc-processes-ctn{
        @extend .container;
        .processes-title{
            margin-bottom: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 60px;
            .itc-processes-heading{
                font-size: 2.75rem;
                font-weight: 600;
                margin-bottom: 15px;
            }
            .itc-processes-subtitle{
                color: #686868;
                text-align: center;
                font-size: 1.125rem;
                width: 480px;
                @media  screen and (max-width: 991px){
                    width: 100%;
                }
            }
        }
        .processes-content{
            .process-list{
                display: flex;
                column-gap: 60px;
                row-gap: 40px;
                flex-wrap: wrap;
                justify-content: center;
                @media screen and (max-width: 991px) {
                    column-gap:30px;
                }
                .process-item-card-ctn{
                    position:relative;
                    height: 276px;
                    flex:20%;
                    flex-grow: 0;
                    @media screen and (max-width: 991px) {
                        flex: 30%;
                    }
                    @media screen and (max-width: 768px) {
                        flex: 40%;
                    }
                    @media screen and (max-width: 480px) {
                        flex: 100%;
                    }
                    &:nth-child(even){
                        margin-top: 60px;
                        @media screen and (max-width: 480px) {
                            margin-top: 0px;
                        }
                    }
                    &:nth-child(odd){
                        margin-bottom: 60px;
                        @media screen and (max-width: 480px) {
                            margin-bottom: 0px;
                        }
                    }
                    .process-item-card{
                        background: #FAFAFA;
                        box-shadow: -3px 0px 4px 0px rgba(0, 0, 0, 0.03);
                        padding: 50px 15px;
                        text-align: center;
                        height: 276px;
                      
                        // &::before {
                        //     content: '';
                        //     position: absolute;
                        //     top: 5px;
                        //     left: 5px;
                        //     width: 100%;
                        //     height: 100%;
                        //     background-color: #2771A9;
                        //     transform: rotate(1deg); 
                        //     z-index: -1; 
                        //   }
                        .process-item-heading{
                            font-size: 1.375rem;
                            margin-bottom: 35px;
                        }
                        .process-item-description{
                            font-size: 1rem;
                            color: #686868;
                        }
                    }
                }
            }
        }
    }
}

// Professionals Section
.itc-professionals-section{
    margin-top:100px;
    padding-bottom: 84px;
    .itc-professionals-ctn{
        @extend .container;
        .professionals-title{
            margin-bottom: 22px;
            .itc-professionals-heading{
                font-size: 2.75rem;
                font-weight: 600;
                margin-bottom: 0;
            }
        }
        .professionals-content{
            .professionals-categories{
                display: flex;
                align-items: center;
                column-gap: 7px;
                overflow: hidden;
                white-space: nowrap;
                scroll-behavior: smooth;
                margin-bottom: 23px;
                .professionals-category-item{
                    cursor: pointer;
                    margin-bottom: 0;
                    border-radius: 3px;
                    border: 2px solid #D7D7D7;
                    background: #FFF;
                    padding:8px 12px;
                }
            }
            .professionals-card-ctn{
                display: flex;
                gap:15px;
                flex-wrap: wrap;
                @media screen and (max-width: 768px) {
                    gap:10px;
                }
                .professional-card{
                    flex: 24%;
                    flex-grow: 0;
                    border-radius: 4px;
                    background: #FFF;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
                    padding: 10px 10px 14px 10px;
                    @media screen and (max-width: 991px) {
                        flex: 40%;
                        flex-grow: 0;
                    }
                    @media screen and (max-width: 768px) {
                        flex: 50%;
                        flex-grow: 0;
                    }
                    @media screen and (max-width: 530px) {
                        flex: 100%;
                    }
                    .inner-card{
                        border-radius: 5px;
                        background: #F7F7F7;
                        padding: 15px 10px 10px 10px;
                        .top-info{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 11px;
                            .verified-label{
                                display: flex;
                                align-items: center;
                                column-gap: 2px;
                                border-radius: 25px 0 35px 25px;
                                padding: 5px 10px;
                                width: 90px;
                                .verified-text{
                                    margin-bottom: 0;
                                    font-size: 14px;
                                    color: #fff;
                                    font-style: italic;
                                    font-weight: 500;
                                }
                            }
                            .rating-label{
                                display: flex;
                                align-items: center;
                                border-radius: 100px;
                                background: #FFF;
                                column-gap: 5px;
                                padding:4px 7px;
                                .rating-text{
                                    margin-bottom: 0;
                                    color: #555;
                                    font-size: 14px;
                                }
                                .reviews-text{
                                    color:#A8A8A8;
                                    font-size: 14px;
                                    font-weight: 300;
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .profile-img-ctn{
                            width: 100%;
                            height: 269px;
                            border-radius: 10px;
                            border: 7px solid #FFF;
                            img{
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }   
                    }
                    .bottom-info{
                        margin-top:15px;
                        padding:0 10px;
                        .name-rate{
                            display: flex;
                            flex-direction: column;
                            row-gap: 5px;
                            margin-bottom:15px;
                            .professional-name{
                                margin-bottom: 0;
                            }
                            .professional-rate{
                                margin-bottom: 0;
                                font-size: 16px;
                                color: #000;
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Testimonials Section
.itc-testimonial-section{
    margin-top:75px;
    background-color: #f7f7f7;
    padding: 77px 0;
    .itc-testimonial-ctn{
        @extend .container;
        background-image:
        url('../images/t-tl.png'),
        url('../images/t-ml.png'),
        url('../images/t-bl.png'),
        url('../images/t-tr.png'),
        url('../images/t-mr.png'),
        url('../images/t-br.png');

        background-position: 
        top 2% left 10%,
        top 60% left 5%,
        bottom 10% left 10%,
        top 8% right 10%,
        top 50% right 5%,
        bottom 10% right 20%; 
    
        background-repeat: no-repeat;

        @media screen and (max-width: 991px) {
            background: none;
        }
        .testimonial-title{
            margin-bottom: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 60px;
            .itc-testimonial-heading{
                font-size: 2.75rem;
                font-weight: 600;
                margin-bottom: 15px;
            }
        }
        .testimonial-content-ctn{
            position: relative;
            .circle-bubble{
                width:31px;
                height:31px;
                border-radius: 50%;
                position: absolute;
                top: 10%;
                left: 10%;
                &:nth-of-type(2){
                    top: 50%;
                    left: 80%;
                    width:19px;
                    height:19px;
                    @media screen and (max-width: 991px) {
                        top:100%;
                    }
                }
            }
            .testimonial-content-inner{
                display: flex !important;
                flex-direction: column !important;
                align-items: center !important;
                .testimonial-content{
                    width:649px !important;
                    @media screen and (max-width: 991px){
                        width: 100% !important;
                    }
                    .testifier-img-ctn{
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        .testifier-img-inner{
                            display: flex;
                            justify-content: center;
                            margin-bottom: 43px;
                            position: relative;
                            width: 150px;
                            .testifier-img{
                                width: 130px;
                                height: 130px;
                                img{
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    object-fit: cover;
                                }
                            }
                            .quote-ctn{
                                display: flex;
                                width: 38px;
                                height: 38px;
                                border-radius: 50%;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                flex-shrink: 0;
                                text-align: center;
                                position: absolute;
                                bottom:10px;
                                right: 0;
                                border: 2px solid #FFF;
                            }
                        }
                    }
                    .testifier-text-ctn{
                        text-align: center;
                        .testifier-description{
                            color: #393939;
                            font-size: 1.125rem;
                            margin-bottom: 59px;
                        }
                        .testifier-name{
                            margin-bottom:0;
                        }
                        .testifier-position{
                            color: #393939;
                            font-size: 1.125rem;
                        }
                    }
                }
            }
        }
        .testimonial-btns{
            display: flex;
            justify-content: center;
            column-gap: 15px;
        }
    }
}

// Faq Section
.itc-faq-section{
    margin-top:100px;
    padding-bottom: 14px;
    .itc-faq-ctn{
        @extend .container;
        .faq-title{
            margin-bottom: 44px;
            .itc-faq-heading{
                font-size: 2.75rem;
                font-weight: 600;
                margin-bottom: 0;
            }
            .itc-faq-subtitle{
                color: #686868;
                font-size: 1.125rem;
                width: 100%;
                @media  screen and (max-width: 991px){
                    width: 100%;
                }
            }
        }
        .faq-content{
            .itc-accordion{
                border-top:1px solid #DDD;
                padding:35px 0;
                .itc-accordion-header{
                    display: flex;
                    align-items: center;
                    column-gap: 23px;
                    cursor: pointer;
                    .itc-accordion-num{
                        display: flex;
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        flex-direction: column;
                        justify-content: center;
                        flex-shrink: 0;
                        color: #FFF;
                        text-align: center;
                        font-size:  1.125rem;
                    }
                    p{
                        font-size:  1.125rem;
                        font-style: normal;
                        font-weight: 500;
                        margin-bottom: 0;
                    }
                }
                .itc-accordion-body{
                    p{
                        color: #000;
                        font-size: 1rem;
                        margin-bottom: 0;
                        margin-top:24px;
                        a{
                            color: var(--secondary-color)
                        }
                    }
                }
            }
        }
    }
}   

// Free trial Section
.free-trial-section{
    @extend .container;
    // margin-top: 40px;
    .free-trial-ctn{
        padding:20px 51px 0 71px;
        background-color: #0057B8;
        border-radius: 20px;
        background-image: url('../images/free-trial-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        gap:60px;
        @media screen and (max-width: 991px) {
           padding: 0;
        }
        .free-trial-img{
            flex:30%;
            @media screen and (max-width: 991px) {
                display: none;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .free-trial-content{
            flex: 70%;
            padding:30px 0;
            @media screen and (max-width: 991px) {
                padding:30px;
            }
            .free-trial-title{
                color:#fff;
                margin:0;
            }
            .free-trial-description{
                color:#fff;
            }
        }
    }
}

.itc-footer-section{
    border-top: 1px solid  #DDD;
    padding-top: 38px;
    margin-top: 59px;
    padding-bottom: 61px;
    .itc-footer-ctn{
        @extend .container;
        .itc-footer-logo{
            width: 130px;
            height: 70px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .itc-footer-menu-list-ctn{
            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width:991px) {
               align-items: start;
            }
            
            ul.itc-footer-menu-list{
                list-style: none;
                display: flex;
                column-gap: 35px;
                margin-bottom: 0;
                padding-left:0;
                @media screen and (max-width:991px) {
                    flex-direction: column;
                    row-gap: 20px;
                }
                li{
                    a{
                        text-decoration: none;
                        color: #000000D9;
                        font-size: 14px;
                    }
                }
            }  
            .itc-footer-cta-ctn{
                display: flex;
                column-gap: 12px;
                @media screen and (max-width: 991px) {
                    flex-direction: column;
                    row-gap: 20px;
                }
                .with-icon{
                    display: flex;
                    column-gap: 8px;
                    align-items: center;
                    justify-content: center;
                    p{
                        margin-bottom: 0;
                        font-size: 14px;
                    }
                }
            } 
        }
    }
}

.itc-copyright-section{
    background: #000;
    .itc-copyright-ctn{
        @extend .container;
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;    
        @media screen and (max-width:991px) {
            flex-direction: column-reverse;
            row-gap: 20px;
        }
        p{
            color:#fff;
            font-size: 14px;
            margin:0;
            &:nth-of-type(1){
                opacity:0.5
            }
            &:nth-of-type(2){
                font-weight: 500;
                letter-spacing: 1.96px;
            }
        }
        .copyright-links{
            display:flex;
            column-gap:38px;
            a{
                text-decoration: none;
                color: #fff;
                font-size: 14px;
            }
        }
    }
}

//general
.itc-top-border{
    width: 60px;
    height: 6px;
}

.page-not-found-img{
    width: 100%;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.error-section{
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .error-row{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        column-gap: 60px;
        row-gap: 30px;
        width: 90%;
        @media screen and (max-width: 991px){
            flex-direction: column-reverse;
            width: 100%;
        }
        .error-col{
            flex:1
        }
    }
}

.landing-edit-icon{
    position: absolute;
    top: 0px;
    right: 0;
    background: #FFF;
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 4px;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.project-tag{
    font-size: 10px;
    color: #fff;
    background-color: #000;
    height:fit-content;
    width:fit-content;
    font-weight: 700;
    padding: 2px 10px;
    border-radius: 85px;
}

.landing-page-iframe{
    margin-top:75px;
}