.image, .participant-vid-container{
    height: 100vh;
    width: 100vw;
    position:fixed;
}

.image-card, .agora-vid-container{
    height:60vh;
    width: 130px;
    /* position: absolute; */
    border-radius: 0.5rem;
    top:10%;
    right: 20px;
}
.image-card{
    z-index: 102;
}
.participant-vid-container{
    z-index: 100;
}
.agora-vid-container{
    z-index: 105;
}
.show{
    visibility: visible;
}
.hidden{
    visibility: hidden;
}


.profile-pic{
    height: 250px;
    width: 250px;
    border-radius: 50%;
    border: 7px solid white;
    /*margin-bottom: 1rem;*/
    color: white;
    z-index:99;
    object-fit: cover;
}
.profile-pic-card{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 2px solid white;
    margin-bottom: 0.5rem;
    object-fit: cover;
}
.header{
    position: absolute;
    z-index: 1000;
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}
.header-icon{
    color: white;
    cursor: pointer;
    margin: 0; 
}
.no-video{
    background-color: black;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}
.call-option{
    /*top: 85vh;*/
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position:fixed;
    bottom: 20px;
}
.icon{
    cursor: pointer;
    background-color: white;
    padding: 0.8rem;
    margin-bottom: 0.5rem;
    border-radius: 5rem;
    height: 50px !important; 
    width: 50px !important 
}
.option-align{
    width: 300px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    margin-top: 10vh;
}
.own-video{
    background-color: #2C2C2C;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 180px !important;
    position: relative;
    top:-30vh;
    right: 0;

}
.calling-image{
    width:150px;
    height:150px;
    border-radius: 50%;
    margin-bottom: 1rem;
    object-fit: cover;
}
.ant-btn.btn-red {
  
	color: #fff;
	background-color: red;
	border-color: red;
    width: 100%;
    margin-right: 0.5rem !important;
    height: 40px;
}
.ant-btn.btn-green {
  
	color: #fff;
	background-color: green;
	border-color: green;
    width: 100%;
    height: 40px;
}
@media (max-width: 1000px) { 
    .image-card, .agora-vid-container{
        height: 150px;
        width: 200px;
        position: absolute;
        top:70%;
        right: 40px;
        border-radius: 0.5rem;

    }
 }
@media (max-width: 991px) { 
    .image-card, .agora-vid-container{
        height: 150px;
        width: 100px;
        position: absolute;
        /*top:50vh;*/
        right: 20px;
        border-radius: 0.5rem;

    }
    .own-video{
        top: 40vh;
        left: 20px;
    
    }
 }