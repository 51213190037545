/* Header Section */
.header-section-ctn{
    z-index:999
}

.header-section-ctn .transparent-nav{
    box-shadow: none;
    background-image: url('../images/hero-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #ffffff;
}

.header-section-ctn .remove-nav-bg{
    background-image: none;
    background-color: #ffffff;
}

.header-section-ctn .mobile-transparent-nav{
    box-shadow: none;
    padding-left:0px !important;
    padding-right: 0px !important;
}

.header-section-ctn .white-nav{
    padding-left:0px !important;
    padding-right: 0px !important;
}

.header-section-ctn .button-background.sign-up-button{
    background-color: #F04A22 !important;
    background-image: none;
}

.mobile-right-nav{
    display:flex; 
    align-items:center; 
    column-gap:20px;
}

.hero-section{
    background-image: url('../images/hero-bg.png'), 
    url('../images/line-support.png');
    background-repeat: no-repeat, no-repeat;
    background-position: center center, 
    bottom 70px right 45%;
    height: 100%;
    overflow-x: hidden;
}

.hero-section .owl-nav.disabled{
    display: none !important;
}


.hero-section-ctn{
    background-image:
    url('../images/hero-black-line.svg'),
    url('../images/hero-arrow.svg');
    background-repeat: no-repeat, no-repeat;
    background-position:
    top 8% left 38%,
    top 25% right 53%;
    padding-top: 40px;
    padding-bottom: 90px;
}

.hero-col h1.ant-typography{
    color:#000000;
    font-weight:700; 
    font-size:50px;
}

.hero-tech-display{
    background-image: url('../images/orange-bg.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom right;
    position: relative;
}


.hero-tech-guy{
    /* width: 500px; */
    height: 500px;
    text-align: right;
}

.hero-tech-guy img{
    width: inherit;
    height: inherit;
    object-fit: contain;
}
.hero-group-img{
    width: auto;
    max-height: 600px;
}
.hero-group-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-tech-display .tiny-card{
    background-color: #ffffff;
    width: fit-content;
    padding:20px;
    border-radius: 20px 0px 20px 20px;
    position: absolute;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
}

.hero-tech-display .tiny-card:nth-of-type(2){
    top:20%;
    right: -70px;
}

.hero-tech-display .tiny-card:nth-of-type(3){
    bottom:20%;
    left: 0%;
}

.hero-tech-display .tiny-card:nth-of-type(4){
    bottom:-10px;
    right: -8%;
}

.location img{
    margin-right: 15px;
}

.hero-tech-display .tiny-card.info{
    width: 210px;
}

.tech-icons img{
    border-right: 1px solid #F6EDE9;
    padding:0px 15px;
}

.tech-icons img:first-of-type{
    padding-left: 0;
}

.tech-icons img:last-of-type{
    border-right: none;
    padding-right: 0;
}

.support-row{
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    padding: 25px 10px;
    border-radius: 20px 0px 20px 20px;
    margin-top:50px;
    background-color: #ffffff;
}

.support-col{
    border-right:1px solid #D9D9D9;
    padding-left:40px;
}

.support-col:nth-of-type(3){
    border-right: none;
}

.support-col p{
    margin-bottom: 0;
    width: 70%;
    color:#393939;
}

.col-item{
    background-color:#F04A22;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-item img{
    width: 60%;
    object-fit: contain;
}

.rounded-primary-btn{
    height:52px;
    width:100%;
    border-radius: 5px;
    font-size: 17px;
    padding:0 30px;
    background-color:#F04A22;
    cursor: pointer;
}

.main-landing-page-learn-more:hover > a {
    color: var(--secondary-color) !important;
}

.outline-orange-btn{
    height:52px; 
    width:100%; 
    border-radius: 5px;
    font-size: 17px;
    border: 2px solid #F04A22;
    color:#F04A22;
    padding:0 30px;
    cursor: pointer;
}


/* Tech Showcase Section */

.tech-showcase{
    height: 100%;
    padding:0px 0 70px 0;
    overflow-x: hidden;
    background: linear-gradient(106.94deg, #F0FAFF 6.97%, #F7FCFF 89.8%);
}

.tech-showcase-ctn{
    padding-top: 100px;
    background:
    url('../images/support-semi.svg'),
    url('../images/doughnut.svg'),
    url('../images/qtr-circle.svg');
    background-position: 
    left 0% top 0,
    left 0% top 40%,
    right 0% bottom -10%;
    background-repeat: no-repeat;
}

.tech-showcase .ant-input-prefix{
    padding-right: 10px;
}

.search-btn, .search-btn:hover{
    height:54px;
    width: fit-content;
    border-radius: 0px 5px 5px 0px;
    /* padding:0 30px; */
    background-color:#F04A22;
}

.tech-showcase .ant-form{
    width: 50%;
    margin:0 auto;
}

.tech-showcase .ant-input-affix-wrapper{
    height:55px; 
    border:none;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
}

.tech-showcase .location-btn, .tech-showcase .location-btn:hover{
    padding:0;
    font-size:16.5px;
    cursor:pointer;
    background: none;
    border: none;
    color: #000000;
}

.tech-carousel{
    margin-top: 15px;
    padding-bottom: 20px;
}

.tech-carousel .ant-card-body{
    padding: 15px;
}

.tech-carousel .ant-card-bordered{
    border:1px solid #EBEBEB;
    border-radius: 10px;
}

.tech-carousel .tech-headshot{
    width:100%;
    height: 100%;
    margin-bottom: 20px;
    text-align: center !important;
}

.tech-carousel .tech-headshot img{
    width: 100px;
    height: 100px;
    border-radius:50%;
    object-fit: cover;
    display: inline-block !important;
}

.landing-page-tech-card {
    height: 446px;
}

.card-tech-top{
    position: relative;
}

.card-tech-top img.hipaa-badge{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 1rem;
    scale: 3;
}

.tech-details p{
    text-align: center;
    margin-bottom:5px;
    color:#393939
}

.tech-details p span{
    font-weight: 500;
}

.tech-details p:last-of-type{
    text-align: left !important;
}

.tech-tags{
    height: 100px;
}

.tech-tags .ant-tag{
    padding: 5px 11px;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    margin-bottom:10px;
    color:#393939;
    font-weight: 400;
}

.tech-carousel .slick-slide {
	padding: 0 10px;
	box-sizing: border-box;
 }

 .ant-modal-confirm-error .ant-modal-confirm-body > .anticon{
    display: none;
 }

 .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
     margin-left: 0;
 }


/*News Room Section*/
.newsroom-section{
    padding:60px 0;
    overflow-x: hidden;
}

.news-title{
    flex: 0 0 auto;
    position: relative;
    top:9px;
    margin-right:18px;
}

.view-news{
    text-align: right;
    border-bottom: 1px solid #E8D8C8;
    font-weight: 500;
    flex: 1 1 auto;
 }

 .view-news a{
    color: #F04A22;
    position: relative;
    top:-7px;
 }

.newsroom-section .news-heading-ctn{
    margin-bottom:30px;
}

.news-heading{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}


.news-slider .ant-card-body{
   height: 110px !important;
}

.news-slider .ant-card-body img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.news-slider .ant-card-bordered{
    border:1px solid #EBEBEB;
    border-radius: 10px;
    padding:0 20px;
}

.news-slider .slick-slide {
	padding: 0 10px;
	box-sizing: border-box;
 }

/* Team Section */
.team-section{
    background: linear-gradient(103.48deg, #F0FAFF 2.11%, #F7FCFF 89.27%);
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
   overflow-x: hidden;
}

.team-section .team-ctn{
    background-image:
    url('../images/team-blue.png'),
    url('../images/talent-green.png'),
    url('../images/team-yellow.png');
    background-position: 
    left 55% top 15%,
    left 55% top 50%,
    right 20% bottom 20%;
    background-repeat: no-repeat;
    padding-top:100px;
    padding-bottom:200px;
}

.teamlist-container-mobile{
    width: 100%;
    height: auto;
}

.teamlist-container-mobile img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.teamlist-container{
    position: relative;
}


.teamlist-container .team-card{
    border: 1px solid #EBEBEB;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 35px;
    padding-bottom: 40px;
    background-color: #ffffff;
    width: 380px;
    float: right;
    clear: both;
    margin-right: 50px;
}

.teamlist-container .team-item{
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 30px;
}

.teamlist-container .team-item:last-of-type{
    margin-bottom: 0;
}

.teamlist-container img.verify{
    position: absolute;
    right: 0;
    top: 80px;
}

.teamlist-container .tech-img{
    width: 65px;
    height: 65px;
}

.teamlist-container .tech-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}


.team-small-card{
    border: 1px solid #EBEBEB;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    background-color: #ffffff;
    width: 175px;
    height: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.team-small-card .tech-img{
    margin: 0 auto;
}

.teamlist-container .team-small-card{
    text-align: center;
    position: absolute;
    top:0;
    right: 0;
}

.teamlist-container .team-small-card:nth-of-type(2){
    right:400px;
    top: 70px;
}

.teamlist-container .team-small-card:nth-of-type(3){
    right:360px;
    top: 320px;
}

.teamlist-container .team-small-card:nth-of-type(4){
    right:0px;
    top: 240px;
}


/*Tech Service*/
.tech-services{
    padding: 70px 0;
    background-image:
    url('../images/service-rope.svg');
    background-position: 
    left 0% top 15%;
    background-repeat: no-repeat;
    background-size: 80px;
}

.services-ctn{
    background-image: url('../images/service-triangle.svg');
    background-position: right 0% top 0%;
    background-repeat: no-repeat;
    background-size: 50px;
}

.tech-services .ant-tabs-nav-wrap{
    border-bottom: 1px solid #E8D8C8;
}

.tech-services .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    border: 1.5px solid #F04A22 !important;
}

.tech-services .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #000000 !important;
    font-size: 17px !important;
}

.tech-services .ant-tabs-tab{
    color: #7D7D7D;
    font-size: 17px !important;
    padding-left: 12px;
    padding-right: 12px;
}

.tech-services .panel-item{
    display: flex;
    align-items: center;
    column-gap: 15px;
    border-bottom: 2px solid #393939;
    padding-bottom: 10px;
}

.panel-item .panel-img{
    width:60px;
    height: 60px;
}

.panel-item .panel-img img{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    object-fit: cover;
}

.tech-services .panel-item img{
    transition: all 0.2s ease-out;
}
.tech-services .panel-item img.open-rotate-icon{
    transform: rotate(90deg);
}

.panel-desc-close{
    max-height: 0;
    transition: max-height 1s linear;
    overflow: hidden;
}

.panel-desc-open{
    max-height: auto;
    transition: max-height 1s linear;
}


/* Tech Talents Stats Section */
.tech-talents{
    background: url('../images/talent-rope.svg'), linear-gradient(98.4deg, #FFFDFA 0.98%, #FFF6E5 92.38%);
    background-repeat: no-repeat;
    background-position: top 50% right 0;
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow-x: hidden;
}

.tech-talents-ctn{
    background-image:
    url('../images/talent-qtr.svg'),
    url('../images/talent-green.png');
    background-position: 
    left 55% top 40%,
    right 60% top 80%;
    background-repeat: no-repeat;
}

.stats-row .ant-card-body{
    padding:20px 35px;
}

.stats-row .ant-card-bordered{
    border: none;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 20px 0px 20px 20px;
}

/* Support Section */
.support-section{
    background-image:url('../images/support-rope.svg');
    background-position: left 0% top 30%;
    background-repeat: no-repeat;
    overflow-x: hidden;
    /* background-size: 80px, 50px; */
}

.support-ctn{
    background-image:url('../images/support-semi.svg');
    background-position: right 8% top 0%;
    background-repeat: no-repeat;
    padding-top:60px;
    padding-bottom:60px;
}
.support-steps{
    margin-top: 40px;
}

.step-item {
    text-align:center;
}

.step-item .step-text{
    width:90%;
    margin: 0 auto;
    padding-left: 5px;
}

img.step-mobile{
    display: none;
}

/* Testimony Section */

.testimony-carousel{
    background-image: url('../images/testimony-bg.png'),  linear-gradient(106.94deg, #F0FAFF 6.97%, #F7FCFF 89.8%);
    background-repeat: no-repeat;
    background-position: center center;
    height: auto;
    width: 100%;
    overflow-x: hidden;
}

.testimony-ctn{
    padding-top: 100px;
    padding-bottom: 100px;
    background-image:
        url('../images/test-tl.png'),
        url('../images/test-ml.png'),
        url('../images/test-bl.png'),
        url('../images/test-tr.png'),
        url('../images/test-mr.png'),
        url('../images/test-br.png');

        background-position: 
        top 10% left 10%,
        top 60% left 5%,
        bottom 10% left 10%,
        top 15% right 10%,
        top 50% right 5%,
        bottom 10% right 20%; 
    
        background-repeat: no-repeat;
}

.testimony-ctn h3.ant-typograophy{
    font-size: 32px;
}

.testimony-item{
    width: 70% !important;
    text-align: center;
    margin: 0 auto;
}

.testimony-item .headshot-ctn{
    position: relative;
}

.headshot-ctn .quote{
   position: absolute;
   bottom:-5%;
   left:52%
}

.testimony-item .headshot{
    width: 130px;
    height: 130px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.testimony-item .headshot img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

/* Footer Section */
footer{
    overflow-x: hidden;
}
.footer-section{
    padding-top:80px;
    background-image:
    url('../images/left-rope.svg'),
    url('../images/right-rope.svg');
    background-position: 
    left 0% top 10%,
    right 0% top 10%;
    background-repeat: no-repeat;
}

.newsletter-form{
    display: flex;
    justify-content: center;
    align-items: top;
    column-gap: 20px;
}

.newsletter-input{
    height: 54px;
    border: 1px solid #BCBCBC;
    border-radius: 5px;
    width: 465px;
}

.newsletter-btn{
    width: 150px;
}


.footer-details{
    margin-top: 80px;
    /* padding: 0 30px; */
}

.footer-support-items{
    display: flex;
    flex-direction: column;
}

.footer-details .ant-col{
    border-left:1px solid #F3F6FB;
    padding-bottom: 40px;
}

.footer-details .ant-col:first-of-type{
    border-left: none;
}

.footer-subsection h5.ant-typography{
    position: relative;
    padding-top: 5px;
}

.footer-subsection a{
    color: #393939;
}

.footer-subsection a:hover{
    color: #F07C22;
}

.footer-subsection h5.ant-typography::before{
    content: "";
    position: absolute;
    left: -26px;
    top: 0;
    height: 30px;
    width: 2px;
    background-color: #1BA8F0;
}

.footer-copyright{
    border-top: 1px solid #F3F6FB;
    padding: 20px 0;
    color: #393939;
}

/* Find tech by states */
.landing-page-find-tech-by-state-wrapper {
    padding: 4rem 0;
}

.landing-page-find-tech-by-state-wrapper .view-all-states {
    width: 200px;
    height: 50px;
    border-radius: 5px;
}

.landing-page-find-tech-by-state-wrapper .state-tag {
    color: var(--secondary-color);
    background-color: rgba(240, 74, 34, 0.1);
    border: none;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    border-radius: 5px;
}

@media screen and (max-width: 1250px) {
    .hero-tech-display .tiny-card:nth-of-type(2){
        top:25%;
        right: -30px;
    }
}

@media screen and (max-width: 1200px) {
    .hero-group-img{
        width:100%;
        height: auto;
    }
    .hero-group-img img{
        width:100%;
        height: 100%;
        object-fit: cover;
    }
    .hero-tech-display{
        background-size: 90%;
    }
    .hero-tech-display .tiny-card:nth-of-type(2){
        top:25%;
        right: -50px;
    }
    .tech-showcase-ctn .slick-prev {
        left: -1%;
        top:50%;
        z-index: 99;
    }

    .tech-showcase-ctn .slick-next {
        right: -1%;
        top:50%
    }
}


@media screen and (max-width: 991px) {
    .landing-page-find-tech-by-state-wrapper .find-tech-by-state-right-section {
        display: none !important;
    }

    .hero-section{
        background-image: url('../images/hero-mobile-bg.png');
    }

    .support-row{
        padding: 20px 20px;
    }
    .support-col{
        border-right:none;
        padding-left:0;
        margin-bottom:20px
    }
    .support-col:last-of-type{
        margin-bottom: 0;
    }
   
    .step-item{
        display: flex;
        column-gap: 0px;
        align-items: center;
    }
    img.step-mobile{
        display: block;
        height: 120px;
    }
    img.step-desktop{
        display: none;
    }

    .step-item .step-text{
        width:75%;
        margin: auto auto;
        padding-top: 20px;
        padding-left: 0;
    }

    .hero-section-ctn{
        background-image: none
    }


    .hero-tech-display{
       margin-right: 60px;
       margin-right: 20%;
       margin-left:10%;
       background-size: 550px;
    }

    .hero-tech-guy{
        height: 600px;
        text-align: right;
    }

    .hero-tech-display .tiny-card:nth-of-type(2){
        top:20%;
        right: -60px;
    }
    
    
    .hero-tech-display .tiny-card:nth-of-type(4){
        bottom:-10px;
        right: -30px;
    }
    

    .tech-talents{
        background-image:
        url('../images/talent-rope.svg'),
        url('../images/talent-qtr.svg'),
        url('../images/talent-green.png'),
        linear-gradient(98.4deg, #FFFDFA 0.98%, #FFF6E5 92.38%);
        background-position: 
        top 30% right 0,
        left 0% top 70%,
        right 40% top 50%;
        background-repeat: no-repeat;
        background-size: auto, auto, 20px;
    }

    .tech-talents-ctn{
        background-image: none;
    }

    .team-section .team-ctn{
        background-image:none;
        padding-top: 50px;
        padding-bottom: 40px;
    }

   

    .footer-section{
        background-position: 
        left -10% top 0%,
        right -10% top 0%;
    }

    .tech-showcase-ctn{
        width: 100%;
    }

    .tech-showcase-ctn .slick-prev {
        left: -1%;
        top:50%;
        z-index: 99;
    }

    .tech-showcase-ctn .slick-next {
        right: -1%;
        top:50%
    }


    
     .testimony-slider .slick-prev, .testimony-slider .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 100%;
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        /* transform: translate(0, -50%); */
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
    }

    .news-slider .slick-prev {
        left: 0%;
        top:50%;
        z-index: 99;
    }

    .news-slider .slick-next {
        right: 0%;
        top:50%
    }

    .testimony-item{
        padding-bottom: 40px;
        width: 90% !important;
    }

    .testimony-slider .slick-prev {
        left: 40%;
    }

    .support-section{
        padding:60px 0;
        background-image:
        url('../images/support-rope-mobile.svg'),
        url('../images/support-semi.svg');
        background-position: 
        right 0% top 70%,
        right 8% top 0%;
        background-repeat: no-repeat;
        background-size: 80px, 80px;
    }

    .support-ctn{
        background-image: none;
    }

    .testimony-slider .slick-next {
        right: 40%;
    }

    .footer-details .ant-col:nth-of-type(2),  .footer-details .ant-col:nth-of-type(4){
       margin-left: 30px;
       width: 200px !important;
    }
    .footer-section{
        padding-top:80px;
        background-image:
        url('../images/left-rope.svg'),
        url('../images/right-rope.svg');
        background-position: 
        left 0% top 0%,
        right 0% top 0%;
    }
}

@media screen and (max-width: 880px) {
    .hero-tech-display{
        margin-right: 60px;
        margin-left: 0;
     }

}



@media screen and (max-width: 780px) {

    .hero-tech-display{
        margin-right: 60px;
        background-size: 500px;
    } 
    
     .hero-tech-display .tiny-card:nth-of-type(2){
        top:25%;
    }

 
     .hero-tech-guy{
         height:550px;
         text-align: right;
     }


    .testimony-ctn{
        padding-top: 100px;
        padding-bottom: 100px;
        background-image:
            url('../images/test-tl.png'),
            url('../images/test-ml.png'),
            url('../images/test-tr.png'),
            url('../images/test-mr.png');
    
            background-position: 
            top 30% left 10%,
            top 50% left 5%,
            top 25% right 10%,
            top 50% right 5%;
        
            background-size: 45px, 35px, 35px, 45px;

            background-repeat: no-repeat;
    }

    .testimony-ctn h3.ant-typograophy{
        font-size: 20px;
    }

    
    .teamlist-container{
        width: 100%;
    }
    .teamlist-container .team-card{
        width: calc(380px - 10vw);
    }
    .teamlist-container .team-small-card:nth-of-type(2){
        right:calc(400px - 10vw);
    }
    
    .teamlist-container .team-small-card:nth-of-type(3){
        right:calc(360px - 10vw);
    }

    .team-small-card{
        max-width: 140px;
        max-height: 140px;
    }

    .tech-showcase .ant-form{
        width: 70%;
    }

    .footer-section{
        padding-top:80px;
        background-image:
        url('../images/left-rope.svg'),
        url('../images/right-rope.svg');
        background-position: 
        left -10% top 3%,
        right -10% top 3%;
        background-size: 120px 120px;
    }

}

@media screen and (max-width: 620px){

    .hero-tech-display{
        margin-right: 60px;
        background-size: 450px;
    } 

    .hero-tech-guy{
        height:500px;
        text-align: right;
    }
    
     .hero-tech-display .tiny-card:nth-of-type(2){
        top:25%;
    }


    .location img{
        margin-right:10px;
        width:15px;
    }
    .view-news a{
        top:0;
     }

    .teamlist-container img.verify{
      width:60px;
      right: 30px;
    }

    .teamlist-container .tech-img{
        width: 50px;
        height: 50px;
    }

    .teamlist-container .team-card{
        width: calc(360px - 10vw);
    }
    .teamlist-container .team-small-card:nth-of-type(2){
        right:calc(380px - 10vw);
    }
    
    .teamlist-container .team-small-card:nth-of-type(3){
        right:calc(340px - 10vw);
    }
  
    .teamlist-container{
        font-size: 13px;
    }


    .tech-services{
        background-image:none
    }

    .newsletter-form{
        flex-direction: column;
     }
 
     .form-item{
         width: 100%;
     }
     
     .newsletter-input{
         width: 100%;
         margin-bottom:10px;
     }
   
  
}

@media screen and (max-width: 580px) {

    .hero-tech-display{
        background-size: 400px;
    } 

    .hero-tech-guy{
        height:450px;
    }

    .location img{
        margin-right: 10px;
    }

    .hero-tech-display .tiny-card{
        font-size: 14px;
    }

    .hero-tech-display .tiny-card.info{
        width: 160px;
    }

    .hero-tech-display .tiny-card{
        padding:15px;
    }
    

    .tech-showcase-ctn .slick-prev {
        left: -1%;
        top:50%;
        z-index: 99;
    }

    .tech-showcase-ctn .slick-next {
        right: -1%;
        top:50%
    }

    .tech-showcase-ctn .slick-list{
        padding-left: 10px;
        padding-right: 10px;
    }

    .tech-showcase-ctn .ant-card{
        width: 94% !important;
    }


     .news-slider .slick-prev {
        left: 0%;
        top:50%;
        z-index: 99;
    }

    .news-slider .slick-next {
        right: 0%;
        top:50%
    }

    .footer-section{
        background-image:
        url('../images/mobile-left-rope.svg'),
        url('../images/mobile-right-rope.svg');
        background-position: 
        left -12% top 1%,
        right -12% top 1%;
    }
}

@media screen and (max-width: 550px) {

    .hero-tech-display{
        background-size: 350px;
    } 

    .hero-tech-guy{
        height:400px;
    }

    .hero-col h1.ant-typography{ 
        font-size:35px;
    }

    .stats-row .ant-card-body{
        padding:20px 20px;
    }
    
    .tech-showcase .ant-form{
        width: 90%;
    }


    .teamlist-container .team-card{
        width: calc(380px - 22vw);
        margin-right: 20px;
    }
    .teamlist-container .team-small-card:nth-of-type(2){
        right:calc(360px - 22vw);
    }
    
    .teamlist-container .team-small-card:nth-of-type(3){
        right:calc(340px - 22vw);
        top: 280px;
    }

    .teamlist-container .team-small-card:nth-of-type(4){
        top: 250px;
    }

    .teamlist-container img.verify{
        right: 0px;
      }

    .team-small-card{
        max-width: 120px;
        max-height: auto;
    }

    .newsletter-input{
        width: 100%;
    }
    

    .newsletter-subscription h3.ant-typography{
        width: 80%;
        margin: 0 auto;
    }

    .footer-details .ant-col:nth-of-type(2),  .footer-details .ant-col:nth-of-type(3),  .footer-details .ant-col:nth-of-type(4){
        padding-left:12px !important;
        padding-right:0 !important;
     }

     .footer-subsection h5.ant-typography::before{
        left: -13px;
    }
}

@media screen and (max-width: 480px) {

    .hero-tech-display{
        margin-right: 30px;
        background-size: 310px;
    } 

    .hero-tech-display .tiny-card{
        padding:12px;
        border-radius: 10px 0px 10px 10px;
    }

    .hero-tech-guy{
        height:350px;
    }

    .hero-tech-display .tiny-card:nth-of-type(2){
        right: -30px;
    }

    .location img{
        margin-right: 7px;
    }

    .news-title h3.ant-typography{
        font-size: 20px;
    }

    .stats-row .ant-card-body{
        padding:20px 20px;
    }

    .tech-talents{
        background-image:
        url('../images/mobile-talent-rope.svg'),
        url('../images/talent-qtr.svg'),
        url('../images/talent-green.png'),
        linear-gradient(98.4deg, #FFFDFA 0.98%, #FFF6E5 92.38%);
        background-position: 
        top 35% right 0,
        left 0% top 70%,
        right 40% top 50%;
        background-repeat: no-repeat;
        background-size: auto, auto, 20px;
    }

    .footer-subsection .download a img{
        width: 70px;
    }

    .footer-copyright span.ant-typography{
        font-size: 14px;
    }
    

    .mobile-right-nav{
        display:flex; 
        align-items:center; 
        column-gap:15px;
    }
}

@media screen and (max-width: 400px){

    .hero-tech-display .tiny-card{
        font-size: 12px;
        padding:10px;
    }

    .hero-tech-display{
        margin-right: 30px;
        background-size: 265px;
    } 

    .hero-tech-guy{
        height:300px;
    }

    .location img{
        margin-right:6px;
        width:14px;
    }

    .hero-tech-display .tiny-card.info{
        width: 120px;
    }

    .hero-col .rounded-primary-btn, .hero-col .outline-orange-btn{
        font-size:13px;
        padding:0 12px !important;
    }
    .teamlist-container .team-card{
        width: calc(380px - 29vw);
        margin-right: 20px;
        padding:15px
    }
    .teamlist-container .team-small-card:nth-of-type(2){
        right:calc(360px - 29vw);
    }
    
    .teamlist-container .team-small-card:nth-of-type(3){
        right:calc(340px - 29vw);
        top: 280px;
    }
    .stats-row .ant-card-body div.ant-typography{
        font-size: 13px;
    }
    

    .footer-copyright .ant-space{
        gap: 8px !important;
    }
}

@media screen and (max-width: 350px) {

    .hero-tech-display{
        margin-right: 20px;
        background-size: 200px;
    } 

    .hero-tech-guy{
        height: 280px;
    }

    .team-small-card{
        max-width: 100px;
        max-height: auto;
        font-size: 10px;
    }

}