.create-specialization-ctn{
    background: #FFFFFF;

    .create-specialization-top{
        background: #F6F6F6;
        padding: 16px 24px;
    }
    .create-specialization-body{
        min-height: 50vh;
        padding: 32px 24px;

        .ant-select{
            width: 361px;
            height:40px;
            @media screen and (max-width: 600px) {
                width: 100%;
            }

        }

        .specialization-input{
            width: 368px;
            height:40px;
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }

        // .create-specialization-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input, .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        //     height: 40px;
        // }

        // .create-specialization-select .ant-select-single .ant-select-selector .ant-select-selection-placeholder, .ant-select-single .ant-select-selector .ant-select-selection-item{
        //     line-height: 40px;
        // }
       
        .ant-select-dropdown-menu::-webkit-scrollbar-track {
            background-color: #f5f5f5;
          }
          
        /* Styling the thumb of the scrollbar */
        .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
            background-color: #c4c4c4;
            border-radius: 10px;
        }
        
        /* Styling the hover state of the thumb */
        .ant-select-dropdown-menu::-webkit-scrollbar-thumb:hover {
            background-color: #a7a7a7;
        }
        
        /* Styling the active state of the thumb */
        .ant-select-dropdown-menu::-webkit-scrollbar-thumb:active {
            background-color: #7f7f7f;
        }

        .specialization-title-section{
            border-bottom: 1px solid #C2CBDC;
            padding-bottom: 25px;

        }

        .specialization-desc-section, .specialization-work-type-section, .specialization-horly-rate-section{
            padding-top: 60px;
            border-bottom: 1px solid #C2CBDC;
            padding-bottom: 55px;
        }

        .onsite-fee-section{
            margin-top:32px;
            div.ant-typography{
                width:857px;
                @media screen and (max-width: 991px) {
                    width: 100%;
                }
            }
        }

        .specialization-skills-section{
            padding-top: 60px;
            margin-bottom:60px;
            .suggested-tag.ant-tag{
                padding:5px 18px;
                background: #F0F0F0;
                border: 1.175px solid #D9D9D9;
                border-radius: 2.35px;
                font-size: 12px;
                color: #3A3A44;
                margin-right: 8px;
                margin-bottom: 15px;
            }
            .ant-select{
                width: 720px;
                height:40px;
                @media screen and (max-width: 800px) {
                    width: 100%;
                }
            }
        }
    }
}

 /*Select  Scrollbar styles */
.create-specialization-select .rc-virtual-list-holder::-webkit-scrollbar, .add-skills-select .rc-virtual-list-holder::-webkit-scrollbar{
    width: 8px;
    background: transparent;
   
}

.create-specialization-select .rc-virtual-list-holder::-webkit-scrollbar-thumb, .add-skills-select .rc-virtual-list-holder::-webkit-scrollbar-thumb{
    background: rgba(0, 0, 0, 0.5);
    border-radius: 99px;
}

.create-specialization-select .rc-virtual-list-holder::-webkit-scrollbar-thumb:hover, .add-skills-select .rc-virtual-list-holder::-webkit-scrollbar-thumb:hover{
    background-color: #555;
}


.create-specialization-select .ant-select-item-group, .create-specialization-select .title-option{
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}

.create-specialization-select .ant-select-item-option-grouped{
    padding-left: 12px;
}

.confirm-specialization-modal .ant-modal-footer{
    border-top: none;
}

.success-result-ctn{
    margin-top: 20px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .congratulations-info{
        width: 813px;
        margin: 0 auto;
        color: rgba(0, 0, 0, 0.45);
    }
}