.add-edit-location-form{
    .add-edit-form-switch{
        display: flex;
        align-items: center; 
        column-gap: 10px;
        margin-bottom:24px;
        div.ant-typography{
           font-weight:500;
           font-size: 14px;
        }
        &.required{
            div.ant-typography{
                &::before{
                    content: "*";
                    color: var(--secondary-color);
                    margin-right: 4px;
                    margin-top: 4px;
                    font-size: 18px;
                }
            }
        }
    }
    .ant-form-item-label > label{
        margin-bottom: 6px;
        color:#202223;
        font-weight:500;
        &::after{
            display: none;
        }
    }
    &.required{
        .ant-form-item-label > label{
            font-weight: 500;
            &::before{
                content: "*" !important;
                color: var(--secondary-color) !important;
                margin-right: 4px !important;
            }
        }
    }
    .ant-upload.ant-upload-drag{
        border: 1px solid #D9D9D9;
    }
    .ant-card-body{
        padding:14px !important;
    }
}