.skills-ctn{
    margin-top: 20px;
}

.skill-card .ant-card-body{
    padding: 0;
}

.skill-card .brand-btn, .skill-modal .ant-btn-default{
    color: rgba(0, 0, 0, 0.85) !important;
    border: 1px solid #D9D9D9 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
    border-radius: 2px !important;
    font-size: 14px !important;
    background-color: #fff !important;
}

.skill-card .brand-btn:disabled{
    opacity: 0.7;
}

.skill-card .brand-btn:hover:enabled, .skill-modal .ant-btn-default:hover:enabled{
    color: #F04A22 !important;
    border: 1px solid #F04A22 !important;
}

.skill-card .card-top{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:10px 18px;
}

.skill-card .card-footer{
    display:flex; 
    justify-content:space-between; 
    align-items:center; 
    border-top:1px solid #f0f0f0; 
    padding:10px 18px;
    background:#fff !important;
    height: 55px;
}

.skill-modal .ant-modal-body{
    padding: 0;
}

.skill-modal .first-division{
    padding:21px 24px;
}

.skill-modal .ant-checkbox-wrapper + .ant-checkbox-wrapper{
    margin-left: 0;
    margin-right: 13px;
}

.skill-modal .second-division{
    padding:21px 24px;
    border: 1px solid #EDEDED;
}

.tech-skill-page-breadcrumb-header .ant-page-header-heading-left {
    display: grid;
}

.tech-skill-switch button {
    opacity: 1;
}

/* New Skill styling */

.skills-and-subskills-ctn{
    margin-top:40px;
}

.skills-and-subskills-item{
    margin-bottom: 20px;
}

.skills-and-subskills-item .subskills-tags .ant-tag{
    padding:7px 14px;
    background: #FAFAFA;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-right: 8px;
    margin-bottom: 8px;
}

.specialization-card{
    margin-top: 40px;
}

.specialization-card .learn-more-info{
    margin-top:30px;
    margin-bottom: 30px;
}

.specialization-list-ctn{
    margin-bottom: 30px;
}

.specialization-card .learn-more-info a{
    font-weight:500;
    color:#F04A22;
    text-decoration: none;
}

.specialization-popover-content .popover-action-item{
    width:100%;
    padding:8px 12px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.specialization-popover-content .popover-action-item:hover{
    background-color:#F5F5F5; 
}


.specialization-popover .ant-popover-arrow{
    display: none;
}

.specialization-popover{
    padding-top:0;
}

.specialization-popover .ant-popover-content{
    width: 139px !important;
}

.specialization-popover .ant-popover-inner-content{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.confirm-specialization-modal .ant-modal-footer{
    border-top: none;
}


@media screen and (max-width: 600px) {
    .skill-card .skill-card-space {
        flex-direction: column;
        align-items: start !important;
    }
    .skill-card .skill-card-space > .ant-space-item:last-child {
        width: 100%;
    }
    .skill-card .skill-card-space .skill-card-space-second-row {
        justify-content: space-between;
        width: 100%;
    }
}