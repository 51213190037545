.dashboard-ctn{
    margin-top: 20px;
    margin-bottom: 40px;
    .dashboard-heading-section{
        display: flex;
        justify-content: space-between;
        @media (max-width:991px) {
            flex-direction: column;
            row-gap: 16px;
        }
        .dashboard-cta-section{
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            align-items: flex-end;
            @media (max-width:991px) {
                align-items: flex-start;
            }
            .invite-members-btn{
                width: fit-content;
                padding: 0;
                height: 40px;
                border:none;
                box-shadow: none;
                font-size: 16px;
            }
        }
    }
    .dashboard-content{
        margin-top:40px;
    }
}

// New user stylings
.new-user-ctn{
    .quick-start-guide{
        border: 1px solid #D9D9D9;
        padding:32px;
        margin-bottom:60px;
        @media (max-width:576px) {
            padding:15px;
        }
        .quick-start-heading{
            margin-bottom:20px;
        }
        .card-section{
            .guide-card{
                border-radius: 2px;
                border: 1px solid #F0F0F0;
                padding: 16px;
                height: 100%;
                .guide-icon{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 16px;
                }
                h5.ant-typography{
                    color:rgba(0, 0, 0, 0.85);
                    margin-bottom:8px;
                }
                div.ant-typography{
                    color:rgba(0, 0, 0, 0.45);
                    margin-bottom:16px;
                    &.client-guide-description{
                        @media(max-width:1375px) {
                            min-height: 70px;
                         }
                         @media(max-width:790px) {
                            min-height: 120px;
                         }
                         @media(max-width:768px) {
                            min-height: auto;
                         }
                    }
                    &.tech-guide-description{
                        @media(max-width:1500px) {
                            min-height: 100px;
                         }
                         @media(max-width:1208px) {
                            min-height: 120px;
                         }
                         @media(max-width:768px) {
                            min-height: auto;
                         }
                    }
                }
            }
        }
    }

    .set-up-cards{
        .set-up-card{
            border: 1px solid #F0F0F0;
            border-radius: 2px;
            display: flex;
            column-gap: 16px;
            padding:16px;
            height: 100%;
            .setup-step-content{
                h5.ant-typography{
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    text-decoration: underline;
                    margin-bottom: 8px;
                    cursor: pointer;
                }
                div.ant-typography{
                    color:rgba(0, 0, 0, 0.45);
                    font-size: 14px;
                    &.client-step-description{
                        @media(max-width:1392px) {
                            min-height: 70px;
                        }
                        @media(max-width:1148px) {
                            min-height: 90px;
                        }
                        @media(max-width:991px) {
                            min-height: 70px;
                        }
                        @media(max-width:768px) {
                            min-height: auto;
                         }
                    }
                    &.tech-step-description{
                        @media(max-width:1160px) {
                            min-height: 70px;
                        }
                        @media(max-width:768px) {
                            min-height: auto;
                         }
                    }
                  
                }
                .disable-step{
                    cursor: default !important;
                }
                .hire-required{
                    border-radius: 2px;
                    border: 1px solid #C2C1C1;
                    color: #C2C1C1;
                    width: fit-content;
                    padding:2px 8px;
                }
            }
        }
    }

    .steps-progress-ctn{
        .steps-progress-bar{
            width: 360px;
            margin-bottom: 32px;
            @media (max-width:576px) {
                width:100%;
            }
        }
        .steps-progress-items{
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            .steps-progress-item{
                display: flex;
                align-items: center;
                column-gap: 8px;
                div.ant-typography{
                    margin-bottom: 0;
                    font-size: 14px;
                    color: #000;
                    font-weight: 500;
                    span{
                        color: var(--secondary-color);
                        font-size: 14px;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

// Active user stylings
.active-user-ctn{
    display: flex;
    flex-direction: column;
    &.itc-active-user-ctn{
        flex-direction: column-reverse;
    }
    .active-user-content{
        border: 1px solid #D9D9D9;
        padding:32px;
        margin-bottom:60px;
        min-height: 50vh;
        .contract-type-list{
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            margin-bottom: 20px;
        }
        .itc-contract-pagination{
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
        }
        @media (max-width:576px) {
            padding:15px;
        }
        .active-user-heading{
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            @media (max-width:576px) {
                flex-direction: column;
                row-gap: 16px;
            }
            a{
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                flex-shrink: 0;
            }
            .create-ticket-btn{
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.85);
                box-shadow: none;
            }
        }
        .contract-cards{
            .contract-card{
                border-radius: 2px;
                border: 1px solid #D9D9D9;
                padding: 24px;
                cursor: pointer;
                &:hover{
                    border: 1px solid var(--secondary-color);
                }
                .contract-card-provider{
                    h5.ant-typography{
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                    }
                    a{
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        color: #F04A22;
                        text-decoration: none;
                    }
                }
                .contract-card-content{
                    margin-top: 20px;
                    .ant-tag{
                        border-radius: 2.35px;
                        border: 1.175px solid #D9D9D9;
                        background: #F0F0F0;
                        color: #3A3A44;
                        font-size: 14.1px;
                        font-weight: 400;
                        padding: 4px 9.4px;
                    }
                    div.ant-typography{
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }
            }
        }
    }  
}

// Active clients stylings
.client-list{
    .client-card{
        border: 1px solid #D9D9D9;
        padding: 12px;
        // width: 400px;
        display: flex;
        row-gap: 20px;
        flex-direction: column;
        justify-content: space-between;
    }
}
