.itc-profiles-ctn{
    // margin-top:40px;
    margin-bottom: 20px;
    // .itc-profiles-header{
    //     margin-bottom: 40px;
    // }
    .itc-profiles-table{
        .itc-profiles-table-header{
            display: flex;
            justify-content: space-between;
            padding-top: 30px;
            padding-bottom: 20px;
            @media screen and (max-width:768px) {
                flex-direction: column;
                row-gap: 10px;
            }
            .search-itc-profiles{
                width: 351px;
                @media screen and (max-width:576px) {
                    width: 100%;
                }
            }
        }
    }
}


// popover stylings
.itc-profile-popover-content .popover-action-item{
    width:100%;
    padding:8px 12px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.itc-profile-popover-content .popover-action-item:hover{
    background-color:#F5F5F5; 
    width: 210px !important;
}


.itc-profile-popover .ant-popover-arrow{
    display: none;
}

.itc-profile-popover{
    padding-top:0;
}

.itc-profile-popover .ant-popover-content{
    width: 210px !important;
}

.itc-profile-popover .ant-popover-inner-content{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 4px !important;	
    padding-bottom: 4px !important;	
}

.profile-filter-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:15px;
    flex-wrap: wrap;
    margin-bottom:20px;
    .profile-filter-section{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .profile-type-list{
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            .profile-type-item{
                background: #F5F5F5;
                color: #8F8F8F;
                border-radius: 3px;
                font-size: 14px;
                font-weight: 500;
                padding: 6px 18px;
                cursor: pointer;
            }
            .profile-type-item-active{
                color: #F04A22;
                background: #FFD5CB;
            }
        }
    }
    .profile-filter-select{
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

div.ant-typography.subdomain-link{
    color: #F04A22;
    margin:0 !important;
    cursor: pointer;
    font-size: 14px;
    &:hover{
        text-decoration: underline;
    }
    .ant-typography-copy{
        color: #F04A22 !important;
    }
}

.itsc-description{
    p{
        margin-bottom: 0;
    }
}

