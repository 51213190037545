:root {
  --secondary-color: #F04A22;
  --border-color: #C2CBDC;
  --light-green-color: #52C41A;
  --light-secondary-color: #FFE3DC;
  --medium-orange-bg-color: #FFD8CE;
  --very-light-secondary-color: #f9e4de82;
  --light-bg-color: rgba(255, 249, 248, 0.63);
  --bg-black-color: #1E1E1E;
  --bg-header-color: #181A1F;
  --orange-color: #FF5C05;
  --dark-orange-color: #E55304;
  --light-orange-color: #FEE2D6;
  --alt-orange-color: #FF6818;
  --text-black-color: #383E56;
  --gray-color: #E5E5E5;
  --light-bg-color: #FFF9F7;
  --bg-orange-color:#FF5C05;
  --brown-color:#472D25;
  --light-black-color:rgba(0, 0, 0, 0.85);
  --light-gray-color: #717171;
  --container-width: 78vw;
  --navbar-height: 90px;
  --mobile-container-width:90vw;
  --small-desktop-screen-width:85vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-size-14 {
  font-size: 14px;
}

.ant-table-wrapper .ant-spin-dot-item {
  background-color: var(--secondary-color);
}

.border-radius-5 {
  border-radius: 5px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.tecme-secondary-color {
  color: var(--secondary-color) !important;
}

.tecme-light-green-color {
  color: var(--light-green-color) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.ant-switch-loading-icon.anticon {
  vertical-align: top !important;
}

.margin-top-minus-8 {
  margin-top: -8px !important;
}



/* Shared components styles */
.landing-container{
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.join-section{
  background-color: #F07C22;
  padding:30px 0;
  background-image:
  url('../../views/pages/common/public/landing-page/assets/images/semi-circle.svg');
  background-position: right 50% top 0;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.join-section .group-img-mobile{
  width: 100%;
  height: 100%;
}

.join-col .rounded-light-btn{
  height:52px;
  width:230px;
  border-radius: 5px;
  font-size: 17px;
  padding:0 30px;
  background-color: #ffffff;
  border: 2px solid #ffffff !important;
  color: #F04A22;
}

.join-col .outline-light-btn{
  height:52px;
  width:230px;
  border-radius: 5px;
  font-size: 17px;
  padding:0 30px;
  background-color: transparent !important;
  border: 2px solid #ffffff !important;
  color: #ffffff !important;
  border-color: #fff !important;
}

.join-col a.outline-light-btn:hover,a.outline-light-btn:visited {
  border: 2px solid #ffffff !important;
  color: #ffffff !important;
  border-color: #fff !important;
}

.join-img-ctn{
  background-image: url('../../views/pages/common/public/landing-page/assets/images/light-orange-bg.svg');
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom right;
  position: relative;
}

.tech-img{
  text-align: right;
}

.join-tiny-card{
  background-color: #ffffff;
  width: 200px;
  padding:20px;
  border-radius: 20px 0px 20px 20px;
  position: absolute;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  bottom: 10%;
  right: 200px;
}

.group-img-mobile img{
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.horizontal-line {
  height: 5px !important;
  color: #f3f3f3 !important;
  margin: 0 !important;
  margin-bottom: 0.5rem;
}

/* .wrapper {
  overflow-x: hidden;
} */

.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
  color: var(--secondary-color) !important; 
}

@media screen and (max-width: 991px) {
  .join-section{
    background-image:
    url('../../views/pages/common/public/landing-page/assets/images/semi-circle.svg'),
    url('../../views/pages/common/public/landing-page/assets/images/mobile-join-rope.svg');
    background-position: right 15% top 0,
    left 0 top 50%;
    background-repeat: no-repeat;
    background-size: 90px, auto;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 550px) {
  .hero-col .rounded-primary-btn, .hero-col .outline-orange-btn, .join-col .rounded-light-btn, .join-col .outline-light-btn{
    padding:0 15px;
    font-size: 16px;
  }

  .join-col .rounded-light-btn, .join-col .outline-light-btn{
    width: 200px;
  }

  .hero-col .ant-space, .join-col .ant-space{
    column-gap: 14px !important;
  }
  
  .join-tiny-card{
    width: 150px;
    right: 190px;
  }
}

@media screen and (max-width: 480px) {
  .hero-col .rounded-primary-btn, .hero-col .outline-orange-btn, .join-col .rounded-light-btn, .join-col .outline-light-btn{
    font-size:14px;
    padding:0 14px !important;
  }

  .join-col .rounded-light-btn, .join-col .outline-light-btn{
    width: 170px;
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 400px){
  .join-col .rounded-light-btn, .join-col .outline-light-btn{
    font-size:13px;
    width: 140px;
  }
}

@media screen and (max-width: 350px) {
  .join-col .ant-space{
    flex-wrap: wrap;
  }

  .join-tiny-card{
    width: 120px;
    right: 140px;
    bottom: 0;
  }
}
