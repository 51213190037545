.page-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
    @media (max-width: 991px) {
        padding-right: 25px;
        flex-direction: column;
        align-items: flex-start;
    }
    @media (max-width: 576px) {
       margin-right: 25px;
    }
    .btn-add-job-posting{
        width: 180px;
        height:40px;
        @media (max-width: 991px) {
            margin-right: 25px;
            margin-left: 25px;
        }
        @media (max-width: 576px) {
            width: 100%;
        }
    }
}
.job-postings-ctn{
    margin-top:26px;
    .job-postings-header{
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        @media screen and (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 30px;
        }       
        .search-job-postings{
            width: 362px;
            height: 40px;
            @media screen and (max-width: 576px) {
                width: 100%;
            }
            .ant-btn-default{
                border-color: #D9D9D9 !important;
                box-shadow: none !important;
                height:40px !important;
                width: 40px !important; 
            }
            input.ant-input{
                height:40px !important;    
            }
        }
    }
    .job-postings-table{
        .ant-table-container{
            min-height: auto !important;
        }
        .ant-table{
            border-radius: 2px;
            border: 1px solid #D9D9D9;
        }
    }

    // table column styles
    // .statistics-item{
    //     width: 150px;
    //     @media (max-width:1200px) {
    //         width: 120px;
    //     }
    //     @media (max-width:991px) {
    //         width: auto;
    //     }
    // }
    // .table-action-ctn{
    //     width:215px;
    //     display: flex;
    //     justify-content: space-between;
    //     @media (max-width:991px) {
    //         width: auto;
    //     }
    // }
}


.job-applications-content{
    .ant-list-split .ant-list-item {
        border-bottom: none;
    }
    .ant-list-item{
        padding: 0;
    }
}

// popver stylings
.job-posting-popover-content .popover-action-item{
    width:100%;
    padding:8px 12px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.job-posting-popover-content .popover-action-item:hover{
    background-color:#F5F5F5; 
    width: 181px !important;
}


.job-posting-popover .ant-popover-arrow{
    display: none;
}

.job-posting-popover{
    padding-top:0;
}

.job-posting-popover .ant-popover-content{
    width: 181px !important;
}

.job-posting-popover .ant-popover-inner-content{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 4px !important;	
    padding-bottom: 4px !important;	
}


// List item styles
.job-post-list-item{
    display: flex;
    column-gap: 16px;
    justify-content: space-between;
    width: 100%;
}
