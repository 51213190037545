
.job-browsing-collapse{
    border-bottom: 1px solid #D9D9D9;
    padding-bottom:16px;
    margin-bottom: 24px;
    .ant-collapse-ghost{
        width: 100% !important;
    }
    &.ant-collapse > .ant-collapse-item > .ant-collapse-header{
        padding:12px 0;
        font-size: 16px;
        font-weight: 500;
        .ant-collapse-expand-icon{
            position: relative;
            top: -3px;
        }
    }
    .ant-collapse-content > .ant-collapse-content-box{
        padding: 12px 0;
    }
}

.key-word-search{
    height: 37px;
    .ant-btn-default{
        border-color: #D9D9D9 !important;
        box-shadow: none !important;
        height:37px !important;
        width: 37px !important; 
    }
    input.ant-input{
        height:37px !important;    
    }
}

.job-browsing-category{
    .job-browsing-category-item{
        cursor: pointer;
        &:hover{
            color:var(--secondary-color);
        }
        &.job-browsing-category-item-active{
            color:var(--secondary-color);
        }
    }
}
.job-browsing-payment{
    .hourly-rate-slider{
        padding: 20px 24px 24px 16px;
        div.ant-typography{
            color: #000;
        }
        .ant-slider-track{
            background-color: var(--secondary-color);
        }
        .ant-slider-handle{
            border-color: var(--secondary-color);
        }

    }
    .fixed-rate-checkboxes{
        padding-left:24px;
        padding-top: 24px;
        .fixed-rate-checkbox{
            margin-bottom: 8px;
        }
    }
}

