.itc-dashboard-ctn{
    .itc-dashboard-header{
        .dashboard-welcome-section{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom:24px;
            gap:15px;
            a{
                color: var(--secondary-color);
                font-size: 16px;
                font-weight: 500;
                text-decoration: none;
            }
        }
        .itc-dashboard-stats{
            margin-bottom: 32px;
            .itc-dashboard-stats-item{
                border: 1px solid #D9D9D9;
                padding: 16px;
                border-radius: 2px;
                height: 100%;
                .stats-info-icon{
                    position: relative;
                    top: 2px;
                    left: 4px;
                    svg {
                        fill: rgba(0, 0, 0, 0.65) !important;
                        color: rgba(0, 0, 0, 0.65) !important;
                    }
                }
            }
        }
    }
    .itc-dashboard-content{
        .message-job-section{
            .no-group-conversation{
                border: 1px solid #D9D9D9;
                padding:30px;
                min-height: 40vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .tech-team-content{
                border: 1px solid #D9D9D9;
                padding:30px;
                margin-bottom:60px;
                // max-height: 100vh;
                overflow-y: auto;
                @media (max-width:576px) {
                    padding:15px;
                }
                .tech-team-heading{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    @media (max-width:576px) {
                        flex-direction: column;
                        row-gap: 16px;
                    }
                    a{
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        flex-shrink: 0;
                    }
                }
                .tech-team-listings{
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    .tech-team-item{
                        border:1px solid #D9D9D9;
                        padding:20px;
                        .tech-team-info{
                            .work-mode{
                                border:1px solid #000000D9;
                                border-radius: 2px;
                                font-size: 12px;
                                width: fit-content;
                                padding: 2px 4px;
                                margin-bottom:8px;
                            }
                        }
                        
                    }
                }
            }
        }

        .dashboard-section-iframe{
            margin-top: 33px;
            border: 1px solid #D9D9D9;
        }
       
        .join-request-section{
            border: 1px solid #D9D9D9;
            padding:30px;
            margin-top: 33px;
        }
        .project-management-links-section{
            border: 1px solid #D9D9D9;
            // padding:30px;
            margin-top: 33px;
            height: 100%;
            .project-management-link{
                margin-bottom: 10px;
            }
        }
        
    }
}

.view-link, .view-link:hover{
    color: var(--secondary-color);
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    display: block;
}