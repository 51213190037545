.job-post-ctn{
    margin-top:60px;
    .job-post-header{
        margin-bottom:50px
    }
    .job-post-card{
        .job-post-details{
            h4.ant-typography{
                margin-bottom:30px;
            }
            .job-post-description{
                margin-bottom:40px;
                .download-cv{
                    a{
                        color: var(--secondary-color);
                        text-decoration: none;
                    }
                }
            }
            .job-skills{
                @media screen and (max-width:991px)  {
                    margin-bottom:40px;
                }
            }
        }
        .job-post-cta-ctn{
            .job-post-cta{
                margin-bottom: 28px;
                @media screen and (max-width: 991px) {
                    border-top: 1px solid #EAECF0;
                    padding:24px 16px;
                    position: fixed;
                    left:0;
                    bottom:0;
                    width: 100%;
                    justify-content: center;
                    background-color: #fff;
                    z-index: 99;
                    margin-bottom: 0;
                }
                .submit-application{
                    width: 100%;
                }
            }
            .job-post-details{
                border: 1px solid #D9D9D9;
                border-radius: 0px 0px 8px 8px;
                .job-post-details-heading{
                    border-bottom: 1px solid #D9D9D9;
                    padding: 24px;
                    div.ant-typography{
                        font-weight: 500;
                        color: var(--secondary-color);
                    }
                }
                .job-post-details-content{
                    padding:24px;
                    .job-post-details-content-item{
                        display: flex;
                        column-gap: 8px;
                        margin-bottom: 20px;
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                        .job-post-details-content-icon{
                            position: relative;
                            top:2.5px;
                        }
                        .job-post-details-content-subitem{
                            div.ant-typography{
                                margin: 0;
                                font-size: 14px;
                                font-weight: 500;
                                &:last-of-type{
                                   color: rgba(0, 0, 0, 0.65);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .similar-jobs{
        div.ant-typography{
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            a{
                color: var(--secondary-color);
                font-weight: 500;
                font-size: 14px;
                text-decoration:underline
            }
        }
    }
}