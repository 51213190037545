.help-sticker{
    background-color: #181A1F;
    padding:12px 40px;
    width: 100%;
    margin-bottom: 120px;
    .help-sticker-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}