// Invite Listings

.itc-invites-ctn{
    margin-bottom: 20px;
    .itc-invites-table{
        .itc-invites-table-header{
            display: flex;
            justify-content: space-between;
            padding-top: 30px;
            padding-bottom: 20px;
            @media screen and (max-width:768px) {
                flex-direction: column;
                row-gap: 10px;
            }
            .search-itc-invites{
                width: 351px;
                @media screen and (max-width:576px) {
                    width: 100%;
                }
            }
        }
        .invite-type-list{
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            margin-bottom: 20px;
            .invite-type-item{
                background: #F5F5F5;
                color: #8F8F8F;
                border-radius: 3px;
                font-size: 14px;
                font-weight: 500;
                padding: 6px 18px;
                cursor: pointer;
            }
            .invite-type-item-active{
                color: #F04A22;
                background: #FFD5CB;
            }
        }
    }
}

// Invite Details

.invite-details-ctn{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 80vh;
    .invite-details-card{
        border: 1px solid #EAEAEA;
        background-color: #FBFBFB;
        border-radius:12px;
        width: 773px;
        padding:30px;
        @media screen and (max-width:991px) {
            width: 100%;
            padding: 20px;
        }
        .invite-details-title{
            font-size: 44px;
            color:#000000;
            @media screen and (max-width:991px) {
                font-size: 32px;
            }
            a{
                color: var(--secondary-color);
            }
        }
        .invite-details-paragraph{
            font-size: 18px;
            color:#222222;
            margin-top: 40px;
        }
        .info-details-text{
            font-size: 14px;
            color:rgba(0, 0, 0, 0.65);
            font-style: italic;
            margin-bottom: 40px;
            .anticon-info-circle svg{
                fill: #000000 !important
            }
        }
        .invite-details-btns{
            display: flex;
            justify-content: center;
            column-gap: 20px;
            margin-bottom: 40px;
            .invite-details-btn{
                width: 228px;
            }
        }
        .pending-invites-link{
            display: flex;
            justify-content: flex-end;
            a{
                color:var(--secondary-color);
                text-decoration: none;
            }
        }
    }
}

// Accept invite modal
.accept-invite-modal{
    .close-modal{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5px;
        font-size: 24px;
        cursor: pointer;
        .anticon-close svg{
            fill: #000000 !important;
        }
    }
    .accept-invite-modal-ctn{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .accept-invite-modal-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 389px;
            .accept-invite-modal-title{
                font-size: 32px;
                color:#000000;
                margin: 32px 0 20px 0;
                text-align: center;
            }
            .accept-invite-modal-text{
                font-size: 16px;
                color:rgba(0, 0, 0, 0.65);
                margin-bottom: 32px;
                text-align: center;
            }
            .accept-invite-modal-btns{
                display: flex;
                align-items: center;
                column-gap: 24px;
                width: 100%;
                .accept-invite-modal-btn {
                    flex: 1;
                    width: 100% !important;
                }
            }
        }
    }
}