.ant-table-row{
    cursor: pointer;
  }

.locations-popover-content .popover-action-item{
  width:100%;
  padding:8px 0;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}

.locations-popover-content .popover-action-item:hover{
    background-color:#F5F5F5; 
    width: 181px !important;
}

.locations-popover .ant-popover-arrow{
    display: none;
}

.locations-popover{
    padding-top:0;
}

.locations-popover .ant-popover-content{
    width: 181px !important;
}

.locations-popover .ant-popover-inner-content{
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 8px !important;	
    padding-bottom: 8px !important;	
}
