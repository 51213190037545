.job-applications-ctn{
    .job-applications-tabs{
       div.ant-tabs-nav::before{
            border-bottom: none !important;
        }
    }
    .job-history-col{
        @media screen and (max-width: 991px) {
            padding-bottom: 100px;
        }
        // .job-history-card{
        //     max-height:50vh;
        //     overflow-y:"auto";
        //     .ant-card-body{
               
        //     }
        // } 
    }
}

.applicant-card{
    width: 100%;
    .applicant-card-inner{
        display: flex;
        column-gap: 16px;
    }
    .applicant-card-top{
        display: flex;
        width: 100% !important;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
            row-gap:15px;
        }
        .applicant-avatar-img{
            width: 72px;
            height: 72px;
            img{
                object-fit: contain;
                width: 100%;
                height: 100%;
                border-radius: 1.48px;
            }
        }
        .applicant-name{
            color:var(--secondary-color);
            margin-bottom: 0px;
            font-weight: 500;
            font-size: 16px;
        }
        .applicant-rate{
            font-weight:500;
            margin-right:7px;
            font-size: 16px;
        }
    }
    .applicant-content{
        margin:7px 0px 24px 0;
        @media screen and (max-width: 991px) {
            margin:15px 0;
        }
    }
    .mobile-view-btn{
        @media screen and (max-width: 568px) {
            margin-top:15px;
            width: 100% !important;
        }
    }
}

.mobile-application-list{
    .mobile-application-cards{
        margin-top: 30px;
    }
}