.profile-specialization-form-ctn, .profile-testimonial-form-ctn, .profile-faq-form-ctn, .profile-tech-team-section{
    width:80%;
    @media screen and (max-width: 991px){
        width:100%;
    }
}

.itc-profile-content{
    margin-top: 25px;
}

.no-result-itc{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width:614px;
    @media screen and (max-width: 991px){
        width:100%;
    }
}

.itsc-hidden-banner{
    background-color: #fff7f6;
    border: 1px solid #fff7f6;
    width: fit-content;
    padding: 4px 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap:15px;
    row-gap:8px;
}