.discovery-result-hourly-price {
    width: 4.5rem;
}

.discovery-search-sort-by .ant-select-arrow {
    color: var(--secondary-color);
}

.discovery-search-tech-location img {
    object-fit: none;
}

.discovery-search-tech-meta .ant-card-meta-title {
    margin-bottom: 0 !important;
}

.discovery-search-tech-meta .ant-card-meta-description img {
    margin-left: -3px;
}

/*.discovery-search-user-rating {*/
/*    width: 8.5rem;*/
/*}*/

.discovery-search-no-tech-text {
    font-size: 15px;
}

.discovery-search-mobile-filter-button:hover path {
    fill: var(--secondary-color);
}

.ais-RangeInput-submit {
    border-color: var(--secondary-color) !important;
    color: var(--secondary-color) !important;
    background-color: rgba(0, 0, 0, 0.25) !important;
    /*width: 100% !important;*/
    /*position: absolute !important;*/
    /*margin: 0 !important;*/
    /*top: 45px !important;*/
}

.ais-ToggleRefinement-checkbox {
    /*height: 1.3rem !important;*/
    /*width: 2.2rem !important;*/
    background: rgba(0, 0, 0, 0.25) !important;
}

/*.ais-ToggleRefinement-checkbox:after {*/
/*  height: 1.15rem !important;*/
/*  width: 1.15rem !important;*/
/*}*/

.ais-ToggleRefinement-checkbox:checked {
    background: var(--secondary-color) !important;
    border-color: #fff !important;
}

/*.ais-RefinementList-checkbox {*/
/*  height: 24px !important;*/
/*  width: 24px !important;*/
/*  box-shadow: none !important;*/
/*}*/

.ais-GeoSearch-input:checked,
.ais-RefinementList-item--selected .ais-RefinementList-checkbox {
    color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    background-color: var(--secondary-color) !important;
    background-image: url("../images/checked-arrow.png") !important;
    background-repeat: no-repeat;
}

/*.ais-HierarchicalMenu-label,*/
/*.ais-Menu-label,*/
/*.ais-RefinementList-labelText {*/
/*  margin-left: 0.5rem;*/
/*  font-size: 14px;*/
/*}*/

/*.ais-RefinementList-item:not(:first-child):not(:last-child) {*/
/*  margin: 0.5rem 0;*/
/*}*/

.ais-RefinementList-count {
    background-color: #fff !important;
    border-color: var(--secondary-color) !important;
    color: var(--secondary-color) !important;
}

.als-compliance-level-text {
    font-size: 14px;
}

/*.ais-SearchBox-input {*/
/*  box-shadow: none !important;*/
/*}*/

.ais-SearchBox-input:hover {
    border-color: var(--secondary-color) !important;
}

.ais-SearchBox-input:focus {
    border-color: var(--secondary-color) !important;
}

.ais-SearchBox-input:active {
    border-color: var(--secondary-color) !important;
}

.ais-RangeInput-input {
    box-shadow: none !important;
    width: 100% !important;
}

.ais-RangeInput-input:hover {
    border-color: var(--secondary-color) !important;
}

.ais-RangeInput-input:focus {
    border-color: var(--secondary-color) !important;
}

.ais-RangeInput-input:active, .ais-HitsPerPage-select:focus, .ais-SortBy-select:focus {
    border-color: var(--secondary-color) !important;
}

.ais-SortBy-select {
    border: none !important;
    background-color: #fff !important;
    box-shadow: none !important;
    background-image: url("../images/sort-by.svg") !important;
}

.ais-SortBy-select:hover,
.ais-SortBy-select:active {
    border: none !important;
    background-color: #fff !important;
    box-shadow: none !important;
}

.ais-SortBy-option {
    /*padding: 1rem 0 !important;*/
}

/*.ais-RangeInput-form {*/
/*  display: grid !important;*/
/*  grid-template-columns: 40% 16% 40% !important;*/
/*  row-gap: 1rem !important;*/
/*  column-gap: 2% !important;*/
/*  flex-wrap: wrap !important;*/
/*  position: relative !important;*/
/*  margin-bottom: 3rem !important;*/
/*}*/

/*.ais-RangeInput-separator {*/
/*  margin: 0 !important;*/
/*  width: 100% !important;*/
/*  text-align: center !important;*/
/*}*/

.ais-SearchBox-input {
    box-shadow: none !important;
}

.search-result-other-brand-banner {
    background-color: #f0f0f0;
    padding: 6px 0;
    border-radius: 5px;
    position: fixed;
    top: 90px;
    z-index: 15;
    width: 100vw;
    overflow: hidden;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.19);
}

@media screen and (min-width: 992px) {
    .instant-search-page-left-fixed-scrollbar {
        overflow-y: auto;
        overflow-x: hidden;
        /*border: 1px solid #dee2e6;*/
        /*border-radius: 5px;*/
        /*position: fixed;*/
        bottom: 10px;
        width: 17vw;
        padding: 6px 12px;
        background-color: #fff;
        z-index: 200;
    }
}

.ais-SortBy-select {
    /*text-align: right !important;*/
}

.ais-InfiniteHits-loadMore:disabled[disabled] {
    display: none;
}

.search-filter .ant-collapse-header {
    padding: 0 !important;
}

.search-filter .ant-collapse-content-box {
    padding: 0 0 0 22px !important;
}

.border-bottom.search-filter {
    padding-bottom: 10px;
}

.search-filter {
    margin-top: 10px;
}

.ant-input-group-addon .ant-btn-default {
    color: #00000073 !important;
    border-color: #d9d9d9 !important;
}

@media screen and (min-width: 1200px) {
    .search-filter.ant-collapse-ghost{
        width: 20vw;
    }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
    .search-filter.ant-collapse-ghost{
        width: 75vw;
    }
    .ais-Hits-item{
        margin-bottom: 10px;
    }
}
.filter-button{
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.85) !important;
}
.ant-card-meta-avatar{
    padding-right: 10px !important;
}
.sort-radio-option .ant-card-body{
    padding: 8px !important;
    width: 100%;
}
