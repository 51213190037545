.user-card{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    height: 80vh;
    padding:1rem;
    margin:auto;
   
  
}
.paragraph{
    text-align: center;
    color: #A3A3A3;
}
.chat-input-wrap{
    padding: 0.5rem 1rem;
    border:none; 
    display:flex;
    margin-bottom:0.5rem;

}
.chat-input{
    border-radius:3rem !important;
    margin-right:0.5rem;
    padding-left: 1rem;
    background-color:#F8F8F8;
    color:black;
}
.chat-sender{
    margin-right:0.5rem;
    background-color:#FFF1EE;
    /*background-color:#F04A22;*/
    /*background-image:linear-gradient(to right, #F04A22, #F08522);*/
    padding: 10px ;
    /*color:white;*/
    border-radius:1rem 0 1rem 1rem;
    min-width: 0px;
    max-width:70%
}
.chat-input:hover, .chat-input:focus{
    background-color:#F8F8F8;
    
}
.chat-rec{
    margin-left:0.5rem;
    background-color:#f0f0f0;
    border-radius:0rem 1rem 1rem 1rem;
    padding: 10px ;
    min-width: 0px;
    max-width:70%
}
.chat-bubble{
    background-color:#f0f0f0;
    border-radius:0px 10px 10px 10px;
    padding: 10px;
    word-break: break-all;
}
/*.chat-bubble-text{*/
/*    margin-left:1.5rem !important;*/
/*}*/
.chat-time{
    position: relative;
    display: flex;
    padding: 0;
    margin:0.5rem 0 0 0;
    font-size: 10px
}
.inverseScroll{
    display: flex;
    flex-direction: column-reverse;
    /*height:47vh;*/
    margin: 0;
    border-radius: 1rem;
    overflow-y: auto;

}
.search-input{
    /*border-radius:3rem;*/
    margin:0.5rem 0;
    height:47px;
    /*padding:0 1rem 0 1rem;*/
    /*background-color: beige*/
}
.chat{
     overflow:hidden;
     height: 80vh
}

.ant-chat{
    border-radius: 0;
    border:1px solid #E4E5E7;
}

.ant-chat-conversation{
    border-bottom: none !important;
}

.chat-list-header, .chat-content-header{
    background-color: #FAFBFB;
    border-radius: 0px;
    border: none;
    border-bottom:1px solid #E4E5E7;
    padding:16px 24px;
   
}

.conversation-search input{
    height:32px;
}
.conversation-search input{
    box-shadow: none;
}


.your-chat {
    background: #fff1ee;
}
.chat-selected{
    background: #F0F0F0;
}

.user-chat{
    box-shadow: 0px -1px 0px 0px #E1E3E5 inset;
    padding-top:16px;
    padding-bottom:16px;
    cursor: pointer;
    border-bottom: none;
}
.inner-card{
    padding-left:24px;
    padding-right: 24px;
}
.inner-card-active{
    display: relative;
    padding-left:24px;
    padding-right: 24px;
}

.inner-card-active::before{
    content: '';
    height: 80%;
    width: 5px;
    border-radius: 0px 4px 4px 0px;
    background: #F04A22;
    display: block;
    position: absolute;
    left: 0;
    top:10%;
}

.chat-cell-ctn{
    display: flex;
    align-items: center;
    column-gap:16px;
}

.chat-cell-ctn .chat-cell-info{
    flex-grow: 1;
}

.mobile-chat-form{
    /* position: fixed;
    bottom: 70px;
    padding: 0px 25px 0px 0px !important;
    z-index: 100; */
    /*padding-right: 35px !important;*/
}

.chat-wrapper{
    padding-bottom: 16px;
}

.message-action-btn{
    border: none;
    color:#000 !important;
    padding:0;
    box-shadow: none;
    font-weight: 500;
}   

.message-action-btn span{
    color:#000;
}

.message-action-icon{
    color:#000;
    font-size:16px;
    margin-bottom: 5px;
}

.chat-member-popover .ant-popover-arrow{
    display: none;
}

.chat-member-popover{
    padding-top:0;
}

.chat-member-popover .ant-popover-title{
    padding:12px !important;
}

.chat-member-popover .ant-popover-content{
    width: 293px !important;
    margin-top: 12px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.chat-member-popover .chat-member-popover-inner{
    width: 270px !important;
}

.chat-member-popover .ant-popover-content::-webkit-scrollbar {
    width: 10px;
  }
  
.chat-member-popover .ant-popover-content::-webkit-scrollbar-track {
    background: #ffffff;
  }
  
.chat-member-popover .ant-popover-content::-webkit-scrollbar-thumb {
    background: rgb(217, 217, 217, 0.6);
}

.chat-member-popover-content .user-chat-action{
    margin-top: 15px;
}

.chat-member-popover .ant-popover-inner-content{
    padding: 12px !important;
}

.messages-action-modal .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
    margin-left: 38px;
}

.call-chat-btn{
    border: 1px solid #000; 
    color:#000;
    height:32px
}

.chat-btn-icon{
    font-size: 20px;
    margin-right: 4px;
    color:"#000";
}

.conversation-cell-skeleton{
    padding: 24px;
}

@media screen and (max-width: 991px) {
    .chat-btn-icon{
        color:#F04A22;
        font-size: 28px;
        margin-bottom: 5px;
    }
    .inner-card{
        padding-left:16px;
        padding-right: 16px;
    }
    .inner-card-active{
        padding-left:16px;
        padding-right: 16px;
    }
    .call-chat-btn{
        border: none; 
        background-color: transparent;
    }
    .conversation-cell-skeleton{
        padding:24px 16px;
    }
}
    
@media only screen and (min-width: 320px) and (max-width: 576px) {
    .chat{
        min-height: 420px;
    }
    /*.chat-bubble-text{*/
    /*    margin-left: 25px !important;*/
    /*}*/
}
