.discovery-content-main {
  min-height: auto;
  overflow: hidden;
}

.discovery-content-large-button {
  min-width: 12rem;
  text-align: center;
  width: max-content;
}

.discovery-content-large-button:hover {
  border-color: var(--secondary-color);
}

.discovery-content-large-button.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: var(--secondary-color) !important;
  color: var(--secondary-color) !important;
}

.descovery-experience-device-types {
  max-width: 55rem;
}

.discovery-experience-logo {
  z-index: 1;
}

.discovery-content-header {
  display: grid;
  grid-template-columns: 100px calc(100% - 125px);
  column-gap: 25px;
  place-items: center;
}

.discovery-content-footer {
  display: grid;
  grid-template-columns: 32px calc(100% - 232px) 150px;
  column-gap: 25px;
  place-items: center;
}

a.discovery-content-call {
  padding-top: 4px !important;
  text-decoration: none;
}

.discovery-content-fixed-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 1rem;
  width: calc(100vw - 2rem);
  background-color: #ffffff;
}

.wizard-use-my-device-location {
  color: #F04A22 !important;
}

/* .progress-mt{
  margin-top: 120px;
} */

.instant-search-button-helper-text {
  font-size: 14px;
  width: 12rem;
  max-width: 12rem;
  text-align: center;
}

.radio-categories{
  width: 900px;
}

@media (max-width: 1200px) {
  .radio-categories{
    width: 100%;
  }
}

@media (max-width: 992px) {
  a.discovery-content-call {
    padding-top: 6.4px !important;
  }

  .discovery-content-contact-call-wrapper {
    width: calc(100vw + 1rem);
    margin-left: -1rem;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 0.5rem));
  }

  .discovery-content-contact-call-wrapper button {
    border-right: 1px solid #dee2e6;
  }

  .discovery-content-contact-call-wrapper button,
  .discovery-content-contact-call-wrapper a {
    color: var(--secondary-color);
    font-weight: 500;
  }

  .discovery-content-footer {
    grid-template-columns: 32px calc(100% - 57px);
    column-gap: 25px;
    margin-left: 0.1rem !important;
    margin-right: 0.1rem !important;
  }

  .discovery-content-fixed-footer {
    position:sticky;
    bottom:0px;
    left:0;
    /* margin: 0 auto; */
    /* width: 100%; */
  }

  .discovery-content-main {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 370px) {
  .discovery-content-large-button {
    width: 8rem;
  }
  .instant-search-button-helper-text {
    width: 8rem;
  }
}
