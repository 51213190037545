.payment-method-row{
    /* width:80%; */
    margin: 0 auto;
    padding: 40px 20px;
}

    .ant-form-vertical .ant-form-item-label{
        padding: 0;
        color: #bbb !important; 
    }

    .payment-method-row .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        content: '';
        display: none;
    }

    .payment-method-row .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
        display: inline-block;
        margin-left: 4px;
        margin-top: -10px;
        color: #ff4d4f;
        font-size: 12px;
        font-weight: 600;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }

    .payment-input{
        border: none;
        border-bottom: 1px solid #bbb;
        border-radius: 0;
        padding-left: 0;
    }

    .rccs__card--unknown > div {
        background: linear-gradient(to right, #ff7e5f, #feb47b);
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    }

   


.ant-table-row{
    cursor: pointer;
  }

@media (max-width:991px) {
    .payment-method-row{
        padding: 0;
    }  
    .rccs__card--unknown > div {
        margin: 0 auto;
    }
}

@media (max-width:350px) {
    div.rccs, .rccs__card{
        max-width:260px !important;
    }
   
}

.payments-popover-content .popover-action-item{
    width:100%;
    padding:8px 0;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.payments-popover-content .popover-action-item:hover{
    background-color:#F5F5F5; 
    width: 181px !important;
}

.payments-popover .ant-popover-arrow{
    display: none;
}

.payments-popover{
    padding-top:0;
} 

.payments-popover .ant-popover-content{
    width: 181px !important;
}

.payments-popover .ant-popover-inner-content{
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 8px !important;	
    padding-bottom: 8px !important;	
}
