.submit-application-ctn{
    margin-top:60px;
    .submit-application-header{
        margin-bottom:40px
    }
    .submit-application-form{
        margin-top: 80px;
        .application-profile{
            border-bottom: 1px solid #D9D9D9;
            padding-bottom:40px;
            h5.ant-typography{
                margin-bottom: 8px;
            }
            div.ant-typography{
                margin-bottom:8px;
                font-size: 14px;
                font-weight: 500;
            }
            .profile-form-item{
                width: 385px;
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            }
        }
        .application-payments{
            margin-top: 80px;
            .hourly-rates{
                border-bottom: 1px solid #D9D9D9;
                padding-bottom: 40px;
                padding-top: 20px;
                .ant-space{
                    @media screen and (max-width:576px) {
                        width:100% !important;
                    }
                }
                &:last-of-type{
                    border-bottom: none;
                    padding-bottom: 0;
                }
                .title{
                    margin-bottom: 8px;
                    font-weight: 500;
                }
                .sub-title{
                    margin-bottom: 14px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                }
                .hourly-rate-input{
                    width: 339px;
                    @media screen and (max-width:576px) {
                        width:70vw;
                    }
                }
            }
            .submit-application-image{
                text-align: center;
                img{
                    @media screen and (max-width:576px) {
                        width:40px;
                    }
                }
                div.ant-typography{
                    color:rgba(0, 0, 0, 0.65);
                    font-weight: 500;
                }
            }
        }
        .application-cover-letter{
            margin-top: 80px;
        }
        .application-attachments{
            margin-top: 24px;
        }
        .application-check{
            padding-top:24px;
            padding-bottom: 24px;
            border-bottom: 1px solid #D9D9D9;
            margin-bottom: 40px;
        }
        .application-buttons{
            margin-bottom: 100px;
        }
    }
}