.ticket-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:15px;
    margin: 15px 0;

    .ticket-duration{
        @media (max-width:990px) {
            margin-left: auto;
            width: 100%;
        }
    }
}

.ticket-heading h2.ant-typography{
    margin:0;
    font-weight: 300;
}

.ticket-status{
    display: flex;
    align-items: center;
    column-gap:10px;
}

.ticket-status .ticket-status-badge{
    color: #F04A22;
    background: #FFD5CB;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    padding: 3px 15px;
    cursor: pointer;
}

.ticket-timing .font-weight-500 {
    font-size: 24px;
}
.ticket-timing .time-worked-text {
    font-size: 18px;
}

.ticket-cta-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:15px;
    margin-bottom: 20px;
}

.ticket-cta-section .back-btn{
    height:40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
}

.back-btn .back-icon{
    line-height: inherit;
}

.ticket-btn-list{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.ticket-btn-list .white-btn, .visit-schedule-title .white-btn, .parts-details-title .white-btn, .note-editor-ctn-fluid .white-btn{
    background: #FFFFFF;
    border: 1px solid #D9D9D9 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    height: 45px;
    font-weight: 500 !important;
    color: #000000 !important;
}

.visit-schedule-title .white-btn, .parts-details-title .white-btn, .note-editor-ctn-fluid .white-btn{
    height:30px;
}

.ticket-btn-list .orange-btn, .ticket-btn-list .orange-btn:hover{
    background: #F04A22;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    height: 45px;
    font-weight: 500 !important;
    color: #FFFFFF !important;
}



/* Activity Panel */
.activity-ctn{
    background-color: #FFFFFF;
    width: 100%;
    // height: auto;
    .disabled-send-button {
        cursor: not-allowed !important;
        color: #CACACA !important;
    }
}

.activity-title{
    padding: 15px;
    border-bottom:1px solid #D9D9D9;
}

.activity-title h4.ant-typography{
    margin-bottom: 0;
}

.activity-content{
    padding: 25px 15px 10px 15px;
    height: calc(100vh - 330px);
    overflow-y: auto;
}

.activity-card{
    border:1px solid #D9D9D9;
    padding:15px;
    margin-bottom: 10px;
}

/* .activity-card-title{
    display: flex;
    align-items: center;
    column-gap: 10px;
} */

.card-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card-top-activity {
    display: grid;
    align-items: center;
    grid-template-columns: calc(100% - 175px) 175px;
    flex-wrap: wrap;
    gap: 10px;

    small {
        color:#434343;
        font-weight: 300;
        font-size: 14px;
    }

    @media (max-width:576px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.activity-card-img{
    width: 40px;
    height: 40px;
}

.activity-card-img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.card-description{
    font-size:16px;
    margin-top:10px;
    word-wrap: break-word;
    border: 1px solid #D9D9D9;
    padding:15px;
    border-radius: 4px;
    width: fit-content;
    white-space: pre-line;
}

.send-activity-message-wrapper > .ant-space-item:last-child {
    width: 100%;
}

.activity-msg-input{
    // background: white;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    // color: rgba(0, 0, 0, 0.25);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.activity-msg-input .ant-input{
    // background: #F5F5F5 !important;
    border-right: 1px solid #D9D9D9 !important;
    margin-right: 10px;
}

.activity-msg-input .anticon {
    color: var(--secondary-color);
    cursor: pointer;
}

.resume-support-disabled-play-icon {
    background-color: lightgray;
    border-radius: 50%;
}

.play-icon-two-tone-icon {
    cursor: pointer;
    width: 28px;
    height: 28px;
    svg {
        width: 100%;
        height: 100%;
    }
}

.avatar-line{
    width: 2px;
    height:40px;
    background: #D9D9D9;
    position: relative;
    left:22px;
}

.timeline-ctn{
    padding-left:17px;
}

.timeline-ctn .ant-timeline-item-head {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #4F4F4F;
    border: 2px solid transparent;
    border-radius: 50%;
}

.display-none-timeline-item {
    display: none !important;
}

.ant-timeline-item-tail {
    border-left: 2px solid #D9D9D9;
    left: 5px;
}

.activity-footer{
    padding: 18px 15px;
    border-top:1px solid #D9D9D9;
}

/* Details Panel */
.details-panel-ctn{
    background-color: #FFFFFF;
    height: auto;
    width: 100%;
    /* padding-bottom: 40px; */

    .ant-upload.ant-upload-select-picture-card:hover {
        border-color: var(--secondary-color);
    }
}

.details-panel-ctn .ant-tabs-tab.ant-tabs-tab-active, .details-panel-ctn{
    font-weight: 500 !important;
}

.details-panel-ctn .ant-tabs-tab-btn{
    font-weight:400 !important;
}


.details-content{
    height: calc(100vh - 333px);
    overflow-y: auto;
    padding-bottom: 40px;
}

.details-header-section .ant-tabs-nav-wrap, .top-profile-details{
    padding-left: 15px;
    padding-right: 15px;
}

.details-info{
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.details-profile-avatar{
    height:41px;
    width: 41px;
}

.details-profile-avatar img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.top-profile-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom:15px;
}

.reassign-cta{
    color:#F04A22;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.summary-section-ctn{
    border-bottom:1px solid #D9D9D9;
    border-top:1px solid #D9D9D9;
    padding: 15px;
    color: #000000;
}

.summary-det{
    margin-top:15px;
}

.attachment-section{
    padding: 15px;
    border-bottom:1px solid #D9D9D9;
    .ant-image-mask {
        font-size: 0;
        span {
           font-size: 16px;
        }
     }
    .ant-upload.ant-upload-select-picture-card, .ant-upload-list,.ant-image-mask{
        width: 62px;
        height: 62px;
    }

    .ant-image{
        width: 62px !important;
        height: 62px !important;
    }

    .ant-image-img{
        width: 50px !important;
        height: 50px !important;
        object-fit: cover !important;
        border-radius: 2px;
    }
}

.attachment-list{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.attachment-list .ant-upload-picture-card-wrapper {
    width: max-content;
}

.add-attachment{
    background-color: #FFF6F4;
    border: 0.5px solid #F04A22;
    border-radius: 2px;
    height:60px;
    width:60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.add-attachment-plus-icon{
    color:#F04A22;
    font-size: 30px;
}

// .attachment-img{
//     width: 80px;
//     height: 78px;
// }

// .attachment-img img{
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     border-radius: 2px;
// }

.device-info-section{
    padding: 15px;
}

.device-img{
    width: 62px;
    height: 62px;
    border-radius: 2px;
    margin-top: 5px;
    .ant-image {
        height: 100%;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 2px;
        }
        
    }
    .ant-image-mask {
        font-size: 0;
        span {
           font-size: 16px;
        }
     }
}

.profile-device-info p{
    margin-bottom:0;
    font-weight:400;
    color: #000;
}

.profile-device-info small{
    font-size: 14px;
    font-weight: 400;
    color: rgba(141, 141, 141, 0.85);
    position: relative;
    top:-5px;
}

.visit-schedule{
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding:15px 10px;
}

.location-visit-info{
    padding:15px 10px;
}

.visit-schedule-title, .parts-details-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:10px;
}

.parts-details-ctn{
    padding:0 10px;
}

.parts-card{
    width: 100%;
}

.parts-card .ant-card-body{
    padding: 10px 12px 12px 12px;
}

.parts-card-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-naked-btn{
    border: none !important;
    background-color: transparent !important;
    padding:0 5px !important;
    box-shadow: none !important;
}

.card-naked-btn.edit{
    color: #000 !important;
}

.note-editor-ctn-fluid{
    padding:0 10px;
}

.note-editor-wrapper{
    border: 0.5px solid #CACACA;
    min-height: 50vh;
}

.note-editor-toolbar{
    border-bottom: 0.5px solid #CACACA;
}

/* Modal Styles */
.find-tech-modal .ant-modal-header{
    padding-bottom: 0 !important;
    border-bottom: none !important;
}



.find-tech-modal .ant-modal-body{
   padding-top:5px;
}

/* .find-tech-modal .ant-select-selector{
    height: 40px !important;
} */


.find-tech-modal .ant-select-selection-item{
    /* height:35px !important;  */
    padding-top:10px;
}

.tech-list-location{
    margin-top:20px;
}


.find-tech-modal .choose-location{
    background: rgba(255, 249, 248, 0.63);
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    padding:10px 15px;
    color: #000;
    font-weight: 500;
}

.tech-list-content{
    min-height: 80px;
}

.tech-location-btn{
    margin-top:20px;
    display: flex;
    justify-content: flex-end;
}

.tech-location-btn .disabled-next-btn, .tech-location-btn .disabled-next-btn:hover{
    background: #F5F5F5;
    border: 1px solid #D9D9D9 !important;
    border-radius: 2px !important;
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed !important;
}

.tech-location-btn .active-next-btn, .tech-location-btn .active-next-btn:hover{
    background-color: #F04A22;
    color: #FFFFFF !important;
}


.tech-loading-card{
    height: auto;
    width: auto;
}

.tech-loading-card .ant-card-body{
    padding:10px;
}

.location-technician-card{
    margin-top:8px;
    cursor: pointer;
}

.location-technician-card:hover, .location-technician-card-active{
    background: #FFF9F8;
    border: 1px solid #F04A22;
    border-radius: 2px;
}

.location-technician-card .ant-card-body{
    padding:10px 10px 10px 10px;
    border: 1px solid #D9D9D9;
}

.location-technician-card .ant-card-bordered{
    border: 1px solid #D9D9D9;
}
.location-icon{
    color:#F04A22;
    position: relative;
    top:-3px;
}

.location-city{
    font-size: 12px;
    color: #000;
    font-weight: 400;
}

.location-technician-card .select-card-btn{
    background: #FFFFFF !important;
    border: 1px solid #D9D9D9 !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
    border-radius: 2px !important;
    color: rgba(0, 0, 0, 0.85) !important;
}

.location-technician-card .ant-tag{
    background: #FFF2E8;
    border: 1px solid #FFBB96;
    border-radius: 2px;
    color: #FA541C;
}

.empty-tech-row{
    background: rgba(255, 249, 248, 0.63);
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    padding:15px 20px;
}

.empty-tech-row div.ant-typography{
    margin-bottom: 0;
    color: #000;
    font-weight: 400;
}

.time-slots-ctn{
    height:270px;
    padding: 0 4px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tech-location-btn .sch-back-btn{
    border:none !important;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.85) !important;
}

.react-calendar.tech-availability-calendar{
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
    border-radius: 2px;
    border: none;

    button.react-calendar__tile:enabled:hover {
        background-color: white;
        border: 1px solid var(--secondary-color) !important;
    }

    .react-calendar__disable {
        color: #b7b7b7 !important;
        background-color: #f0f0f0 !important;
    }
}

.tech-availability-calendar .react-calendar__navigation{
    box-shadow: inset 0px -1px 0px #F0F0F0;
}

.tech-availability-calendar .react-calendar__month-view__weekdays{
    text-transform: capitalize;
}

.tech-availability-calendar abbr[title]{
    text-decoration: none;
}

.tech-availability-calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend{
    color: rgba(0, 0, 0, 0.85);
}

.tech-availability-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day, .tech-availability-calendar .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day{
    background: #F04A22;
    color: #FFFFFF;
}

/* .tech-availability-calendar .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day:hover{
    background: rgba(255, 249, 248, 0.63);
    color: rgba(0, 0, 0, 0.85);
} */

.tech-availability-calendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth{
    color: rgba(0, 0, 0, 0.25);
}

.tech-availability-calendar .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day{
    border: 1px solid #F04A22;
    background: transparent;
}

.resolve-ticket-modal {
    .resolve-note {
        background: rgba(255, 249, 248, 0.63);
        border: 1px solid #D9D9D9;
        border-radius: 2px;
    }
}

.caret-right-outlined {
    svg {
        font-size: 20px;
    }
}

.remote-access-session-modal { 
    .step-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        .step-number-wrapper {
            background-color: var(--secondary-color);
            margin-right: 0.8rem;
            .step-number {
                color: white;
                font-size: 15px;
            }
        }
    } 
}

.ticket-updated-successfully-wrapper {
    .ant-modal-confirm-title {
        margin-top: -1.2rem;
    }
}

#add-manual-time-button {
    &:disabled {
        height: 100%;
        color: #00000040 !important;
        border-color: #d9d9d9 !important;
        background: #f5f5f5 !important;
        text-shadow: none !important;
        box-shadow: none !important;
    }
}

.ai-log-modal{
    .ant-form-item-label > label{
        margin-bottom: 10px;
        color:#000;
        font-size: 14px;
        font-weight: 500;
        &::before{
            display: none;
        }
    }
}


@media screen and (max-width: 768px) {
    .support-ticket-container {
        margin-bottom: 70px;
    }
}