.create-list-heading{
    margin-bottom:28px;
}

.create-list-heading .list-header{
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: var(--light-black-color)
}

.create-list-heading .list-info{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--light-gray-color)
}

.create-list-form .list-title, .create-list-form .list-description{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--light-black-color);
    margin-bottom: 10px;
}

.create-list-form .list-description span{
    color: #7A7A7D;
    font-weight: 400;
}