.application-details-card{
    padding-top: 40px;
    border-top: 1px solid #D9D9D9;
    .application-details-details{
        h4.ant-typography{
            margin-bottom:30px;
        }
        .application-details-description{
            margin-bottom:40px;
        }
    }
    .download-cv{
        a{
            color: var(--secondary-color);
            text-decoration: none;
        }
    }
}