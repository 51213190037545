.job-browsing-ctn{
    margin-top: 57px;
    .job-browsing-content{
        .job-browsing-search{
            margin-bottom: 37px;
            .ant-btn-default{
                box-shadow: none !important;
            }
        }
        .search-num-ctn{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
            .sort-ctn{
                display: flex;
                column-gap: 16px;
                align-items: center;
            }
        }
        .mobile-filter-cta{
            margin-bottom: 24px;
            display: flex;
            column-gap: 30px;
            .filter-btn{
                flex: 1;
            }
        }
        .filter-tags-ctn{
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        .job-listings-ctn{
            // border: 1px solid #D9D9D9;
            .ant-list-split .ant-list-item{
                border-bottom:none;
            }
            .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
                border-bottom:none;
            }
            .ant-list-item {
                padding: 0;
            }
        }
    }
}

.sort-modal-ctn{
    .ant-radio-group{
        width:100% !important;
    }
    .ant-space{
        width:100% !important;
    }
    .ant-radio-wrapper{
        width:100% !important;
    }
    .sort-modal-card{
        border: 1px solid #D9D9D9;
        padding: 8px 16px;
        width: 100%;
        margin-bottom: 16px;
    }
}

.apply-sort-btn{
    width:100%;
    margin-top: 20px;
}