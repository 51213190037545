.application-details-ctn{
    margin-top:40px;
    margin-bottom: 100px;
    .application-details-header{
        margin-bottom:40px
    }
    .your-application{
        padding-top: 34px;
        border-top: 1px solid #D9D9D9;
    }
    .price-rate-input{
        width: 339px;
    }
    .job-skills{
        @media screen and (max-width:991px)  {
            margin-bottom:40px;            
        }
    }
}