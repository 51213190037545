.job-browsing-card{
    padding: 24px;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    width: 100%;
    &:hover{
        border-color: var(--secondary-color);
    }
    .dot{
        color: rgba(0, 0, 0, 0.75);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        @media screen and (max-width: 991px) {
            display: none; 
        }
    }
    .job-browsing-card-heading{
        margin-bottom: 16px;
    }
    .job-browsing-card-subheading{
        margin-bottom: 16px;
        div.ant-typography{
            color:rgba(0, 0, 0, 0.65);
            font-size: 12px;
            margin: 0;
            span{
                font-weight: 500;
            }
        }
    }
    .job-browsing-skills{
        margin-bottom: 16px;
    }
    .safety-icon{
        position: relative;
        top:1px;
    }
}

.job-drawer{
    .ant-drawer-content{
        border-radius: 16px 0px 0px 16px;
    }
    .job-drawer-ctn{
        padding: 48px 80px;
        .drawer-navigation{
            display: flex;
            justify-content: space-between;
            align-items: center;
            a{
                color: #000;
                font-size: 14px;
                font-weight: 500;
                text-decoration: underline;
            }
        }
        .drawer-job-title{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin:34px 0;
            column-gap: 30px;
            .ant-btn-primary{
                width:254px;
            }
        }
        .drawer-info-card{
            border-radius: 8px;
            border: 1px solid #D9D9D9;
            padding: 24px;
            margin-bottom: 34px;
        }
        .about-job{
            margin-bottom: 34px;
        }
        .browse-jobs-client-details{
            margin: 34px 0;
        }
    }
}