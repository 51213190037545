.edit-cat-modal{
    .ant-modal-footer{
        border-top: none;
    }
    .edit-cat-title, .selected-skills-tags{
        padding:0 24px;
    }
    .ant-modal-body{
        padding: 24px 0;
    }
    .selected-skills-tags{
        margin-top:20px;
        .ant-tag{
            padding:7px 14px;
            background: #FAFAFA;
            border: 1px solid #D9D9D9;
            border-radius: 2px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }
    .categories-collapse{
        margin-top: 40px;
        .categories-checkbox{
            padding-left: 20px;
            margin-bottom: 5px;
            .parent-categories-checkbox-item{
                margin-bottom: 25px;
                font-weight: 500;
            }
        }
    }
}


