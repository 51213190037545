.account-settings-tech-info-wrapper .ant-collapse-header {
    align-items: center !important;
}

.profile-radius-label{
    display:flex;
    justify-content:space-between;
    align-items: center;
}

.profile-radius-label .edit-service-btn{
    margin-left:20px;
}