.records-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    
  }
.booking-btn.ant-btn  {
    font-size:12px;
    /*border-radius: 0.3rem;*/
    height: 35px;
    border: none;
    background-color: white;
    border: 1px solid black;
    color: #1D3466;
    width: 140px;
    
}
.booking-btnn.ant-btn  {
    font-size:12px;
    border-radius: 0.3rem;
    height: 35px;
    border: none;
    background-color: white;
    border: 1px solid #1D3466;
    color: #1D3466;
    width: 200px;
    
}
.booking-btn-user.ant-btn {
    font-size:12px;
    border-radius: 0.3rem;
    height: 35px;
    border: none;
    background-color: #1D3466;
    border: 1px solid #1D3466;
    color: white;
    width: 140px;
    
}
