.view-application-ctn{
    padding-top:40px;
    .view-application-heading{
        margin-bottom:40px
    }
    .withdrawn-alert-ctn{
        margin-bottom:40px;
        border-radius: 2px;
        border: 1.5px solid #F04A22;
        background-color: #FFF9F8A1;
        display: flex;
        column-gap: 10px;
        padding:20px 26px;
        .withdrawn-alert-content{
            div.ant-typography{
                color: #000;
                font-size: 16px;
                font-weight: 400;
                margin: 0;
            }
            .sub-text{
                color: #636363;
                font-size: 14px;
            }
        }
    }
    .application-profile-section{
        border-radius: 5px;
        border: 1px solid #C2CBDC;
        padding: 32px;
        display: flex;
        justify-content: space-between;
        @media  screen and (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 40px;
        }
        @media  screen and (max-width: 576px) {
            border: 0;
            padding: 0;                  
        }
        .profile-info{
            display: flex;
            column-gap: 40px;
            @media  screen and (max-width: 500px) {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 40px;                
            }
            .profile-img-ctn{
                position: relative;
                .profile-img{
                    width: 258px;
                    height: 258px;
                    @media screen and (max-width: 768px) {
                        width: 220px;
                        height: 220px;
                    }
                    // @media  screen and (max-width: 576px) {
                    //     width: 110px;
                    //     height: 110px;              
                    // }
                    @media  screen and (max-width: 500px) {
                        width:100%;
                        height:100%;           
                    }
                    img{
                        height: 100%;	
                        width: 100%;
                        object-fit: cover;
                        border-radius: 2px;
                    }
                }
                .app-hippa-badge{
                    position: absolute;
                    bottom:20px;
                    right: -30px;
                    @media screen and (max-width: 768px) {
                        right: -20px;
                    }
                    // @media  screen and (max-width: 576px) {
                    //     width: 100px;            
                    // }
                }
            }
            .profile-bio-rating{
                .profile-bio-title{
                    margin-bottom: 0;
                    // @media  screen and (max-width: 576px) {
                    //     font-size: 16px;           
                    // }
                }
                .profile-bio-code{
                    font-size:18px;
                    color:#34343C;
                    margin:10px 0;
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                    }
                    // @media  screen and (max-width: 576px) {
                    //     font-size: 12px;           
                    // }
                }
                .profile-bio-subinfo{
                    color:#7A7A7D; 
                    font-size:18px;
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                    }
                    // @media  screen and (max-width: 576px) {
                    //     font-size: 12px;           
                    // }
                }
                .profile-bio-icon{
                    font-size:18px;
                    color:#F04A22;
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                    }
                }
            }
        }
        .view-app-cta-ctn{
            @media  screen and (max-width: 576px) {
                width:100% !important;                    
            }
            .view-app-btn{
                width:262px !important;
                @media  screen and (max-width: 576px) {
                    width:100% !important;                    
                }
            }
        }
    }
}

.job-application-section{
    border:1px solid #C2CBDC;
    padding: 32px;
    margin-bottom: 40px;
    margin-top: 40px;
    @media  screen and (max-width: 576px) {
        border: 0;
        padding: 0;                  
    }
}

.job-application-section .job-application-title{
    margin-bottom: 24px;
}

.job-application-content .download-cv{
    margin-top:20px;
}

.job-application-content a{
    color: var(--secondary-color);
    text-decoration: none;
    position: relative;
    top: 1px;
}