.added-item-list{
    width:614px;
    .ant-list-item{
        padding: 0 !important;
    }
    .ant-list-split .ant-list-item{
        border-bottom: none !important;
    }
    @media screen and (max-width: 991px) {
        width: 100%;     
    }
}

.added-item-card{
    display: flex;
    justify-content: space-between;
    align-items: start;
    width:100%;
    border-radius: 4px;
    border: 1px solid #DDD;
    background: #FFF;
    margin-bottom:15px;
    padding: 10px 14px;
    @media screen and (max-width: 991px) {
        width: 100%;     
    }
    .close-outlined{
        cursor: pointer;
    }
}

.added-item-message{
    display: flex;
    align-items: center;
    column-gap:8px;
    width: 100%;
    padding:10px 16px;
    margin-bottom:21px;
    border-radius: 2px;
    background:#FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    p{
        margin:0;
        font-size: 14px;
    }
}