.account-settings-ctn{
    .heading-section{
        border-bottom: 1px solid #EAECF0;
        padding-bottom:32px;
        padding-top: 20px;
        .select-menu-ctn{
            margin-top: 10px;
            .select-menu{
                width: 100%;
                .ant-select-selector{
                    height: 40px;
                }
                .ant-select-selection-item{
                    line-height: 38px !important;
                }
            }
        }
    }

    .account-settings-content{
        padding-top: 32px;
        .ant-tabs-left > .ant-tabs-content-holder{
            border-left: none;
        }
    }
}

.settings-tab-ctn{
    padding-left:80px;
    @media screen and (max-width: 991px) {
        padding-left:0px;  
    }
}